import { Field } from "formik";
import { CheckboxWithLabel } from "formik-mui";
import React, { useCallback, useMemo } from "react";
import FormInput from "~/components/Forms/FormInput";
import CustomList from "../../components/CustomList";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import { addOtherPricedItem, mergeValuesWithInital } from "../../helpers/dataHelpers";
import { useForm } from "../../hooks/useForm";
import { useFormEffect } from "../../hooks/useFormEffect";
import { baseMiscItems, baseRRItems } from "./data/data";

// const addGlassAllowence = (formik, getMiscPrice) => {
//   const misc = formik.values.misc || [];
//   if (!misc.some((item) => item.key === "glass_allowance")) {
//     misc.push(
//       {
//         key: "glass_allowance",
//         label: "Glass Allowance",
//         price: getMiscPrice("misc", "glass_allowance"),
//       }
//     );
//     formik.setFieldValue('misc', misc);
//   }
// };

const PageSublets = () => {

	const { get, formik, getMiscPrice, estimateMeta, isCompleted } = useForm();

	const RRItems = useMemo(() => {
		return mergeValuesWithInital(formik.values["r&r"], baseRRItems(estimateMeta.bodyStyle)).map(item => ({
			...item,
			price: item.price ?? getMiscPrice('removerefit', item.key)
		}));
	}, [formik.values, getMiscPrice, estimateMeta])


	// const togglePart = useCallback((key) => {
	//   const parts = [...get('parts')];

	//   if (get('parts').some((part) => part.key == key)) {
	//     // exists so remove it
	//     const index = parts.findIndex((part) => part.key == key);
	//     parts.splice(index, 1);
	//   } else {
	//     // add part using baseParts based on  key match
	//     const part = baseParts().find((part) => part.key == key);
	//     parts.push(part);
	//   }

	//   formik.setFieldValue('parts', parts);
	// }, [get, formik]);

	const toggleRR = useCallback((key, addOnly = false) => {
		const RRs = [...get('r&r')];

		if (get('r&r').some((rr) => rr.key == key)) {
			// exists so remove it
			if (addOnly) return;

			const index = RRs.findIndex((rr) => rr.key == key);
			RRs.splice(index, 1);
		} else {
			// add part using baseParts based on  key match
			const rr = RRItems.find((rr) => rr.key == key);
			RRs.push(rr);
		}

		formik.setFieldValue('r&r', RRs);
	}, [get, formik, RRItems]);


	useFormEffect(['sublets.front_screen.new'], (values, formik) => {
		toggleRR('front_screen', true);

		if (values['sublets.front_screen.new']) {
			addOtherPricedItem('misc', 'glass_allowance', baseMiscItems, formik, getMiscPrice);
			formik.setFieldValue('sublets.adas_calibration', true)
		}
	});

	useFormEffect(['sublets.rear_screen.new'], (values, formik) => {
		toggleRR('rear_screen', true);

		if (values['sublets.rear_screen.new']) {
			addOtherPricedItem('misc', 'glass_allowance', baseMiscItems, formik, getMiscPrice);
			formik.setFieldValue('sublets.adas_calibration', true)
		}
	});

	useFormEffect(['sublets.1/4_right.new'], (values, formik) => {
		toggleRR('right_glass_quarter_panel', true);

		if (values['sublets.1/4_right.new']) {
			addOtherPricedItem('misc', 'glass_allowance', baseMiscItems, formik, getMiscPrice);
		}
	});

	useFormEffect(['sublets.1/4_left.new'], (values, formik) => {
		toggleRR('left_glass_quarter_panel', true);

		if (values['sublets.1/4_left.new']) {
			addOtherPricedItem('misc', 'glass_allowance', baseMiscItems, formik, getMiscPrice);
		}
	});

	return (
		<React.Fragment>
			<EstimateFormPage>
				<CustomList.Main
					headers={[["Item"], ["Options", 20]]}
					name="sublets"
					readOnly={isCompleted}
				>
					<CustomList.Title>Sublets</CustomList.Title>

					<CustomList.List>
						<CustomList.GroupHeading>
							Glass
						</CustomList.GroupHeading>

						<CustomList.ListItem>
							<CustomList.ListColumn scope="row">
								Front Screen
							</CustomList.ListColumn>
							<CustomList.ListColumn sx={{ gap: 8 }}>
								<>
									<Field
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										type="checkbox"
										Label={{ label: "New" }}
										name="sublets.front_screen.new"
									/>

									<Field
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										type="checkbox"
										Label={{ label: "R&R" }}
										checked={get('r&r').some((rr) => rr.key == 'front_screen')}
										onChange={() => toggleRR('front_screen')}
									/>
								</>

							</CustomList.ListColumn>
						</CustomList.ListItem>

						<CustomList.ListItem>
							<CustomList.ListColumn scope="row">
								Rear Screen
							</CustomList.ListColumn>
							<CustomList.ListColumn>
								<>
									<Field
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										type="checkbox"
										Label={{ label: "New" }}
										name="sublets.rear_screen.new"
									/>

									<Field
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										type="checkbox"
										Label={{ label: "R&R" }}
										checked={get('r&r').some((rr) => rr.key == 'rear_screen')}
										onChange={() => toggleRR('rear_screen')}
									/>
								</>

							</CustomList.ListColumn>
						</CustomList.ListItem>

						<CustomList.ListItem>
							<CustomList.ListColumn scope="row">
								L/H 1/4 Glass
							</CustomList.ListColumn>
							<CustomList.ListColumn>
								<>
									{/* <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    Label={{ label: "New" }}
                    name="sublets.1/4_left.new"
                  /> */}

									<Field
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										type="checkbox"
										Label={{ label: "R&R" }}
										checked={get('r&r').some((rr) => rr.key == 'left_glass_quarter_panel')}
										onChange={() => toggleRR('left_glass_quarter_panel', true)}
									/>
								</>

							</CustomList.ListColumn>
						</CustomList.ListItem>

						<CustomList.ListItem>
							<CustomList.ListColumn scope="row">
								R/H 1/4 Glass
							</CustomList.ListColumn>
							<CustomList.ListColumn>
								<>
									{/* <Field
                    component={CheckboxWithLabel}
                    type="checkbox"
                    Label={{ label: "New" }}
                    name="sublets.1/4_right.new"
                  /> */}

									<Field
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										type="checkbox"
										Label={{ label: "R&R" }}
										checked={get('r&r').some((rr) => rr.key == 'right_glass_quarter_panel')}
										onChange={() => toggleRR('right_glass_quarter_panel', true)}
									/>
								</>

							</CustomList.ListColumn>
						</CustomList.ListItem>

						<CustomList.GroupHeading>
							MISC
						</CustomList.GroupHeading>

						<CustomList.ListItem>
							<CustomList.ListColumn scope="row">
								ADAS Calibration - A.I.P
							</CustomList.ListColumn>
							<CustomList.ListColumn>
								<>
									<Field
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										name="sublets.adas_calibration"
										type="checkbox"
										Label={{ label: "Required" }}
									/>
								</>

							</CustomList.ListColumn>
						</CustomList.ListItem>


						{/* <CustomList.Group heading="test">
              <CustomList.ListItem>
                <CustomList.ListColumn scope="row">
                  ADAS Calibration - A.I.P
                </CustomList.ListColumn>
                <CustomList.ListColumn>
                  <>
                    <Field
                      component={CheckboxWithLabel}
                      name="sublets.adas_calibrationss"
                      type="checkbox"
                      Label={{ label: "Required" }}
                    />
                  </>

                </CustomList.ListColumn>
              </CustomList.ListItem>
            </CustomList.Group> */}


					</CustomList.List>
				</CustomList.Main>
			</EstimateFormPage>
		</React.Fragment>
	);
};

export default PageSublets;
