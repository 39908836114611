import { ArrowBack } from "@mui/icons-material";
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from "@mui/icons-material/Search";
import { AppBar, Badge, Box, Button, IconButton, Toolbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import Image from "mui-image";
import { useRef, useState } from "react";
import { Link, useMatch } from "react-router-dom";
import Avatar from "~/components/Avatar";
import ProfilePopover from "~/components/ProfilePopover";
import useGlobalState from "~/hooks/useGlobalState";
import { useLayoutProvider } from "~/providers/LayoutProvider";
import logo from "../assets/images/phms_logo.png";
import AssessmentDialog from "../components/Dialogs/AssessmentDialog";
import SearchDialog from "../components/Dialogs/SearchDialog";
import { useAuth } from "../hooks/useAuth";
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpCenterDrawer from "~/components/HelpCenterDrawer";

const Header = () => {

	const { toggleSideDrawer, sidemenuOpen, toggleSidemenu } = useLayoutProvider();
	const { user } = useAuth();
	const appBarRef = useRef();
	const theme = useTheme();

	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

	const [isSearchOpen, setIsSearchOpen] = useState(false);

	const isXs = useMediaQuery(theme.breakpoints.down("sm"));
	const isOnForms = !!useMatch({ path: "forms/", end: false });

	const popupState = usePopupState({
		variant: 'popover',
		popupId: 'ProfilePopover',
	});

	const handleOpenSearch = () => {
		setIsSearchOpen(true);
	};

	const handleCloseSearch = () => {
		setIsSearchOpen(false);
	};

	const { lastMainUrl } = useGlobalState();

	const [isHelpOpen, setIsHelpOpen] = useState(false);

	return (
		<AppBar
			position="fixed"
			enableColorOnDark
			color="inherit"
			sx={{
				zIndex: matchUpMd || isOnForms ? theme.zIndex.drawer + 1 : null,
				transition: sidemenuOpen ? theme.transitions.create('width') : 'none'
			}}
			ref={appBarRef}
		>
			<Toolbar>
				{isOnForms && (
					<IconButton
						size="large"
						edge="start"
						color="primary-on-white"
						aria-label="open drawer"
						onClick={toggleSideDrawer}
					>
						<MenuIcon />
					</IconButton>
				)}

				{isOnForms ? (
					<Link to={lastMainUrl}>
						<Button size="large" color="primary-on-white">
							<ArrowBack /> Back
						</Button>
					</Link>
				) : (
					<Tooltip title={sidemenuOpen ? 'Close Menu' : 'Open Menu'}>
						<IconButton
							size="large"
							aria-label="open menu"
							onClick={toggleSidemenu}
							sx={{
								...theme.typography.commonAvatar,
								...theme.typography.mediumAvatar,
								transition: 'all .2s ease-in-out',
								background: theme.palette.primary.light,
								color: theme.palette.primary.dark,
								'&:hover': {
									background: theme.palette.primary.dark,
									color: theme.palette.primary.light
								}
							}}
						>
							{sidemenuOpen ? <MenuOpenIcon /> : <MenuIcon />}
						</IconButton>
					</Tooltip>
				)}

				<Box sx={{ mx: 2, maxWidth: 100 }}>
					<Link to={'/'}>
						<Image src={logo} alt="PHMS Logo" />
					</Link>
				</Box>


				{/* middle */}
				<Box sx={{ flexGrow: 1 }} />

				<Box>
					{/* <IconButton
						size="large"
						aria-label="show 17 new notifications"
						color="inherit"
					>
						<Badge badgeContent={17} color="error">
							<NotificationsIcon />
						</Badge>
					</IconButton> */}

					{!(isXs && isOnForms) && (
							<IconButton size="large" color="primary-on-white" onClick={handleOpenSearch}>
								<SearchIcon />
							</IconButton>
					)}

					{/* {isOnForms && (
						<Link to={'./../'}>
							<IconButton size="large" color="primary-on-white">
								<HomeIcon />
							</IconButton>
						</Link>
					)} */}

					<Badge
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						badgeContent="?"
						overlap="circular"
						color="white"
						{...bindTrigger(popupState)}
					>
						<Avatar
							{...bindTrigger(popupState)}
							name={user.name}
							size={42}
							sx={{ cursor: 'pointer', ml: 2 }}
							noToolip
							inline
						/>
					</Badge>
				</Box>
			</Toolbar>

			<SearchDialog
				isOpen={isSearchOpen}
				triggerClose={handleCloseSearch}

			/>

			<AssessmentDialog />

			<ProfilePopover popupState={popupState} triggerHelp={() => setIsHelpOpen(true)} />
			<HelpCenterDrawer open={isHelpOpen} toggleDrawer={setIsHelpOpen} />
		</AppBar>
	);
};

export default Header;
