import { queryOptions } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { getPaintCode, getPaintCodes } from "~/requests/paintCodes";

/**
 * Creates a query for fetching paintCode details based on the provided parameters.
 *
 * @param {object} [params={}] - The optional parameters for fetching paintCode details.
 * @returns {object} The query options for fetching paintCode details.
 *
 * @example
 * // Fetching all paintCodes
 * const allpaintCodesQueryOptions = paintCodesQuery();
 * const { data } = useQuery(allpaintCodesQueryOptions);
 *
 * // Fetching paintCodes with specific parameters
 * const filteredpaintCodesQueryOptions = paintCodesQuery({ param1: value1, param2: value2 });
 * const { data } = useQuery(filteredpaintCodesQueryOptions);
 */
export const paintCodesQuery = (params = {}) => queryOptions({
	queryKey: ['paintCodes', params],
	queryFn: queryFunctionHelper(getPaintCodes, params)
});

/**
 * Creates a query for fetching paintCode details based on the paintCode ID.
 *
 * @param {string} id - The ID of the paintCode to fetch details for.
 * @returns {object} The query options for fetching paintCode details.
 *
 * @example
 * const paintCodeQueryOptions = paintCodeQuery("paintCodeID");
 * const { data } = useQuery(paintCodeQueryOptions);
 */
export const paintCodeQuery = (id) => queryOptions({
	queryKey: ['paintCodes', id],
	queryFn: queryFunctionHelper(getPaintCode, id),
	enabled: id !== null
});


/**
* Creates a loader function for ensuring paintCode data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {function} The loader function that ensures paintCode data is available.
*
* @throws {Error} Throws an error if no paintCode ID is provided.
*
* @example
* const { id } = useLoaderData();
*/
export const paintCodeLoader = (queryClient) => async ({ params }) => {
	if (!params.id) {
		throw new Error('No Paint Code ID provided')
	}
	await queryClient.ensureQueryData(paintCodeQuery(params.id))
	return { id: params.id }
};


/**
* Creates a loader function for ensuring paintCode data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {Object} Empty object
*
* @example
* const { } = useLoaderData();
*/
export const paintCodesLoader = (queryClient) => async () => {
	await queryClient.ensureQueryData(paintCodesQuery())
	return {}
};