import apiHandler from "../apiHandler";

export const searchAssessments = (term, params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "assessments/search",
	dataOrParams: {
		q: term,
		...params
	},
	signal
});

export const getAssessment = (uuid, signal = undefined) => apiHandler({
	method: "get",
	url: "assessments/" + uuid,
	signal
});

export const createAssessment = (data = {}, signal = null) => apiHandler({
	method: "post",
	url: `assessments`,
	dataOrParams: data,
	signal
});

export const updateAssessment = (uuid, data = {}, signal = null) => apiHandler({
	method: "put",
	url: `assessments/${uuid}`,
	dataOrParams: data,
	signal
});
