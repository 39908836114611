import { Box, FormHelperText, Grid, InputAdornment, ToggleButton } from "@mui/material";
import { Field } from "formik";
import { TextField, ToggleButtonGroup } from "formik-mui";
import React, { useCallback, useMemo } from "react";
import FormInput from "~/components/Forms/FormInput";
import { addOtherPricedItem, mergeValuesWithInital } from "~/helpers/dataHelpers";
import { useFormEffect } from "~/hooks/useFormEffect";
import CustomList from "../../components/CustomList";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import { useForm } from "../../hooks/useForm";
import { baseRRItems, baseTurretMiscItems } from "./data/data";

const PageTurret = () => {

	const { formik, getMiscPrice, isCompleted, estimateMeta } = useForm();


	const updatePrices = useCallback((key) => {
		if (key !== null) {
			formik.setFieldValue("turret.trims", getMiscPrice('turret', `${key}_trims`) ?? 0)
			formik.setFieldValue("turret.r&i", getMiscPrice('turret', `${key}_r&i`) ?? 0)
			formik.setFieldValue("turret.paint", getMiscPrice('turret', `${key}_paint`) ?? 0)
		} else {
			formik.setFieldValue("turret.trims", '')
			formik.setFieldValue("turret.r&i", '')
			formik.setFieldValue("turret.paint", '')
		}
	}, [formik, getMiscPrice]);


	const miscItems = useMemo(() => {
		return mergeValuesWithInital(formik.values["turret_misc"], baseTurretMiscItems).map(item => ({
			...item,
			price: item.price ?? getMiscPrice('turret_misc', item.key, item.key == 'consumables_allowance' ? false : true)
		}));
	}, [formik.values, getMiscPrice])


	useFormEffect(["turret.size"], (values) => {
		updatePrices(values["turret.size"]);

		if (values["turret.size"] === 'large') {
			addOtherPricedItem('r&r', 'aircon_vents_piping', baseRRItems(estimateMeta.bodyStyle), formik, getMiscPrice);
		}

	});

	return (
		<EstimateFormPage>
			<React.Fragment>
				<PageTitle>Turret Replacement</PageTitle>

				<div style={{ fontSize: '80%', color: "darkgrey", paddingTop: 10 }}>
					<div>
						Small: Hatch, compact SUV, carolla, i30 etc...
					</div>
					<div>
						Medium: Sedan, Mid-size SUV, 4WD Ute, Tuscon, ASX etc...
					</div>
					<div>
						Large: Fullsize SUV, Prado, Cruiser etc...
					</div>
				</div>

				<Box sx={{ paddingBottom: 2 }}>
					<Field
						component={FormInput}
						inputComponent={ToggleButtonGroup}
						name="turret.size"
						type="checkbox"
						exclusive
						fullWidth
						color="primary"
						sx={{ border: !!formik.errors.turret?.size ? 1 : null, borderColor: !!formik.errors.turret?.size ? 'error.main' : null }}
					>
						<ToggleButton value="small">
							Small
						</ToggleButton>
						<ToggleButton value="medium">
							Medium
						</ToggleButton>
						<ToggleButton value="large">
							Large
						</ToggleButton>
					</Field>
					<FormHelperText error={!!formik.errors.turret?.size}>{formik.errors.turret?.size}</FormHelperText>
				</Box>

				<Grid container spacing={1}>
					<Grid item xs={4}>
						<Field
							component={FormInput}
							inputComponent={TextField}
							name="turret.trims"
							label="Trims"
							variant="outlined"
							fullWidth
							disabled={!formik.values.turret.size}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
								readOnly: true,
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<Field
							component={FormInput}
							inputComponent={TextField}
							name="turret.r&i"
							label="R&I"
							variant="outlined"
							fullWidth
							disabled={!formik.values.turret.size}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
								readOnly: true,
							}}
						/>
					</Grid>
					<Grid item xs={4}>
						<Field
							component={FormInput}
							inputComponent={TextField}
							name="turret.paint"
							label="Paint"
							variant="outlined"
							fullWidth
							disabled={!formik.values.turret.size}
							InputProps={{
								startAdornment: <InputAdornment position="start">$</InputAdornment>,
								readOnly: true,
							}}
						/>
					</Grid>
				</Grid>

				<CustomList.Main
					name="turret_misc"
					headers={[["Item"], ["Price", 10]]}
					baseItems={baseTurretMiscItems}
					values={miscItems}
					readOnly={isCompleted}
				>
					<CustomList.List>
						{(list) => {
							return list.map((item) => (
								<CustomList.ListItem key={item.key} value={item}>
									{({ index, isVirtual }) => (
										<>
											<CustomList.ListColumn>
												<CustomList.Checkbox
													label={isVirtual ? '' : item.label}
													value={item}
													disabled={!formik.values.turret.size}
												/>
												{isVirtual && (
													<Field
														component={TextField}
														name={`turret_misc.[${index}].label`}
													/>
												)}
											</CustomList.ListColumn>
											<CustomList.ListColumn center>
												<Field
													component={TextField}
													name={`turret_misc.[${index}].price`}
													variant="standard"
													InputProps={{
														readOnly: !isVirtual,
														startAdornment: <InputAdornment position="start">{item.adornment}</InputAdornment>,
														defaultValue: item.price,
														inputMode: 'numeric',
														min: 0,
													}}
												/>
											</CustomList.ListColumn>
										</>
									)}
								</CustomList.ListItem>
							))
						}}
					</CustomList.List>
				</CustomList.Main>

			</React.Fragment>
		</EstimateFormPage>
	);
};

export default PageTurret;
