import { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, useMap, useMapEvent } from 'react-leaflet';
import { Box } from '@mui/material';


const EventsMap = ({ latitude, longitude, onLocationChange, interactive }) => {
	const [position, setPosition] = useState([latitude, longitude]);
	const isProgrammaticMove = useRef(false);
	const prevLatLng = useRef({ latitude, longitude })

	// Dynamically load Leaflet CSS when the component is rendered
	useEffect(() => {
		import('leaflet/dist/leaflet.css');
	}, []);

	// Handle map movement and get the new center position
	const handleMoveEnd = (map) => {
		const center = map.getCenter();
		const { lat, lng } = center;

		if (isProgrammaticMove.current) {
			isProgrammaticMove.current = false; // Reset after programmatic move
			return;
		}

		setPosition([lat, lng]);
		if (interactive) {
			onLocationChange({ lat, lng });
		}
	};

	const MapController = () => {
		const map = useMap();

		useEffect(() => {
			// Fly to the new location when latitude or longitude changes
			if (latitude !== prevLatLng.current.latitude || longitude !== prevLatLng.current.longitude) {
				isProgrammaticMove.current = true;
				map.flyTo([latitude, longitude], map.getZoom(), {
					duration: 2,
				});

				prevLatLng.current = { latitude, longitude };
			}
		}, [latitude, longitude, map]);

		return null;
	};

	const MapEvents = () => {
		const map = useMapEvent('moveend', () => handleMoveEnd(map));
		return null;
	};

	useEffect(() => {
		setPosition([latitude, longitude]);
	}, [latitude, longitude]);

	return (
		<Box style={{ position: 'relative', height: '100%', width: '100%' }}>
			<MapContainer
				center={position}
				zoom={13}
				style={{
					height: '100%',
					width: '100%',
				}}
			>
				<TileLayer
					maxZoom={18}
					attribution='&copy; OpenStreetMap contributors & CARTO'
					url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
				/>
				<MapController />
				<MapEvents />
			</MapContainer>
			<div style={{ zIndex: 1000, position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -100%)', pointerEvents: 'none' }}>
				<img src="https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png" alt="center marker" />
			</div>
			{!interactive && (
				<div style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: 1000,
					backgroundColor: 'rgba(255, 255, 255, 0.8)',
				}}></div>
			)}
		</Box>
	);
};

export default EventsMap;