import DeleteIcon from '@mui/icons-material/Delete';
import HelpIcon from '@mui/icons-material/Help';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback, useState } from "react";

const dialogDefaults = {
	alert: {
		title: "Alert",
		titleColour: "warning",
		buttonColour: "primary",
		confirmButtonText: "Ok",
		cancelButtonText: "",
		icon: <WarningIcon />,
	},
	info: {
		title: "Information",
		titleColour: "info",
		buttonColour: "primary",
		confirmButtonText: "Ok",
		cancelButtonText: "",
		icon: <InfoIcon />,
	},
	question: {
		title: "Question",
		titleColour: "primary",
		buttonColour: "primary",
		confirmButtonText: "Yes",
		cancelButtonText: "No",
		icon: <HelpIcon />,
	},
	delete: {
		title: "Delete Confirmation",
		titleColour: "error",
		buttonColour: "error",
		confirmButtonText: "Yes",
		cancelButtonText: "No",
		icon: <DeleteIcon />,
	},
};

const defaultProps = {
	...(dialogDefaults.alert),
	onConfirm: () => { },
	onCancel: () => { },
};


// context setup
const ConfirmationDialogContext = React.createContext({
	popupState: {},
	open: ({
		type = "alert",
		title = "",
		message = "",
		titleColour = "",
		buttonColour = "",
		confirmButtonText = "",
		cancelButtonText = "",
		icon = null,
		onConfirm = () => { },
		onCancel = () => { },
	}) => { },
	close: () => { },
});
export const __ConfirmationDialogContext = ConfirmationDialogContext;


export const ConfirmDialogProvider = ({ children }) => {
	const [props, setProps] = useState({ ...defaultProps });
	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'ConfirmationDialogDialog',
	});

	const open = useCallback((props) => {
		setProps({ ...(dialogDefaults[props.type] || {}), ...props });
		setTimeout(() => {
			popupState.open();
		}, 0);
	}, [popupState]);

	const close = useCallback(() => {
		popupState.close();
	}, [popupState]);


	return (
		<ConfirmationDialogContext.Provider value={{ open, close }}>
			<>
				<ConfirmationDialog
					open={popupState.isOpen}
					onClose={close}
					{...props}
				/>
				{children}
			</>
		</ConfirmationDialogContext.Provider>
	);
};


const ConfirmationDialog = ({ ...props }) => {
	return (
		<Dialog open={props.open} onClose={props.onClose}>
			<DialogTitle color={props.titleColour} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
				{props.icon}
				{props.title}
			</DialogTitle>
			<DialogContent>
				<DialogContentText>{props.message}</DialogContentText>
			</DialogContent>
			<DialogActions style={{ justifyContent: 'space-between' }}>
				{props.cancelButtonText && (
					<Button onClick={(e) => props.onCancel?.(props.onClose)} color="secondary">
						{props.cancelButtonText}
					</Button>
				)}
				{props.confirmButtonText && (
					<Button variant="contained" onClick={(e) => props.onConfirm?.(props.onClose)} color={props.buttonColour} autoFocus>
						{props.confirmButtonText}
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
