import { Box, Card, CardContent, Divider, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { FastField } from 'formik';
import { useCallback, useMemo } from 'react';
import FormInput from '~/components/Forms/FormInput';
import { Gate } from '~/components/Gate';
import ValueLabel from '~/components/ValueLabel';
import { DatePicker } from '~/components/formik-mui-x-datepickers/DatePicker';
import '../../assets/styles/review-print.css';
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import { capitalizeFirstLetter, concatinate, sprintf, toCurrency, toDate } from '../../helpers/stringHelpers';
import { useForm } from '../../hooks/useForm';
import { conventionalRepairTypes } from './data/data';


const DollarValue = ({ value }) => {
	return (
		<Typography className='dollars-container'>
			<span>$</span><span>{toCurrency(value)}</span>
		</Typography>
	);
}

const CategoryTitle = ({ title }) => {
	return (
		<Typography
			variant="h3"
			sx={{
				textTransform: "uppercase",
				marginTop: 2,
				marginBottom: 0.5,
			}}
		>
			{title}
		</Typography>
	);
};

const LabourLineItem = ({ item }) => {

	return (
		<Stack>
			<Grid container>

				<Grid item xs={5}>
					<Typography>
						{[
							item._panel.name,
							...(
								item.report
									? ['(R)']
									: []
							),
							...(
								item.pdr_alloy
									? ['(A)']
									: []
							)
						].join(' ')}
					</Typography>
				</Grid>

				<Grid item xs={4}>
					<Stack direction="row" spacing={1}>

						{!item.replace_panel && (
							<>
								{(item.conventional_repair && item.conventional_repair != "full") && (
									<Typography>
										{[
											conventionalRepairTypes[item.conventional_repair].label,
											...(
												(conventionalRepairTypes[item.conventional_repair].cost_array)
													? [`${Array.from(conventionalRepairTypes?.[item.conventional_repair]?.labels?.[(item._panel?.costs?.conventional_repair?.[item.conventional_repair] || []).indexOf(item[conventionalRepairTypes?.[item.conventional_repair]?.cost_array])])[0]}`]
													: []
											)
										].join(' - ')}
									</Typography>
								)}


								{item.conventional_repair == "full" && (
									<Typography>Full Conv</Typography>
								)}

								{((item.pdr_dent_count || item.pdr_class) && !item.conventional_repair) && (
									<Typography>PDR</Typography>
								)}

								{item.pdr_manual_price > 0 && (
									<Typography title="Manual Price">{'(M)'}</Typography>
								)}
							</>
						)}

						{item.replace_panel && (
							<Typography>NEW</Typography>
						)}
					</Stack>
				</Grid>

				<Grid item xs={3} textAlign="right">
					{(!item.pdr_manual_price && !item.replace_panel && item.pdr_dent_count && item.conventional_repair != "full") && (
						<Typography>
							{item.pdr_dent_count} / {(item.pdr_dent_size + '').capitalize()}
						</Typography>
					)}

					{(!item.replace_panel && item.pdr_class && item.conventional_repair != "full") && (
						<Typography>
							{item.pdr_class.capitalize()}
						</Typography>
					)}


					{(!item.replace_panel && item.pdr_manual_price > 0) && (
						<Typography>${toCurrency(item.pdr_manual_price)}</Typography>
					)}
				</Grid>

			</Grid>
		</Stack>
	);
}

const NoItems = ({ items = [] }) => {
	if (items.length > 0) {
		return null;
	}

	return (
		<Typography variant='body2'>No Items</Typography>
	);
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.body}:first-child`]: {
		paddingLeft: theme.spacing(0),
	},
	[`&.${tableCellClasses.body}:last-child`]: {
		paddingRight: theme.spacing(0),
	},

	[`&.${tableCellClasses.body}`]: {
		paddingBottom: theme.spacing(0),
		paddingTop: theme.spacing(0),
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'& td, & th': {
		border: 0,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

const PageReview = () => {

	const { submission, get } = useForm();

	const getTotal = useCallback((arr, key = 'price', defaultValue = 0) =>
		(arr || []).reduce((sum, item) => (sum + (+item[key])), defaultValue), []);

	const panelTotal = useMemo(() => Object.values(get("panels")).reduce((acc, panel) => (acc + (+panel?.totals?.total || 0)), 0), [get]);
	const subTotal = useMemo(() => panelTotal + getTotal(get("r&r")) + getTotal(get("misc")), [panelTotal, getTotal, get]);
	const jobTotal = useMemo(() => subTotal + (subTotal * 0.1), [subTotal]);

	return (
		<EstimateFormPage className='page-review'>
			<PageTitle>Review</PageTitle>

			<Card variant="outlined">
				<CardContent>
					<Grid container spacing={2}>
						<Grid item xs={12} md={6}>
							<FastField
								component={FormInput}
								inputComponent={DatePicker}
								name="booking.repair_date"
								label="Repair Date"
								slotProps={{
									actionBar: {
										actions: ['clear', 'today', 'accept']
									}
								}}
								textField={{ fullWidth: true }}
								fullWidth={true}
								format="dd/MM/yyyy"

							/>
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			{/* --- Job Details  --- */}
			<Grid container marginTop={2}>
				<Grid item xs={12}>
					<Typography color="primary" variant="h2" sx={{ fontWeight: "bold" }}>
						{submission.workprovider.name}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="subtitle1" color="primary" sx={{ fontWeight: "semibold" }}>
						Claim #: {submission.assessment?.claim_number || get("insurance.claim_number") || '-'}
					</Typography>
					<Typography variant="subtitle1" color="primary" sx={{ fontWeight: "semibold" }}>
						Rego: {submission.summary.rego || get("vehicle.rego") || '-'}
					</Typography>
					<Typography variant="subtitle1" sx={{ fontWeight: "semibold" }}>
						Submitted By: {submission.created_by.name} {toDate(submission.created_at, 'dd/MM/yy hh:mm a')}
					</Typography>
				</Grid>
			</Grid>

			<Grid container marginTop={2}>
				<Grid item xs={6}>
					<ValueLabel
						label={'Customer Name'}
						value={concatinate([submission.assessment?.customer?.first_name ?? get("customer.first_name"), submission.assessment?.customer?.last_name ?? get("customer.last_name")])}
					/>
				</Grid>

				<Grid item xs={6}>
					<ValueLabel
						label={'Company'}
						value={submission.assessment?.customer?.company_name ?? get("customer.company_name")}
					/>
				</Grid>

				<Grid item xs={6}>
					<ValueLabel
						label={'Phone'}
						value={submission.assessment?.customer?.phone_work ?? submission.assessment?.customer?.phone_home ?? get("customer.phone_number")}
					/>
				</Grid>

				<Grid item xs={6}>
					<ValueLabel
						label={'Mobile'}
						value={submission.assessment?.customer?.mobile ?? submission.assessment?.customer?.mobile_alt ?? get("customer.mobile_number")}
					/>
				</Grid>

				<Grid item xs={12}>
					<ValueLabel
						label={'Email'}
						value={submission.assessment?.customer?.email ?? submission.assessment?.customer?.email_alt ?? get("customer.email")}
					/>
				</Grid>
			</Grid>

			<Divider sx={{ marginTop: 2 }} />

			<Grid container marginTop={2}>
				<Grid item xs={12}>
					<Typography color="primary" variant="h2" fontWeight="bold">
						{submission.summary.vehicle ?? concatinate([
							get("vehicle.build_year"),
							get("vehicle.make"),
							get("vehicle.model"),
						])}, {get("vehicle.body_style").replace('_', ' ')}
					</Typography>
				</Grid>
			</Grid>

			<Grid container marginTop={2}>
				<Grid item xs={6}>
					<ValueLabel
						label={'Rego'}
						value={submission.summary.rego ?? get("vehicle.rego")}
					/>
				</Grid>

				<Grid item xs={6}>
					<ValueLabel
						label={'Odometer'}
						value={get("vehicle.klms")}
					/>
				</Grid>

				<Grid item xs={6}>
					<ValueLabel
						label={'Colour / Code (Paint Type)'}
						value={sprintf("%s %s (%s)", (submission?.assessment?.vehicle?.colour || get("vehicle.colour")), get("vehicle.paint_code.code"), get("vehicle.paint_type"))}
					/>
				</Grid>

				<Grid item xs={12} md={6}>
					<ValueLabel
						label={'VIN'}
						value={submission?.assessment?.vehicle?.vin || get("vehicle.vin")}
					/>
				</Grid>

				<Grid item xs={12}>
					<ValueLabel
						label={'Transmission'}
						value={capitalizeFirstLetter(submission?.assessment?.vehicle?.transmission || get("vehicle.transmission"))}
					/>
				</Grid>
				<Grid item xs={6}>
					<ValueLabel
						label={'Calculation Method'}
						value={get("labour_quote_method")}
					/>
				</Grid>

				<Grid item xs={6}>
					<ValueLabel
						label={'Prestige'}
						value={get("prestige", false) ? "Yes" : "No"}
					/>
				</Grid>
			</Grid>

			<Divider sx={{ marginTop: 2 }} />

			<Typography color="primary" variant="h2" textTransform="uppercase" fontWeight="bold" marginTop={2} gutterBottom>{(submission.meta?.job_category ?? '').replace('_', ' ')} - {submission.meta?.job_severity || ''}</Typography>

			{/* --- R&R  --- */}
			{get("r&r", []).length > 0 && (
				<>
					<CategoryTitle title="Remove & Replace" />
					<TableContainer>
						<Table size="small">
							<TableBody>
								{get("r&r", []).filter(rr => rr._hidden_from_review !== true).map((RR) => (
									<StyledTableRow key={RR.key}>
										<StyledTableCell>
											<Stack spacing={1} direction="row">
												{RR.qty > 1 && (

													<Typography>{RR.qty} x </Typography>
												)}
												<Typography>{RR._review_label_override ?? RR.label}</Typography>
											</Stack>
										</StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<NoItems items={get("r&r").filter(rr => rr._hidden_from_review !== true)} />
				</>
			)}

			{/* --- Labour  --- */}
			<Grid container>
				<Grid item xs={12}>
					<CategoryTitle title="Repairs" />
				</Grid>
				<Grid item xs={12}>
					<Stack>
						{Object.values(get("panels")).map((panel, index) => (
							<LabourLineItem key={index} item={panel} />
						))}
					</Stack>
				</Grid>
			</Grid>
			<NoItems items={Object.values(get("panels"))} />

			{/* --- Parts  --- */}
			{get("parts", []).length > 0 && (
				<>
					<CategoryTitle title="Parts" />
					<TableContainer>
						<Table size="small">
							<TableBody>
								{get("parts").map((part) => (
									<StyledTableRow key={part.key}>
										<StyledTableCell><Typography>{part.label}</Typography></StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<NoItems items={get("parts")} />

					<ValueLabel
						label={'Badges'}
						value={get("badges")}
					/>
				</>
			)}

			{/* --- Sublets  --- */}
			<CategoryTitle title="Sublets" />
			<TableContainer>
				<Table size="small">
					<TableBody>
						{get("sublets.front_screen.new") && (
							<StyledTableRow>
								<StyledTableCell><Typography>New Front Screen</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("r&r.[front_screen]") && (
							<StyledTableRow>
								<StyledTableCell><Typography>R&R Front Screen</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("sublets.rear_screen.new") && (
							<StyledTableRow>
								<StyledTableCell><Typography>New Rear Screen</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("r&r.[rear_screen]") && (
							<StyledTableRow>
								<StyledTableCell><Typography>R&R Rear Screen</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("sublets.1/4_left.new") && (
							<StyledTableRow>
								<StyledTableCell><Typography>New L/H QTR Glass</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("r&r.[left_glass_quarter_panel]") && (
							<StyledTableRow>
								<StyledTableCell><Typography>R&R L/H QTR Glass</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("sublets.1/4_right.new") && (
							<StyledTableRow>
								<StyledTableCell><Typography>New R/H QTR Glass</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("r&r.[right_glass_quarter_panel]") && (
							<StyledTableRow>
								<StyledTableCell><Typography>R&R R/H QTR Glass</Typography></StyledTableCell>
							</StyledTableRow>
						)}

						{get("sublets.adas_calibration") && (
							<StyledTableRow>
								<StyledTableCell><Typography>ADAS Calibration - A.I.P</Typography></StyledTableCell>
							</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
			<NoItems items={[
				...(get("r&r.[front_screen]") ? [{ key: 'front_screen' }] : []),
				...(get("r&r.[rear_screen]") ? [{ key: 'rear_screen' }] : []),
				...(get("sublets.adas_calibration") ? [{ key: 'adas_calibration' }] : []),
			]} />

			{/* --- MISC  --- */}
			{get("misc", []).length > 0 && (
				<>
					<CategoryTitle title="Misc" />
					<TableContainer>
						<Table size="small">
							<TableBody>
								{get("misc", []).filter(misc => misc.key.startsWith("usr_")).map((misc) => (
									<StyledTableRow key={misc.key}>
										<StyledTableCell><Typography>{misc.label}</Typography></StyledTableCell>
										<StyledTableCell align="right" width="15%"><DollarValue value={misc.price} /></StyledTableCell>
									</StyledTableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
					<NoItems items={get("misc", []).filter(misc => misc.key.startsWith("usr_"))} />
				</>
			)}

			{/* --- ICD  --- */}
			<CategoryTitle title="Inconsistent" />
			<TableContainer>
				<Table size="small">
					<TableBody>
						{Object.values(get("panels", [])).filter(panel => panel.inconsistent_damage === true).map((panel) => (
							<StyledTableRow key={panel.key}>
								<StyledTableCell><Typography>{panel._panel.name}</Typography></StyledTableCell>
								<StyledTableCell><Typography>{panel.inconsistent_damage_unrepairable ? "Irrepairable" : "Repairable"}</Typography></StyledTableCell>
								<StyledTableCell><Typography>{panel.inconsistent_damage_percentage ?? '-'}%</Typography></StyledTableCell>
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<NoItems items={Object.values(get("panels")).filter(panel => panel.inconsistent_damage === true)} />


			<Gate has="tenant.apps.estimates.see_financials">

				<Divider sx={{ marginTop: 2 }} />

				<Grid container marginTop={2}>
					<Grid item xs={6}></Grid>
					<Grid item xs={6} textAlign="right">
						<Grid container>
							<Grid item xs={6} sx={{ pr: 2 }}><Typography>Labour</Typography></Grid>
							<Grid item xs={6}><DollarValue value={panelTotal} /></Grid>

							<Grid item xs={6} sx={{ pr: 2 }}><Typography>R&R</Typography></Grid>
							<Grid item xs={6}><DollarValue value={getTotal(get("r&r"))} /></Grid>

							<Grid item xs={6} sx={{ pr: 2 }}><Typography>MISC</Typography></Grid>
							<Grid item xs={6}><DollarValue value={getTotal(get("misc"))} /></Grid>
						</Grid>

					</Grid>
				</Grid>

				<Grid container marginTop={1}>
					<Grid item xs={6}></Grid>
					<Grid item xs={6} textAlign="right">
						<Grid container>
							<Grid item xs={6} sx={{ pr: 2 }}><Typography fontWeight="bold">Sub Total</Typography></Grid>
							<Grid item xs={6}><DollarValue value={subTotal} /></Grid>

							<Grid item xs={6} sx={{ pr: 2 }}><Typography fontWeight="bold">GST</Typography></Grid>
							<Grid item xs={6}><DollarValue value={subTotal * 0.1} /></Grid>

						</Grid>
						<Grid container sx={{
							borderTop: "1px solid #ccc",
							marginTop: 1,
							paddingTop: 1
						}}>
							<Grid item xs={6} sx={{ pr: 2 }}><Typography fontWeight="bold">Total</Typography></Grid>
							<Grid item xs={6}>
								<DollarValue value={jobTotal} />
							</Grid>
						</Grid>

					</Grid>
				</Grid>
			</Gate>

			<CategoryTitle title="Notes" />
			<Grid container marginTop={1}>
				<Grid item xs={12}>
					<Box sx={{
						border: "1px solid #ccc",
						borderRadius: 1,
						padding: 1,
						minHeight: 100
					}}>
						<Typography>{get("notes")}</Typography>
					</Box>
				</Grid>
			</Grid>

		</EstimateFormPage>
	);
};

export default PageReview;