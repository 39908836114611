import { useCallback, useMemo, useState } from "react";
import { exportSubmission, syncSubmission } from "~/requests/submissions";
import { enqueueSnackbar } from 'notistack';

export function useIBSSync(submission) {

	const [isProcessing, setIsProcessing] = useState(false);

	const proposeSync = useCallback((force = false, onFinish) => {

		setIsProcessing(true);
		exportSubmission(submission.uuid, {
			action: "ibs",
			force: force.toString()
		})
			.then((response) => {
				const data = response.data.data;
				onFinish?.(data, true);

			})
			.catch((error) => {
				console.error(error);

				enqueueSnackbar(error.response?.data?.data?.message || error.message, { variant: 'error' });
				if (error.response?.data?.data) {
					onFinish?.(error.response?.data?.data, false);
				}
			})
			.finally(() => {
				setIsProcessing(false);
			});
	}, [submission]);

	const enqueueSync = useCallback((quote, onFinish) => {

		syncSubmission(submission.uuid, {
			quote_id: quote.id,
			primary_detail_id: quote.primaryDetailId,
			quote_number: quote.estimateNumber + '',
		})
			.then((response) => {
				// const data = response.data.data;

				enqueueSnackbar(response.data.data.message);
			})
			.catch((error) => {
				console.error(error);

				enqueueSnackbar(error.message, { variant: 'error' });
			})
			.finally(() => {
				onFinish?.(data);
			});
	}, [submission]);


	return {
		proposeSync,
		enqueueSync,
		isProcessing
	};
}