import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import PageTitle from "../PageTitle";

const FormTitleBar = ({
	title = '',
	count = null
}) => {
	const theme = useTheme();

	const isXs = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Grid container sx={{ mb: 1 }}>
			<Grid item xs={12} sm={8}>
				<PageTitle gutterBottom={false}>{title}</PageTitle>
			</Grid>
			<Grid item xs={12} sm={4} paddingRight={1}>
				{count !== null && (
					<Typography
						color="primary"
						sx={{
							textAlign: !isXs ? "right" : "left",
							mt: !isXs ? 1 : "inital",
						}}
					>
						{count} selected
					</Typography>
				)}
			</Grid>
		</Grid>
	);
}

export default FormTitleBar;