import { Accordion, AccordionSummary, Chip, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Skeleton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useQuery } from '@tanstack/react-query';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { toDate, toHumanReadable } from '~/helpers/stringHelpers';
import { getActivitylog } from '~/requests/activityLog';

const ActivityLog = ({
	subject,
	subject_id,
	event,
}) => {

	const { data: _logs = [], isLoading } = useQuery({
		queryKey: ['activitylog', event, subject, subject_id,],
		queryFn: queryFunctionHelper(getActivitylog, event, subject, subject_id),
		staleTime: 1000 * 60 * 5,
		enabled: !!(event && subject && subject_id)
	});

	return (
		<>
			{isLoading && (
				<Skeleton height={40} />
			)}

			{
				!isLoading && (
					<Accordion sx={{ mt: 2 }}>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
						>
							Activity Logs
							{!!_logs.length && (
								<Chip sx={{ ml: 1 }} color="primary" label={_logs.length} size="small" />
							)}
						</AccordionSummary>
						<AccordionDetails>
							<TableContainer sx={{ maxHeight: 400 }}>
								<Table stickyHeader size="small">
									<TableHead>
										<TableRow>
											<TableCell>Status</TableCell>
											<TableCell>Description</TableCell>
											<TableCell>Date Attempted</TableCell>
											<TableCell>Date Finished</TableCell>
											<TableCell>Causer</TableCell>
											<TableCell>Error Message</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{_logs.map((row) => (
											<TableRow
												key={row.uuid}
												sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											>
												<TableCell>{toHumanReadable(row.status)}</TableCell>
												<TableCell>{row.description}</TableCell>
												<TableCell>{toDate(row.date_attempted, 'dd/MM/yy hh:mm a',)}</TableCell>
												<TableCell>{toDate(row.date_finished, 'dd/MM/yy hh:mm a')}</TableCell>
												<TableCell>{row.causer?.name}</TableCell>
												<TableCell sx={{
													maxWidth: 300,
												}}>{row.error_message}</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</AccordionDetails>
					</Accordion>
				)
			}
		</>
	);
}

export default ActivityLog;