import PropTypes from 'prop-types';
import { AssessmentDialogProvider } from "~/providers/dialogs/AssessmentDialogProvider";
import { AssessmentEditDialogProvider } from "~/providers/dialogs/AssessmentEditDialogProvider";
import { StartAuditDialogProvider } from "~/providers/dialogs/StartAuditDialogProvider";
import { StartEstimateDialogProvider } from "~/providers/dialogs/StartEstimateDialogProvider";

const providers = [
	AssessmentEditDialogProvider,
	AssessmentDialogProvider,
	StartAuditDialogProvider,
	StartEstimateDialogProvider
];

const GlobalDialogsProvider = ({ children }) => {
	return providers.reduceRight((acc, Provider) => {
		return <Provider>{acc}</Provider>;
	}, children);
};


GlobalDialogsProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
}

export default GlobalDialogsProvider;