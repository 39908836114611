import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import debounce from 'lodash.debounce';
import { useCallback, useEffect, useState } from 'react';

const SearchField = ({
	placeholder = 'Search...',
	onChange,
	minLength = 2,
	inputRef = null,
	isLoading = false,
	className = '',
	onFocus = null,
	onBlur = null,
	onClick = null,
}) => {

	const [value, setValue] = useState('');

	const throttledhandleChange = debounce(onChange, 200, {
		leading: false,
		trailing: true,
	});

	const handleChange = useCallback((e) => {
		const value = String(e.target.value).trim();

		throttledhandleChange(value.length >= minLength ? value : '');
		setValue(e.target.value);
	}, [minLength, throttledhandleChange]);

	const handleClear = useCallback((e) => {
		setValue('');
		onChange?.('');
	}, [onChange]);

	const handleFocus = useCallback((e) => {
		onFocus?.(e);
	}, [onFocus]);

	const handleBlur = useCallback((e) => {
		onBlur?.(e);
	}, [onBlur]);

	const handleClick = useCallback((e) => {
		onClick?.(e);
	}, [onClick]);

	useEffect(() => {
		inputRef.current.focus();
	}, [inputRef]);

	return (
		<TextField
			placeholder={placeholder}
			value={value}
			onFocus={handleFocus}
			onBlur={handleBlur}
			onClick={handleClick}
			onChange={handleChange}
			autoFocus
			autoComplete="off"
			autoCorrect="off"
			autoCapitalize="none"
			spellCheck="false"
			fullWidth
			className={className}
			inputRef={inputRef}
			inputProps={{
				minLength: minLength
			}}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						{(value.length > 0 && !isLoading) && (
							<IconButton
								onClick={handleClear}
								onMouseDown={(e) => e.preventDefault()}
								edge="end"
							>
								<ClearIcon />
							</IconButton>
						)}

						{(!(value.length > 0) && !isLoading) && (
							<IconButton
								onMouseDown={(e) => e.preventDefault()}
								edge="end"
							>
								<SearchIcon />
							</IconButton>
						)}

						{isLoading && (
							<IconButton
								onMouseDown={(e) => e.preventDefault()}
								edge="end"
							>
								<CircularProgress color="inherit" sx={{ p: 1 }} />
							</IconButton>
						)}
					</InputAdornment>
				)
			}}
		/>
	);
}

export default SearchField;