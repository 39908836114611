import PersonIcon from '@mui/icons-material/Person';
import { Card, Container, Fab, CircularProgress } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FixedFooter from '~/components/FixedFooter';
import Loader from '~/components/Loader';
import Page from "~/components/Page";
import PageTitle from "~/components/PageTitle";
import { setFormikErrors } from '~/helpers/generalHelper';
import { useCrud } from '~/hooks/useCrud';
import DepartmentForm from '~/pages/forms/crud/DepartmentForm';
import { createDepartment } from '~/requests/departments';

const DepartmentNewPage = () => {
	const crud = useCrud({ createRequest: createDepartment, queryKey: 'departments' });
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const formikRef = useRef();

	const handleSave = () => {
		if (formikRef.current) {
			formikRef.current.submitForm();  // This triggers Formik's submitForm function
		}
	};

	const handleSubmit = (id, values, { setSubmitting }) => {
		setIsLoading(true);

		crud.doSave({
			id: id, values, toastLabel: 'Department',
			onSettled: () => {
				setIsLoading(false);
				setSubmitting(false);
			},
			onSuccess: () => {
				navigate(-1);
			},
			onError: (error, errors) => setFormikErrors(formikRef, errors)
		});
	};

	const title = `Create Department`;

	return (
		<Page title="Create Department">
			{isLoading && <Loader />}

			<PageTitle gutterBottom icon={<PersonIcon />}>{title}</PageTitle>

			<Container>
				<Card sx={{ p: 3 }}>
					<NewForm formikRef={formikRef} handleSubmit={handleSubmit} />
				</Card>
			</Container>

			<FixedFooter>
				<Fab color="secondary" variant="extended" onClick={() => navigate(-1)}>
					Cancel
				</Fab>
				<Fab color="primary" variant="extended" onClick={handleSave} disabled={isLoading}>
					{isLoading && (
						<CircularProgress color="inherit" sx={{ p: 1, mr: 1 }} />
					)}
					Save Department
				</Fab>
			</FixedFooter>
		</Page>
	);
}

export default DepartmentNewPage;


const NewForm = ({ formikRef, handleSubmit }) => {
	return (
		<DepartmentForm ref={formikRef} department={{}} isNew={true} onSubmit={(values, options) => handleSubmit(null, values, options)} />
	);
}