import { Box, Button, Divider, Grid, IconButton, InputAdornment, Stack, Tooltip, Typography } from "@mui/material";
import { Field, FieldArray, Formik, useFormikContext } from "formik";
import { TextField } from "formik-mui";
import { forwardRef, useEffect, useState } from 'react';
import { Form } from 'react-router-dom';
import * as Yup from 'yup';
import { DatePicker } from "~/components/formik-mui-x-datepickers/DatePicker";
import SuburbAutocompleteField from "~/components/SuburbAutocompleteField";
import { mergeObjectLeft } from "~/helpers/dataHelpers";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { DateTime } from "luxon";
import EventsMap from "~/components/EventsMap";
import WorkProviderDropdown from "~/components/Dropdowns/WorkProviderDropdown";
import DeleteIcon from '@mui/icons-material/Delete';

// whitelist of fields and default values
const formTemplate = {
	name: '',
	date: null,
	description: "",
	suburb: '',
	postcode: '',
	latitude: '',
	longitude: '',
	workproviders: []
};

const validationSchema = () => Yup.object().shape({
	name: Yup.string().required('Required').min(2, 'Name is too Short').max(100, 'Name is too long'),
	date: Yup.date().required('Required'),
	suburb: Yup.string().required('Required'),
	postcode: Yup.number().typeError('Invalid postcode').min(1000, 'Invalid postcode').max(9999, 'Invalid postcode').nullable(),
});

const EventForm = forwardRef(({ entity = null, isNew = false, onSubmit }, ref) => {

	const [nameLocked, setNameLocked] = useState(true);

	const handleSubmit = (values, formikBag) => {
		// send values and inject other values if applicable
		const formValues = {
			...values,
		};

		onSubmit(formValues, formikBag);
	};

	return (
		<Formik
			initialValues={mergeObjectLeft(formTemplate, entity)}
			onSubmit={handleSubmit}
			validationSchema={validationSchema}
			innerRef={ref}
		>
			{formik => (
				<Form>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={6} display="flex" flexDirection="column" gap={2}>
							<Field
								component={TextField}
								name="name"
								label="Name"
								fullWidth
								required
								helperText="This is generated based on the date, work provider and suburb. You can unlock it to manually set."
								InputProps={{
									readOnly: nameLocked,
									endAdornment: nameLocked ? <InputAdornment position="end">
										<IconButton onClick={() => setNameLocked(false)}>
											<Tooltip title="Unlock">
												<LockOpenIcon
													color="primary"
												/>
											</Tooltip>
										</IconButton>
									</InputAdornment> : null
								}}
							/>

							<Field
								component={TextField}
								name="description"
								label="Description"
								fullWidth
							/>

							<SuburbAutocompleteField name="suburb" required onSuburbSelected={suburb => {
								formik.setFieldTouched('postcode', true, false);
								formik.setFieldValue('postcode', suburb.postcode);

								formik.setFieldValue('latitude', suburb.latitude);
								formik.setFieldValue('longitude', suburb.longitude);
							}} fullWidth />

							<Field
								component={TextField}
								name="postcode"
								label="Postcode"
								fullWidth
								helperText="This is automatically set based on the suburb selected"
							/>

							<Field
								component={DatePicker}
								name="date"
								label="Date Of Event"
								format="dd/MM/yyyy"
								slotProps={{
									actionBar: {
										actions: ['cancel', 'clear', 'today', 'accept']
									}
								}}
								textField={{ fullWidth: true, required: true }}
								fullWidth
							/>

							<Divider />

							<Typography variant="h3">Work Provider Codes</Typography>


							<FieldArray name="workproviders">
								{({ remove, push }) => (
									<>
										<WorkProviderDropdown
											label="Add Work Provider"
											returnWorkprovider
											size="small"
											exclude={formik.values.workproviders.map(wp => wp.id)}
											showNone={false}
											onChange={workprovider => push({
												code: '',
												workprovider_uuid: workprovider.id,
												...workprovider
											})}
										/>
										<Box>
											{formik.values.workproviders && formik.values.workproviders.length > 0 && formik.values.workproviders.map((workprovider, index) => (
												<Box key={index} sx={{
													border: 1,
													borderRadius: 1,
													padding: 1,
													marginTop: 1,
													borderColor: 'secondary.light'
												}}>
													<Typography variant="h4" gutterBottom sx={{ mb: 1 }}>{workprovider.name}</Typography>


													<Stack direction="row" gap={1}>
														<Field
															component={TextField}
															name={`workproviders.${index}.code`}
															label="Code"
															fullWidth
															size="small"
															helperText="Code the work provider uses for this event (if different from the name). Eg CAT code for RACQ"
														/>
														<Button
															color="secondary"
															variant='outlined'
															size='small'
															onClick={() => remove(index)}>
															<DeleteIcon />
														</Button>
													</Stack>
												</Box>
											))}
										</Box>
									</>
								)}
							</FieldArray>



						</Grid>
						<Grid item xs={12} sm={6} >
							<EventsMap
								latitude={formik.values.latitude || -27.66101692486096}
								longitude={formik.values.longitude || 153.03152442763061}
								interactive={formik.values.suburb !== ''}
								onLocationChange={({ lat, lng }) => {
									formik.setFieldValue('latitude', lat);
									formik.setFieldValue('longitude', lng);
								}}
							/>
						</Grid>
					</Grid>
					<FormikContextHelper nameLocked={nameLocked} />
				</Form>
			)}
		</Formik>
	);
});

const formatName = (suburb, date) => {
	if (!suburb || !date) return '';

	const D = DateTime.fromISO(date);
	return `${suburb.toUpperCase().replaceAll(' ', '').substring(0, 10)}.${D.toFormat('dd')}${D.toFormat('MMM').toUpperCase()}${D.toFormat('yyyy').toUpperCase()}`;
}

const FormikContextHelper = ({ nameLocked }) => {
	const formik = useFormikContext();

	useEffect(() => {
		if (formik.values.suburb && formik.values.date && nameLocked) {
			formik.setFieldValue('name', formatName(formik.values.suburb, formik.values.date));
		}
	}, [nameLocked, formik.values.suburb, formik.values.date]);

	return null;
}

EventForm.displayName = 'EventForm';

export default EventForm;