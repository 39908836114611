import { QueryClient, queryOptions } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { getDebtor, getDebtors } from "~/requests/debtors";

/**
 * Creates a query for fetching debtor details based on the provided parameters.
 *
 * @param {object} [params={}] - The optional parameters for fetching debtor details.
 * @returns {object} The query options for fetching debtor details.
 *
 * @example
 * // Fetching all debtors
 * const allDebtorsQueryOptions = debtorsQuery();
 * const { data } = useQuery(allDebtorsQueryOptions);
 *
 * // Fetching debtors with specific parameters
 * const filteredDebtorsQueryOptions = debtorsQuery({ param1: value1, param2: value2 });
 * const { data } = useQuery(filteredDebtorsQueryOptions);
 */
export const debtorsQuery = (params = {}) => queryOptions({
	queryKey: ['debtors', params],
	queryFn: queryFunctionHelper(getDebtors, params)
});

/**
 * Creates a query for fetching debtor details based on the debtor ID.
 *
 * @param {string} id - The ID of the debtor to fetch details for.
 * @returns {object} The query options for fetching debtor details.
 *
 * @example
 * const debtorQueryOptions = debtorQuery("debtorID");
 * const { data } = useQuery(debtorQueryOptions);
 */
export const debtorQuery = (id) => queryOptions({
	queryKey: ['debtors', id],
	queryFn: queryFunctionHelper(getDebtor, id),
	enabled: id !== null
});

/**
* Creates a loader function for ensuring debtor data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {function} The loader function that ensures debtor data is available.
*
* @throws {Error} Throws an error if no debtor ID is provided.
*
* @example
* const { id } = useLoaderData();
*/
export const debtorLoader = (queryClient) => async ({ params }) => {
	if (!params.id) {
		throw new Error('No debtor ID provided');
	}
	await queryClient.ensureQueryData(debtorQuery(params.id));
	return { id: params.id };
};

/**
* Creates a loader function for ensuring debtor data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {Object} Empty object
*
* @example
* const { } = useLoaderData();
*/
export const debtorsLoader = (queryClient) => async () => {
	await queryClient.ensureQueryData(debtorsQuery());
	return {};
};
