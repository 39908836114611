import { Badge, Box, IconButton, Stack } from "@mui/material";
import CameraIcon from '@mui/icons-material/Camera';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import CollectionsIcon from '@mui/icons-material/Collections';
import { useState, useCallback, useContext, useMemo } from "react";
import { CameraContext } from "./CameraContext";
import useOrientation from "~/hooks/useOrientation";
import OptionalComponent from "../OptionalComponent";
const CameraButtons = () => {

	const { toggleDirection, takePhoto, supportedConstraints, cameraCount, photos, openGallery } = useContext(CameraContext);

	const [rotation, setRotation] = useState(0);

	const orientation = useOrientation();


	const handleFlipClick = useCallback(() => {
		setRotation(prevRotation => prevRotation - 180);
		toggleDirection();
	}, [toggleDirection]);

	const canFlip = useMemo(() => supportedConstraints.facingMode && cameraCount > 1, [supportedConstraints, cameraCount]);

	return (
		<Box sx={{
			display: 'flex',
			justifyContent: 'space-around',
			alignItems: 'center',
			p: 1,
			flexDirection: orientation === 'landscape' ? 'column' : 'row'
		}} id="camera-buttons">
			<IconButton
				aria-label="flip camera"
				color="secondary"
				size="large"
				onClick={handleFlipClick}
				disabled={!canFlip}
			>
				<FlipCameraAndroidIcon sx={{
					transition: 'transform 0.5s',
					transform: `rotate(${rotation}deg)`
				}} />
			</IconButton>

			<IconButton aria-label="take picture" size="large" color="primary" onClick={takePhoto}>
				<CameraIcon sx={{ fontSize: 46 }} />
			</IconButton>

			<OptionalComponent
				condition={photos.length !== 0}
				wrapper={Badge}
				wrapperProps={{ color: 'info', badgeContent: ' ', variant: 'dot' }}
			>
				<IconButton aria-label="gallery" color="secondary" size="large" disabled={photos.length === 0} onClick={openGallery}>
					<CollectionsIcon />
				</IconButton>
			</OptionalComponent>

		</Box>
	);
}

export default CameraButtons;