import apiHandler from "../apiHandler";

export const getDebtors = (params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "debtors",
	dataOrParams: params,
	signal
});

export const getDebtor = (uuid, signal = undefined) => apiHandler({
	method: "get",
	url: "debtors/" + uuid,
	signal
});

export const createDebtor = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `debtors`,
	dataOrParams: params,
	signal
});

export const updateDebtor = (id, params = {}, signal = null) => apiHandler({
	method: "put",
	url: `debtors/${id}`,
	dataOrParams: params,
	signal
});

export const deleteDebtor = (id, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `debtors/${id}`,
	dataOrParams: params,
	signal
});

export const restoreDebtor = (id, params = {}, signal = null) => apiHandler({
	method: "post",
	url: `debtors/${id}/restore`,
	dataOrParams: params,
	signal
});
