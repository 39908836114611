import { getSubmission, getSubmissionValues } from "~/requests/submissions";

export const submissionValuesQuery = (id) => ({
	queryKey: ['form', 'estimate', 'submission', id, 'values'],
	queryFn: async ({ signal }) => (await getSubmissionValues(id, {}, signal))?.data?.data,
	refetchOnWindowFocus: false,
	enabled: !!id,
	staletime: 1000 * 1, // 1 seconds
});

export const submissionQuery = (id) => ({
	queryKey: ['form', 'estimate', 'submission', id],
	queryFn: async ({ signal }) => (await getSubmission(id, {}, signal))?.data?.data,
	refetchOnWindowFocus: false,
	staletime: 1000 * 1, // 1 seconds
	enabled: !!id
});

export const estimateFormLoader =
	(queryClient) =>
		async ({ params }) => {
			const _submissionQuery = submissionQuery(params.submissionId)
			const valuesQuery = submissionValuesQuery(params.submissionId)
			return ({
				submission: queryClient.getQueryData(_submissionQuery.queryKey) ?? (await queryClient.fetchQuery(_submissionQuery)),
				values: queryClient.getQueryData(valuesQuery.queryKey) ?? (await queryClient.fetchQuery(valuesQuery))
			})
		}