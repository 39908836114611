import { Box, Stack, Typography } from '@mui/material'
import TileButton from './TileButton'

const AssessmentCard = ({ assessment, onClick = () => { } }) => {
	return (
		<TileButton sx={{ p: 0.5, display: 'block' }} onClick={onClick}>
			<Box sx={{ p: 1, display: 'flex', justifyContent: 'space-between' }}>
				<Typography fontWeight={500}>
					Claim No: {assessment.claim_number}
				</Typography>
				<Typography fontWeight={500}>
					Rego: {assessment.vehicle?.rego}
				</Typography>
			</Box>

			<Stack direction='column' width='100%' padding={1}>
				<Typography variant="body2">
					Facility: {assessment.facility?.name}
				</Typography>
				<Typography variant="body2">
					Event: {assessment.facility?.event?.name}
				</Typography>
				<Typography variant="body2">
					Customer: {[assessment.customer?.full_name || '', assessment.customer?.company_name || ''].join(' ')}
				</Typography>
				<Typography variant="body2">
					Vehicle: {[assessment.vehicle?.make || '', assessment.vehicle?.model || '', assessment.vehicle?.badge || '', `- ${assessment.vehicle?.colour || ''}`].join(' ')}
				</Typography>
			</Stack>
		</TileButton>
	)
}

export default AssessmentCard