import { useMutation, useQueryClient } from "@tanstack/react-query";
import { enqueueSnackbar } from 'notistack';
import { isArray, isObject } from "~/helpers/objectHelper";

export function useCrud({
	createRequest = null, // reference to api function
	updateRequest = null, // reference to api function
	deleteRequest = null, // reference to api function
	restoreRequest = null, // reference to api function
	queryKey = null, // query key to invalidate on save/delete
}) {

	const queryClient = useQueryClient();

	const saveMutation = useMutation({
		mutationFn: (data) => {
			// if id given, update, otherwise create (unless no createRequest is provided, then just try update)
			if (data.id) {
				return updateRequest(data.id, data);
			} else {
				return createRequest ? createRequest(data) : updateRequest(data);
			}
		},
	});

	const deleteMutation = useMutation({
		mutationFn: (id) => deleteRequest(id)
	});

	const restoreMutation = useMutation({
		mutationFn: (id) => restoreRequest(id)
	});

	const doSave = async ({ id = null, values, toastLabel = "", onSuccess = null, onError = null, onSettled = null }) => {
		const modifiedData = { ...(id ? { id } : {}), ...values };

		saveMutation.mutate(modifiedData, {
			onSettled: (data, error) => {
				onSettled && onSettled({ data, error });
				if (error) {
					console.error(error);
					const errorMsg = error.response?.data?.exception?.message ?? `Failed to save ${toastLabel}`;
					toastLabel && enqueueSnackbar(errorMsg, { variant: 'error' });
					onError && onError(errorMsg, error.response?.data?.errors);
				} else {
					toastLabel && enqueueSnackbar(`${toastLabel} saved successfully`);
					onSuccess && onSuccess(data);
				}

				if (queryKey) {
					queryClient.invalidateQueries({
						queryKey: isArray(queryKey) ? queryKey : [queryKey],
						type: 'all'
					});
				}
			}
		});
	};

	const doDelete = async ({ id, toastLabel = "", onSuccess = null, onError = null, onSettled = null }) => {
		deleteMutation.mutate(id, {
			onSettled: (data, error) => {
				onSettled && onSettled({ data, error });
				if (error) {
					console.error(error);
					const errorMsg = error.response?.data?.exception?.message;
					toastLabel && enqueueSnackbar(`Failed to delete ${toastLabel}\n${errorMsg}`, { variant: 'error' });
					onError && onError(errorMsg, error.response?.data?.errors);
				} else {
					toastLabel && enqueueSnackbar(`${toastLabel} deleted successfully`);
					onSuccess && onSuccess(data);
				}

				if (queryKey) {
					queryClient.invalidateQueries({
						queryKey: isArray(queryKey) ? queryKey : [queryKey],
						type: 'all'
					});
				}
			}
		});
	};

	const doRestore = async ({ id, toastLabel = "", onSuccess = null, onError = null, onSettled = null }) => {
		restoreMutation.mutate(id, {
			onSettled: (data, error) => {
				onSettled && onSettled({ data, error });
				if (error) {
					console.error(error);
					const errorMsg = error.response?.data?.data?.message;
					toastLabel && enqueueSnackbar(`Failed to restore ${toastLabel}\n${errorMsg}`, { variant: 'error' });
					onError && onError(errorMsg, error.response?.data?.errors);
				} else {
					toastLabel && enqueueSnackbar(`${toastLabel} restored successfully`);
					onSuccess && onSuccess(data);
				}

				if (queryKey) {
					queryClient.invalidateQueries({
						queryKey: isArray(queryKey) ? queryKey : [queryKey],
						type: 'all'
					});
				}
			}
		});
	}

	return {
		doSave,
		doDelete,
		doRestore
	};
}