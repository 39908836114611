import { useContext } from "react";
import { __FilterBarContext } from "~/providers/FilterBarProvider";

export function useFilters() {
	const context = useContext(__FilterBarContext);
	const { getFilterValue, updateFilterValue, clearFilterValue, resetFilters, filters } = context;

	return {
		getFilterValue,
		updateFilterValue,
		clearFilterValue,
		resetFilters,
		filters
	};
}