import React, { memo } from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';

// styles
const LoaderWrapper = styled('div')({
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 1301,
	width: '100%'
});

const Loader = memo(() => (
	<LoaderWrapper>
		<LinearProgress color="primary" />
	</LoaderWrapper>
));

Loader.displayName = 'Loader';

export default Loader;
