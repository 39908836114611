import { Skeleton } from "@mui/lab";
import { Button, ButtonGroup, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useMemo } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { useForm } from "~/hooks/useForm";
import { submissionQuery } from "~/loaders/estimateFormLoader";
import { getChildren } from "~/requests/submissions";


const FormChildrenTabs = () => {
	const { parentSubmission } = useForm();
	const { submissionId } = useParams();
	const navigate = useNavigate();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const parentUuid = useMemo(() => (parentSubmission?.uuid || submissionId), [parentSubmission?.uuid, submissionId]);
	const queryKey = [...submissionQuery(parentUuid).queryKey, 'children'];

	const { data: children = [], isLoading } = useQuery({
		queryKey: queryKey,
		queryFn: queryFunctionHelper(getChildren, parentUuid),
		staleTime: 1000 * 60 * 5,
		enabled: !!parentUuid
	});

	if ((children?.length || []) === 1) {
		return null;
	}

	const OptionalTooltip = !isMobile ? React.Fragment : Tooltip;

	return (
		<>
			{isLoading && (
				<Skeleton height={80} />
			)}

			<ButtonGroup variant="outlined" fullWidth disableElevation size="large">
				{children?.map((child, index) => (
					<OptionalTooltip {...(!isMobile ? {} : { title: (index == 0 ? 'Estimate' : `Audit ${index}`), key: index })}>

						<Button
							key={child.uuid}
							variant={child.uuid == submissionId ? 'contained' : 'outlined'}
							onClick={() => navigate(`/forms/${child.form.key}/${child.uuid}`)}
							// endIcon={<CircleTwoToneIcon color="error" />}

							sx={{
								'&:first-child': {
									borderTopLeftRadius: 18,
									borderBottomLeftRadius: 18,
								},
								'&:last-child': {
									borderTopRightRadius: 18,
									borderBottomRightRadius: 18,
								},

							}}
						>
							{index == 0 ? (isMobile ? 'Est' : 'Estimate') : `${isMobile ? 'Au' : 'Audit'} ${index}`}
						</Button>
					</OptionalTooltip>
				))}
			</ButtonGroup>
		</>
	);
}

export default FormChildrenTabs;