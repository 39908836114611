export const getWindow = () => {
	if (window.PHMS === undefined) {
		window.PHMS = {
			auth: {
				logout: () => { },
			}
		};
	}

	return window.PHMS;
}

export const handleDownload = (path, filename = "") => {
	const anchor = document.createElement('a');
	anchor.href = path;
	anchor.download = filename;
	// Append the anchor element to the body
	document.body.appendChild(anchor);

	// Trigger a click event on the anchor element
	anchor.click();

	// Remove the anchor element from the body
	document.body.removeChild(anchor);
};