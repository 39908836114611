import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const DialogCloseButton = ({ onClick }) => {
  return (
    <IconButton
      aria-label="close"
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
      }}
      onClick={onClick}
    >
      <CloseIcon />
    </IconButton>
  )
}

export default DialogCloseButton