import { Chip } from "@mui/material";

const EventChip = ({
	event,
	...props
}) => {

	if (!event) {
		return null;
	}

	return (
		<Chip label={event.name} {...props} size="small" />
	);
}

export default EventChip;