import apiHandler from "../apiHandler";

export const getWorkproviders = (params = {}, signal = null) => apiHandler({
	method: "get",
	url: "workproviders",
	dataOrParams: params,
	signal
});

export const getWorkprovider = (id, signal = null) => apiHandler({
	method: "get",
	url: `workproviders/${id}`,
	signal
});

export const createWorkprovider = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `workproviders`,
	dataOrParams: params,
	signal
});

export const updateWorkprovider = (id, params = {}, signal = null) => apiHandler({
	method: "put",
	url: `workproviders/${id}`,
	dataOrParams: params,
	signal
});

export const deleteWorkprovider = (id, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `workproviders/${id}`,
	dataOrParams: params,
	signal
});

export const restoreWorkprovider = (id, params = {}, signal = null) => apiHandler({
	method: "post",
	url: `workproviders/${id}/restore`,
	dataOrParams: params,
	signal
});

export const resetWorkproviderAPiToken = (id, signal = null) => apiHandler({
	method: "get",
	url: `workproviders/${id}/reset-token`,
	signal
});