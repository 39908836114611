import PersonIcon from '@mui/icons-material/Person';
import { Card, Container, Fab } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import FixedFooter from '~/components/FixedFooter';
import Loader from '~/components/Loader';
import Page from "~/components/Page";
import PageTitle from "~/components/PageTitle";
import { setFormikErrors } from '~/helpers/generalHelper';
import { useAuth } from '~/hooks/useAuth';
import { useCrud } from '~/hooks/useCrud';
import UserForm from "~/pages/forms/crud/UserForm";
import { updateProfile } from '~/requests/auth';

const ProfilePage = () => {
	const { user, refreshProfile } = useAuth();
	const crud = useCrud({ updateRequest: updateProfile, queryKey: 'users' });
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const formikRef = useRef();

	const handleSave = () => {
		if (formikRef.current) {
			formikRef.current.submitForm();  // This triggers Formik's submitForm function
		}
	};

	const handleSubmit = (values, { setSubmitting }) => {
		setIsLoading(true);
		crud.doSave({
			values, toastLabel: "Profile",
			onSettled: () => {
				setIsLoading(false);
				setSubmitting(false);
			},
			onSuccess: () => {
				refreshProfile();
				navigate(-1);
			},
			onError: (error, errors) => setFormikErrors(formikRef, errors)
		});
	};

	return (
		<Page title="My Profile">
			{isLoading && <Loader />}

			<PageTitle gutterBottom icon={<PersonIcon />}>My Profile</PageTitle>

			<Container>
				<Card sx={{ p: 3 }}>
					<UserForm ref={formikRef} entity={user} isMyProfile onSubmit={handleSubmit} />
				</Card>
			</Container>

			<FixedFooter>
				<Fab color="secondary" variant="extended" onClick={() => navigate(-1)}>
					Cancel
				</Fab>
				<Fab color="primary" variant="extended" onClick={() => handleSave()}>
					Save
				</Fab>
			</FixedFooter>
		</Page>
	)
}

export default ProfilePage