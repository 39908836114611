import PersonIcon from '@mui/icons-material/Person';
import { Card, CircularProgress, Container, Fab } from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CRUDEntityPage from '~/components/CRUD/CRUDEntityPage';
import FixedFooter from '~/components/FixedFooter';
import Loader from '~/components/Loader';
import Page from "~/components/Page";
import PageTitle from "~/components/PageTitle";
import { setFormikErrors } from '~/helpers/generalHelper';
import { useCrud } from '~/hooks/useCrud';
import { eventQuery } from '~/loaders/eventsLoader';
import EventForm from '~/pages/forms/crud/EventForm';
import { createEvent } from '~/requests/events';

const EventNewPage = () => {

	return <CRUDEntityPage
		entityLabel="Event"
		entityQuery={eventQuery}

		createRequest={createEvent}

		queryKey={'events'}
		entityForm={EventForm}
	/>;

	const crud = useCrud({ createRequest: createEvent, queryKey: 'events' });
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const formikRef = useRef();

	const handleSave = () => {
		if (formikRef.current) {
			formikRef.current.submitForm();  // This triggers Formik's submitForm function
		}
	};

	const handleSubmit = (id, values, { setSubmitting }) => {
		setIsLoading(true);

		crud.doSave({
			id: id, values, toastLabel: 'Event',
			onSettled: () => {
				setIsLoading(false);
				setSubmitting(false);
			},
			onSuccess: () => {
				navigate(-1);
			},
			onError: (error, errors) => setFormikErrors(formikRef, errors)
		});
	};

	const title = `Create Event`;

	return (
		<Page title="Create Event">
			{isLoading && <Loader />}

			<PageTitle gutterBottom icon={<PersonIcon />}>{title}</PageTitle>

			<Container>
				<Card sx={{ p: 3 }}>
					<NewForm formikRef={formikRef} handleSubmit={handleSubmit} />
				</Card>
			</Container>

			<FixedFooter>
				<Fab color="secondary" variant="extended" onClick={() => navigate(-1)}>
					Cancel
				</Fab>
				<Fab color="primary" variant="extended" onClick={handleSave} disabled={isLoading}>
					{isLoading && (
						<CircularProgress color="inherit" sx={{ p: 1, mr: 1 }} />
					)}
					Save Event
				</Fab>
			</FixedFooter>
		</Page>
	);
}

export default EventNewPage;


const NewForm = ({ formikRef, handleSubmit }) => {
	return (
		<EventForm ref={formikRef} event={{}} isNew={true} onSubmit={(values, options) => handleSubmit(null, values, options)} />
	);
}