import { Box } from '@mui/material';
import { useLayoutEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDocumentTitle } from "usehooks-ts";
import { useAuth } from '../hooks/useAuth';
import Header from '../layout/Header';


const OldPage = ({
	title = '',
	hasHeader = true,
	children,
	sx = {},
	...props
}) => {
	useDocumentTitle(`PHMS - ${title}`);

	const { authenticated } = useAuth();
	const { pathname } = useLocation();
	const contentRef = useRef();

	useLayoutEffect(() => {
		// on navigation, scroll to top of page
		if (contentRef) contentRef.current.scrollTo(0, 0);
	}, [pathname]);

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh', overflow: 'hidden', ...sx }} {...props}>
			{(authenticated) && (
				<Header />
			)}

			<Box
				ref={contentRef}
				className='page-content'
				overflow="auto"
				component="main"
				sx={{ flexGrow: 1, mt: hasHeader ? '64px' : null }}
			>
				{children}
			</Box>
		</Box>
	);
}

export default OldPage;