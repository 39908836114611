import React from 'react';
import PropTypes from 'prop-types';
import { isEmptyChildren, isFunction } from '~/helpers/generalHelper';
import { useGate } from '~/hooks/useGate';

export const Gate = ({
	has = null,
	fallback,
	passNull = false, // treat null as a pass
	children
}) => {
	const { gate } = useGate();
	return (
		children && gate(passNull ? (has ?? true) : has)
			? isFunction(children)
				? children()
				: !isEmptyChildren(children)
					? children
					: null
			: fallback
	);
}

Gate.defaultProps = {
	has: false,
	visible: false,
	fallback: null,
};

Gate.propTypes = {
	has: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
	visible: PropTypes.bool.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
}
