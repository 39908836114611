import axios from 'axios';
import config from './config';
import { getWindow } from './helpers/windowHelper';

export const instance = axios.create();

export const controller = new AbortController();

/**
 * Handles API requests using Axios.
 *
 * @param {object} options - The options for the API request.
 * @param {string} options.method - The HTTP method for the request (GET, POST, PUT, DELETE, etc.).
 * @param {string} options.url - The URL for the API endpoint.
 * @param {object} [options.dataOrParams] - The data to be sent with POST or PUT requests, or query parameters for GET requests.
 * @param {AbortSignal|null} [options.signal=null] - The unique request ID. Axios-cancel uses this to cancel previous alive request.
 * @param {...any} rest - Any additional properties that Axios supports can be passed here.
 * @returns {Promise<any>} A Promise that resolves with the response data.
 *
 * @example
 * const response = await apiHandler({
 *   method: 'POST',
 *   url: 'https://api.example.com/data',
 *   dataOrParams: { key: 'value' },
 *   signal: controller.signal
 * });
 */
const apiHandler = ({
	method,
	url,
	dataOrParams = undefined,
	signal = null, /* The unique request id. Axios-cancel uses this to cancel previous alive request.*/
	...rest
}) => {

	const axiosConfig = {
		...((signal || controller) ? { signal: (signal || controller.signal) } : {}),
		baseURL: config.api.base_url,
		url,
		method,
		data: dataOrParams,
		params: method === 'get' ? dataOrParams : {},
		...rest
	};

	return instance({
		...axiosConfig,
		withCredentials: true,
		withXSRFToken: true
	});
};

instance.interceptors.response.use(null, (error) => {
	if (error.response) {
		console.error(error.response);

		switch (error.response.status) {
			case 401: // Unauthorized
				getWindow().auth.logout(false);
				break;

			case 403: // Forbidden
				break;

			default:
				break;
		}
	} else if (error.request) {
		console.error(error.request);
	}

	return Promise.reject(error);
});

export default apiHandler;