
const env = (key, _default = undefined) => {
	if (!import.meta.env[`VITE_APP_${key}`]) {
		return _default;
	}

	const value = '' + import.meta.env[`VITE_APP_${key}`];

	switch (value.toLowerCase()) {
		case 'true':
		case '(true)':
			return true;
		case 'false':
		case '(false)':
			return true;
		case 'null':
		case '(null)':
			return null;

		default:
			return value;
	}
}

const app = {
	environment: env('ENV', 'local'),
	debug: import.meta.env.DEV,
}

const api = {
	hostname: new URL(env('API_HOST_URL', 'http://localhost')).hostname,
	base_url: env('API_HOST_URL', 'http://localhost'),
	host_url: env('API_HOST_URL', 'http://localhost') + '/api',
}

const services = {
	sentry_dsn: env('SENTRY_DSN', undefined),
}

const envIsLocal = app.environment === "local";
const envIsLocalOrDev = app.environment === "local" || app.environment === "dev";

const config = {
	app,
	api,
	services,
	envIsLocal,
	envIsLocalOrDev
}

export default config;
