import apiHandler from "../apiHandler";


export const getActivitylog = (event, subject, subject_id, signal = undefined) => apiHandler({
	method: "get",
	url: "activity-logs/",
	dataOrParams: {
		event,
		subject_type: subject,
		subject_id,
	},
	signal
});
