import * as Yup from 'yup';
import { mobileNumberRegex, phoneNumberRegex } from '~/helpers/stringHelpers';

export const initialValues = (pages = []) => {
	const baseValues = {

		// JOB
		job: {
			customer: {
				first_name: '',
				last_name: '',
				company_name: '',
				mobile_number: '',
				phone_number: '',
				email: ''
			},
			insurance: {
				claim_number: '',
			},
			booking: {
				repair_date: null,
			},
			vehicle: {
				rego: '',
				rego_state: '',
				build_year: '',
				make: '',
				model: '',
				badge: '',
				body_style: '',
				transmission: '',
				vin: '',
				colour: '',
				paint_type: 'metallic',
				klms: '',
				size: '',
			},
		},

		// LABOUR
		labour: {
			labour_quote_method: 'count',
			prestige: false,
			panels: {},
			panel_notes: "",
		},

		// RR
		removerefit: {
			"r&r": [],
		},

		// SUBLETS
		sublet: {
			sublets: {
				front_screen: {
					new: false,
				},
				rear_screen: {
					new: false,
				},
				// '1/4_left': {
				// 	new: false,
				// },
				// '1/4_right': {
				// 	new: false,
				// },

				adas_calibration: false
			},
		},

		// MISC
		misc: {
			misc: [
				{
					key: "enviro_levy",
					label: "Enviro Levy",
				},
				{
					key: "cavity_wax",
					label: "Cavity Wax",
				},
				{
					key: "clips",
					label: "Clips as Required",
				},
			],
		},

		// TURRENT
		turret: {

			turret_misc: [
				{
					key: "panel_bond",
					label: "Panel Bond",
					adornment: "$",
				},
				{
					key: "anti_flutter",
					label: "Anti-Flutter",
					adornment: "$",
				},
				{
					key: "consumables_allowance",
					label: "Consumables Allowance",
					adornment: "%",
				},
				{
					key: "weld_through_primer",
					label: "Weld Through Primer",
					adornment: "$",
				}
			],
			turret: {
				size: null,
				trims: null,
				"r&i": null,
				paint: null,
			},
		},

		// PARTS
		part: {
			parts: [],
		},

		// NOTES
		note: {
			notes: "",
		},

		// PHOTOS
		photos: {
			photos: {},
		},

		paint: {
			paint: [],
		},

		// CHECKLIST
		checklist: {
			checklist: {
				required_badges: false,
				taken_pictures: false
			}
		}
	}

	// if pages empty array, loop through object and merge all the second level objects together 
	if (pages.length === 0) {
		return Object.values(baseValues).reduce((acc, page) => ({ ...acc, ...page }), {});
	}

	return pages.reduce((acc, page) => {
		if (page in baseValues) {
			acc = { ...acc, ...baseValues[page] };
		}
		return acc;
	}, {});
};

const buildYearValidation = Yup.string().nullable().test(
	'build-year',
	'Invalid Year or Month',
	function (value) {
		if (!value) {
			return true; // If value is null or undefined, let other validations handle it
		}

		const yearRegex = /^\d{4}$/; // YYYY format
		const monthYearRegex = /^(0[1-9]|1[0-2])\/\d{4}$/; // MM/YYYY format

		if (yearRegex.test(value)) {
			const year = parseInt(value, 10);
			return year >= 1950 && year <= new Date().getFullYear() + 2;
		}

		if (monthYearRegex.test(value)) {
			const [monthPart, yearPart] = value.split('/');
			const monthNum = parseInt(monthPart, 10);
			const year = parseInt(yearPart, 10);

			const isValidMonth = monthNum >= 1 && monthNum <= 12;
			const isValidYear = year >= 1950 && year <= new Date().getFullYear() + 2;

			return isValidMonth && isValidYear;
		}

		return false;
	}
);


export const triageSchema = (submission) =>
	submission.is_completed ?
		Yup.object().shape({}) :
		Yup.object().shape({
			// job
			customer: Yup.object().shape({
				first_name: Yup.string().nullable(),
				last_name: Yup.string().nullable(),
				company_name: Yup.string().nullable(),
				mobile_number: Yup.string().matches(mobileNumberRegex, "Invalid Mobile Number").nullable(),
				phone_number: Yup.string().matches(phoneNumberRegex, "Invalid Phone Number").nullable(),
				email: Yup.string().email("Invalid email").nullable(),
			}),
			insurance: Yup.object().shape({
				insurer: Yup.string().nullable(),
				claim_number: Yup.string().nullable()
			}),
			booking: Yup.object().shape({
				repair_date: Yup.date().nullable()
			}),
			vehicle: Yup.object().shape({
				rego: Yup.string().max(9, "Invalid Rego").nullable(),
				rego_state: Yup.string().nullable(),
				body_style: Yup.string().required("Body Style is required"),
				size: Yup.string().required("Size is required"),
				build_year: buildYearValidation,
				make: Yup.string().nullable(),
				model: Yup.string().nullable(),
				badge: Yup.string().nullable(),
				vin: Yup.string().nullable().length(17, "VIN must be 17 characters"),
				colour: Yup.string().nullable(),
				transmission: Yup.string().required("Transmission is required"),
				klms: Yup.number().integer("Invalid Kilometers").min(0, "Invalid Kilometers").required("Kilometers is required"),
				paint_type: Yup.string().required("Paint Type is required"),
			}),

			panels: Yup.object(),

			"r&r": Yup.array().of(Yup.object().shape({
				qty: Yup.number().required("Qty is required").min(1, "Qty must be greater than 0"),
				// enforce price where custom == true
				price: Yup.number().when('custom', {
					is: true,
					then: () => Yup.number().required("Price is required").min(0, "Price must be greater than 0"),
					otherwise: () => Yup.number().nullable(),
				}),
				// price: Yup.number().required("Price is required").min(0, "Price must be greater than 0"),
			})),

			turret: Yup.object().when(['panels'], {
				is: (panels, schema) => {
					return panels && Object.values(panels).some((panel) => panel._panel.key.startsWith("turret") && panel.replace_panel === true);
				},
				then: () => Yup.object().shape({
					size: Yup.string().required("Size is required when a turret is marked for replacement"),
				}),
				otherwise: () => Yup.object().shape({
					size: Yup.string().nullable(),
				}),
			}),

			misc: Yup.array().of(Yup.object().shape({
				price: Yup.number().required("Price is required").min(0, "Price must be greater than 0"),
			})),

			notes: Yup.string().nullable(),
			photos: Yup.object().nullable(),
			// checklist: Yup.object().test('checklist', 'All items must be checked',
			// 	value => Object.values(value).every(item => item)
			// )
		}).test({
			name: 'regoOrClaim',
			test: function (values) {
				if (!submission && !values.insurance.claim_number && !values.vehicle.rego) {
					return this.createError({
						path: 'vehicle.rego',
						message: 'A rego is required when a claim number is not provided',
					});
				}
				return true;
			},
		});