import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Box, Checkbox, Chip, FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { memo, useEffect, useMemo } from 'react';
import { isArray } from '~/helpers/objectHelper';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { getWorkproviders } from '~/requests/workproviders';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const WorkProviderDropdown = memo(({
	value,
	initalValue,
	fullWidth = false,
	formikMeta,
	onChange,
	name,
	showNone = true,
	exclude = [],
	returnWorkprovider = false,
	label = 'Work Provider',
	...props
}) => {
	const { data: workproviders = [], isLoading: isWorkprovidersLoading } = useQuery({
		queryKey: ['workproviders'],
		queryFn: queryFunctionHelper(getWorkproviders)
	});

	const menuOptions = useMemo(() => {
		return [
			...(showNone ? [{ id: null, name: 'None' }] : []),
			...workproviders
		].filter(wp => !exclude.includes(wp.id));
	}, [exclude, showNone, workproviders]);

	useEffect(() => {
		if (initalValue && !value) {
			if (returnWorkprovider) {
				onChange(workproviders.find(wp => wp.id === initalValue));

			} else {
				onChange(name, initalValue);
			}
		}
	}, [initalValue, name, onChange, returnWorkprovider, value, workproviders]);

	return (
		<FormControl fullWidth={fullWidth} error={formikMeta?.touched && formikMeta?.error ? true : false} {...props}>
			<InputLabel id="workprovider-label">{label}</InputLabel>
			<Select
				labelId="workprovider-label"
				label={label}
				value={value ?? initalValue ?? null}
				disabled={isWorkprovidersLoading || menuOptions.length === 0}
				onChange={e => {
					if (returnWorkprovider) {
						onChange(workproviders.find(wp => wp.id === e.target.value));

					} else {
						onChange(name, e.target.value);
					}
				}}
				renderValue={props.multiple ?
					(selected) => (
						<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
							{selected.map((id) => {
								const chipValue = workproviders.find(wp => wp.id === id)?.name || "";
								return chipValue ? <Chip key={id} label={chipValue} /> : null;
							})}
						</Box>
					) : undefined}
				{...props}
			>
				{menuOptions.map(item => (
					<MenuItem key={item.id} value={item.id}>
						{props.multiple && (
							<Checkbox icon={icon} checkedIcon={checkedIcon} checked={(isArray(value) || isArray(initalValue)) ? (initalValue ? initalValue.indexOf(item.id) > -1 : value.indexOf(item.id) > -1) : false} />
						)}
						{item.name}
					</MenuItem>
				))}
			</Select>
			{formikMeta?.touched && formikMeta?.error && (
				<FormHelperText>{formikMeta.error}</FormHelperText>
			)}
		</FormControl>
	)
});
WorkProviderDropdown.displayName = 'WorkProviderDropdown';

export default WorkProviderDropdown