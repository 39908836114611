import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { mergeValuesWithInital } from "~/helpers/dataHelpers";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { toDecimal } from "~/helpers/stringHelpers";
import { initialValues as baseValues } from "../form/estimate/formikMeta";
import { getMiscPrices, getPanelPrices } from "../requests/submissions";

// context setup
const FormPricesContext = React.createContext({
	getMiscPrice: (category, key) => { },
	getPanelPrice: (key) => { },
});
export const __FormPricesContext = FormPricesContext;

export const FormPricesProvider = ({
	submission = null,
	loadedValues,
	pages = [],
	children
}) => {

	const { data: miscPrices, refetch: refetchMiscPrices, isFetched: miscPricesFetched } = useQuery({
		queryKey: ['form', 'estimate', 'submission', submission?.uuid, 'miscPrices', submission?.workprovider_id],
		queryFn: queryFunctionHelper(getMiscPrices, 'estimate', submission?.uuid),
		staleTime: 1000 * 60 * 5,
		enabled: !!submission?.workprovider_id
	});

	const { data: panelPrices, refetch: refetchPanelPrices, isFetched: panelPricesFetched } = useQuery({
		queryKey: ['form', 'estimate', 'submission', submission?.uuid, 'panelPrices', submission?.workprovider_id, submission?.summary.paint_type],
		queryFn: queryFunctionHelper(getPanelPrices, 'estimate', submission?.uuid),
		staleTime: 1000 * 60 * 5,
		enabled: !!(submission?.workprovider_id && submission?.summary.paint_type)
	});


	const getMiscPrice = useCallback((category, key, round = true) => {
		if (!miscPrices) return "";

		const _category = miscPrices[category] || [];
		const price = _category.find(item => item.key === key)?.price;

		return price ? toDecimal(price, round ? 0 : 1) : null;
	}, [miscPrices]);

	const getPanelPrice = useCallback((key) => {
		if (!panelPrices) return "";

		return panelPrices[key] || {};
	}, [panelPrices]);

	const initialValues = useMemo(() => {
		// handle any defaults and attach on the pricing with getMiscPrice... 

		let misc = null;
		if (pages.includes('misc') || pages.length === 0) {
			misc = baseValues(['misc']).misc.map(item => {
				const price = getMiscPrice('misc', item.key);
				return { ...item, price };
			});
		}

		let turret_misc = null;
		if (pages.includes('turret') || pages.length === 0) {

			turret_misc = baseValues(['turret']).turret_misc.map(item => {
				const price = getMiscPrice('turret_misc', item.key);
				return { ...item, price };
			});
		}

		const returnData = mergeValuesWithInital(loadedValues, { ...baseValues(pages), ...({ misc }), ...({ turret_misc }) });
		return returnData;
	}, [getMiscPrice, loadedValues, pages]);

	return (
		<FormPricesContext.Provider
			value={{ getMiscPrice, getPanelPrice }}
		>
			{(!!submission?.workprovider_id && !miscPricesFetched) ? null : children({ initialValues })}
		</FormPricesContext.Provider>
	);
};
