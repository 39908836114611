import apiHandler from "../apiHandler";

export const searchEvents = (term, signal = undefined) => apiHandler({
	method: "get",
	url: "events/search",
	dataOrParams: {
		q: term
	},
	signal
});

export const getEvents = (params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "events",
	dataOrParams: params,
	signal
});

export const getEvent = (uuid, signal = undefined) => apiHandler({
	method: "get",
	url: "events/" + uuid,
	signal
});

export const createEvent = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `events`,
	dataOrParams: params,
	signal
});

export const updateEvent = (id, params = {}, signal = null) => apiHandler({
	method: "put",
	url: `events/${id}`,
	dataOrParams: params,
	signal
});

export const deleteEvent = (id, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `events/${id}`,
	dataOrParams: params,
	signal
});

export const restoreEvent = (id, params = {}, signal = null) => apiHandler({
	method: "post",
	url: `events/${id}/restore`,
	dataOrParams: params,
	signal
});