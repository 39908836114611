import { Box, Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { getRoles } from '~/requests/users';

const UserRolesDropdown = ({ value, onChange }) => {
	const { data: roles = [], isLoading } = useQuery({
		queryKey: ['roles'],
		queryFn: queryFunctionHelper(getRoles)
	});

	const handleChange = (values) => {
		const selectedRoles = values.map(name => roles.find(role => role.name === name));
		onChange('roles', selectedRoles);
	};

	return (
		<FormControl fullWidth>
			<InputLabel id="roles-label">Roles</InputLabel>
			<Select
				labelId="roles-label"
				multiple
				label="Roles"
				value={value ? value.map(item => item.name) : []}
				disabled={isLoading}
				renderValue={(selected) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
						{selected.map((item) => {
							const chipValue = roles.find(role => role.name === item)?.display_name || "";
							return chipValue ? <Chip key={item} label={chipValue} /> : null;
						})}
					</Box>
				)}
				onChange={event => handleChange(event.target.value)}
			>
				{roles.filter(role => role.name !== 'system.super_admin').map(item => (
					<MenuItem key={item.name} value={item.name}>
						{item.display_name}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}

export default UserRolesDropdown