import { Grid } from "@mui/material";
import { Field } from "formik";
import { TextField } from "formik-mui";
import React from "react";
import FormInput from "~/components/Forms/FormInput";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";


const PageNotes = () => {

	return (
		<EstimateFormPage>
			<PageTitle>Notes</PageTitle>

			<Grid container paddingTop={1}>
				<Grid item xs={12}>
					<Field
						component={FormInput}
						inputComponent={TextField}
						name="notes"
						fullWidth
						InputProps={{
							multiline: true,
							minRows: 20,
							maxRows: 30
						}}
					/>
				</Grid>
			</Grid>
		</EstimateFormPage>
	);
};

export default React.memo(PageNotes);
