import { forwardRef } from 'react';
import { Suspense } from 'react';

// project imports
import Loader from '../components/Loader';

const loadable = (Component) => {
	const LoadableComponent = forwardRef((props, ref) => (
		<Suspense fallback={<Loader />}>
			<Component {...props} ref={ref} />
		</Suspense>
	));

	LoadableComponent.displayName = `Loadable(${Component.displayName || Component.name || 'Component'})`;

	return LoadableComponent;
};

export default loadable;
