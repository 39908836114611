import { Alert, Button, Card, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import GlobalSearch from '~/components/GlobalSearch';
import { newFormSubmission, searchSubmissions } from '~/requests/submissions';
import DialogCloseButton from '../DialogCloseButton';
import EventChip from '../EventChip';
import FacilityChip from '../FacilityChip';
import SubmissionSummaryTile from '../SubmissionSummaryTile';

const StartAuditDialog = ({ isOpen, onClose }) => {

	const navigate = useNavigate();
	const theme = useTheme();

	const [selectedEstimate, setSelectedEstimate] = useState(null);

	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const isCompleted = useMemo(() => selectedEstimate?.status === 'completed', [selectedEstimate]);

	const handleClose = useCallback(() => {
		setSelectedEstimate(null);
		onClose();
	}, [onClose]);

	const searchModels = useMemo(() => [
		{
			key: 'submissions',
			label: 'Estimates',
			endpoint: searchSubmissions,
			onClick: (item) => {
				setSelectedEstimate(item);
			},
			render: (item, key, onClick) => <SubmissionSummaryTile key={key} submission={item} onClick={onClick} showAuthor />,
			params: {
				form: 'estimate'
			}
		}

	], [setSelectedEstimate]);


	const handleStartAudit = useCallback(() => {
		newFormSubmission('estimate_audit', {
			parent_uuid: selectedEstimate.uuid
		})
			.then(({ data: { data: submission } }) => {
				navigate(`/forms/estimate_audit/${submission.uuid}`);
			})
			.catch((error) => {
				console.error(error);
				enqueueSnackbar("Error starting audit. Please try again", { variant: 'error' });
			})
			.finally(() => {
				handleClose();
			});
	}, [navigate, handleClose, selectedEstimate]);

	return (
		<Dialog
			open={isOpen}
			onClose={handleClose}
			fullWidth
			fullScreen={isMobile}
			className='dialog-align-top'
			maxWidth='md'
		>
			<DialogTitle>
				Find Estimate
				<DialogCloseButton onClick={handleClose} />
			</DialogTitle>
			<DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
				{selectedEstimate && (
					<>
						<Typography variant="subtitle1" gutterBottom> Please confirm the details below before proceeding </Typography>
						<Card variant="outlined" sx={{ marginTop: 2 }}>
							<CardContent>
								<Stack gap={0.5}>
									{!isCompleted && (
										<Alert severity="error">
											The estimate is not completed yet. Please make sure the estimate is completed before proceeding.
										</Alert>
									)}
									<Typography>
										Claim No: {selectedEstimate.summary.claim_number}
									</Typography>
									<Typography>
										Rego: {selectedEstimate.summary.rego}
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Facility: <FacilityChip facility={selectedEstimate.assessment?.facility} />
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Event: <EventChip event={selectedEstimate.assessment?.facility?.event} />
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Customer: {selectedEstimate.summary.name}
									</Typography>
									<Typography variant="body2" color="text.secondary">
										Vehicle: {selectedEstimate.summary.vehicle}
									</Typography>
								</Stack>
							</CardContent>
							<CardActions>
								<Button size="small" onClick={() => setSelectedEstimate(null)}>Clear</Button>
							</CardActions>
						</Card>
					</>
				)}

				{!selectedEstimate && (
					<GlobalSearch models={searchModels} />
				)}

				<DialogActions sx={{ p: 0, mt: 1 }}>
					<Button variant='outlined' color='secondary' onClick={handleClose} sx={{ mr: 'auto' }}>
						Cancel
					</Button>
					<Button variant='contained' onClick={() => handleStartAudit(selectedEstimate)} autoFocus disabled={!selectedEstimate || !isCompleted}>
						Confirm
					</Button>
				</DialogActions>
			</DialogContent>
		</Dialog>
	)
}

export default StartAuditDialog