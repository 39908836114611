import React, { createContext, useContext, useState } from 'react';

const LayoutProviderContext = createContext();

export const useLayoutProvider = () => {
  return useContext(LayoutProviderContext);
};

export const LayoutProvider = ({ children }) => {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(true);
  const [sidemenuOpen, setSidemenuOpen] = useState(true);

  const toggleSideDrawer = () => {
    setSideDrawerOpen((prevState) => !prevState);
  };

  const toggleSidemenu = () => {
    setSidemenuOpen((prevState) => !prevState);
  };

  const closeSidemenu = () => {
    setSidemenuOpen((prevState) => !prevState);
  };

  return (
    <LayoutProviderContext.Provider value={{ sideDrawerOpen, toggleSideDrawer, sidemenuOpen, toggleSidemenu, closeSidemenu }}>
      {children}
    </LayoutProviderContext.Provider>
  );
};