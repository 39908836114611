import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Card, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from '@tanstack/react-query';
import { bindDialog } from 'material-ui-popup-state';
import { useCallback, useContext } from 'react';
import { useNavigate } from 'react-router';
import SubmissionSummaryTile from '~/components/SubmissionSummaryTile';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { useAssessmentEditDialog } from '~/hooks/dialogs/useAssessmentEditDialog';
import { getSubmissions, newFormSubmission } from "~/requests/submissions";
import { __AssessmentDialogContext } from '../../providers/dialogs/AssessmentDialogProvider';
import DialogCloseButton from '../DialogCloseButton';

const AssessmentDialog = () => {
	const { popupState, assessment } = useContext(__AssessmentDialogContext);
	const { open: openEditAssessment } = useAssessmentEditDialog();

	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { data: forms = [], isLoading } = useQuery({
		queryKey: ['assessments', assessment?.uuid, 'forms'],
		queryFn: queryFunctionHelper(getSubmissions, {
			form: 'estimate',
			assessment_uuid: assessment?.uuid,
		}),
		refetchOnWindowFocus: false,
		enabled: !!assessment,
	});

	const handleStartEstimate = useCallback((selectedAssessment = null) => {
		newFormSubmission('estimate', {
			...(selectedAssessment ? { assessment_uuid: selectedAssessment.uuid } : {})
		})
			.then(({ data: { data: submission } }) => {
				navigate(`forms/estimate/${submission.uuid}`);
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				popupState.close();
			});
	}, [navigate, popupState]);

	if (!assessment) return null;

	return (
		<>
			<Dialog
				{...bindDialog(popupState)}
				scroll='paper'
				fullScreen={isMobile}
				maxWidth='md'
			>
				<DialogTitle>
					Assessment: {assessment.vehicle.rego} | {assessment.claim_number}
					<DialogCloseButton onClick={popupState.close} />
				</DialogTitle>
				<DialogContent dividers>
					{/* Push modal out */}
					<Box sx={{
						minWidth: '60vh',
					}} />

					<Grid container spacing={0} position='relative'>
						<Grid item xs={2} fontWeight='bold'>Claim No:</Grid>
						<Grid item xs={10} fontWeight='bold'>{assessment.claim_number || '-'}</Grid>
						<Grid item xs={2} fontWeight='bold'>Rego:</Grid>
						<Grid item xs={10} fontWeight='bold'>{assessment.vehicle.rego || '-'}</Grid>

						<Grid item xs={2}>Facility:</Grid>
						<Grid item xs={10}>{assessment.facility?.name}</Grid>
						<Grid item xs={2}>Event:</Grid>
						<Grid item xs={10}>{assessment.facility?.event?.name}</Grid>
						<Grid item xs={2}>Customer:</Grid>
						<Grid item xs={10}>{[assessment.customer?.full_name, assessment.customer?.company_name].join(' ')}</Grid>
						<Grid item xs={2}>Vehicle:</Grid>
						<Grid item xs={10}>{[assessment.vehicle?.build_year, assessment.vehicle?.make, assessment.vehicle?.model, assessment.vehicle?.badge, assessment.vehicle?.colour].join(' ')}</Grid>
						<Grid item xs={2}>Imported:</Grid>
						<Grid item xs={10}>{assessment.imported ? 'Yes' : 'No'}</Grid>

						<IconButton aria-label="edit" sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => openEditAssessment(assessment.uuid)}>
							<EditIcon />
						</IconButton>
					</Grid>

					{forms.length > 0 && (
						<Card sx={{ mt: 2, p: 2, backgroundColor: 'grey.100' }}>
							<Typography variant='h4' sx={{ mb: 2 }}>Existing Forms</Typography>
							<Stack spacing={1} sx={{ mt: 1 }}>
								{forms.map((item) => (
									<SubmissionSummaryTile submission={item} key={item.uuid} onClick={() => navigate(`/forms/estimate/${item.uuid}`)} />
								))}

								{(!forms && !isLoading) && (
									<Typography>None</Typography>
								)}

								{isLoading && (
									<>
										<Skeleton height={60} />
										<Skeleton height={60} />
									</>
								)}
							</Stack>
						</Card>
					)}

					<Stack direction="row" spacing={2} sx={{ marginTop: 3 }}>
						<Button
							variant="contained"
							size='large'
							fullWidth
							startIcon={<DynamicFormIcon />}
							onClick={() => handleStartEstimate(assessment)}
						>
							Start New Estimate
						</Button>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button
						autoFocus
						onClick={popupState.close}
						variant='outlined'
						color='secondary'
					>
						Close
					</Button>

				</DialogActions>
			</Dialog>
		</>
	);
}

export default AssessmentDialog;