import { Field } from "formik";
import { TextField } from "formik-mui";
import React, { useCallback, useMemo } from "react";
import FormInput from "~/components/Forms/FormInput";
import CustomList from "../../components/CustomList";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import { getRandomString } from "../../helpers";
import { mergeValuesWithInital } from "../../helpers/dataHelpers";
import { useForm } from "../../hooks/useForm";
import { baseParts } from "./data/data";

const PageParts = () => {

	const { formik, getMiscPrice, estimateMeta, isCompleted } = useForm();

	const addItem = useCallback((arrayHelpers, list) => {
		arrayHelpers.push({
			key: getRandomString(8),
			label: "New Item",
		});
	}, []);

	const parts = useMemo(() => {
		return mergeValuesWithInital(formik.values["parts"], baseParts(estimateMeta.bodyStyle)).map(item => ({
			...item,
			price: item.price ?? getMiscPrice('misc', item.key)
		}));

	}, [formik.values, getMiscPrice, estimateMeta])

	return (
		<React.Fragment>
			<EstimateFormPage>
				<PageTitle>Parts</PageTitle>

				<Field
					component={FormInput}
					inputComponent={TextField}
					name="badges"
					label="Badges"
					fullWidth
					helperText="Please take photos of the required badges and upload them to the quote"
				/>

				<CustomList.Main
					name="parts"
					headers={[["Item"]]}
					baseItems={baseParts(estimateMeta.bodyStyle)}
					values={parts}
					allowAdd={addItem}
					readOnly={isCompleted}
				>
					<CustomList.List>
						{(list) => {
							return list.map((item) => {
								if (item._group) {
									return (
										<CustomList.GroupHeading key={item.name}>{item.name}</CustomList.GroupHeading>
									)
								} else {
									return (

										<CustomList.ListItem key={item.key} value={item}>
											{({ index, isVirtual }) => (
												<>
													<CustomList.ListColumn>
														<CustomList.Checkbox
															label={isVirtual ? '' : item.label}
															value={item}
														/>
														{isVirtual && (
															<Field
																component={TextField}
																fullWidth
																name={`parts.[${index}].label`}
															/>
														)}
													</CustomList.ListColumn>
												</>
											)}
										</CustomList.ListItem>
									)
								}
							})
						}}
					</CustomList.List>
				</CustomList.Main>
			</EstimateFormPage>
		</React.Fragment>
	);
};

export default PageParts;
