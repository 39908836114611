import React, { useCallback } from "react";
import { usePopupState } from "material-ui-popup-state/hooks";
import StartEstimateDialog from "~/components/Dialogs/StartEstimateDialog";

// context setup
const StartEstimateDialogContext = React.createContext({
	popupState: {},
	open: () => { },
});
export const __StartEstimateDialogContext = StartEstimateDialogContext;

export const StartEstimateDialogProvider = ({ children }) => {

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'StartEstimateDialog',
	});

	const open = useCallback(() => {
		popupState.open();
	}, [popupState]);

	return (
		<StartEstimateDialogContext.Provider
			value={{ popupState, open }}
		>
			<>

				<StartEstimateDialog
					isOpen={popupState.isOpen}
					onClose={popupState.close}
				/>
				{children}
			</>
		</StartEstimateDialogContext.Provider>
	);
};
