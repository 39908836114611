import { QueryClient, queryOptions } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { getFacilities, getFacility } from "~/requests/facilities";

/**
 * Creates a query for fetching facility details based on the provided parameters.
 *
 * @param {object} [params={}] - The optional parameters for fetching facility details.
 * @returns {object} The query options for fetching facility details.
 *
 * @example
 * // Fetching all facilities
 * const allfacilitiesQueryOptions = facilitiesQuery();
 * const { data } = useQuery(allfacilitiesQueryOptions);
 *
 * // Fetching facilities with specific parameters
 * const filteredfacilitiesQueryOptions = facilitiesQuery({ param1: value1, param2: value2 });
 * const { data } = useQuery(filteredfacilitiesQueryOptions);
 */
export const facilitiesQuery = (params = {}) => queryOptions({
	queryKey: ['facilities', params],
	queryFn: queryFunctionHelper(getFacilities, params)
});

/**
 * Creates a query for fetching facility details based on the facility ID.
 *
 * @param {string} id - The ID of the facility to fetch details for.
 * @returns {object} The query options for fetching facility details.
 *
 * @example
 * const facilityQueryOptions = facilityQuery("facilityID");
 * const { data } = useQuery(facilityQueryOptions);
 */
export const facilityQuery = (id) => queryOptions({
	queryKey: ['facilities', id],
	queryFn: queryFunctionHelper(getFacility, id),
	enabled: id !== null
});


/**
* Creates a loader function for ensuring facility data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {function} The loader function that ensures facility data is available.
*
* @throws {Error} Throws an error if no facility ID is provided.
*
* @example
* const { id } = useLoaderData();
*/
export const facilityLoader = (queryClient) => async ({ params }) => {
	if (!params.id) {
		throw new Error('No facility ID provided')
	}
	await queryClient.ensureQueryData(facilityQuery(params.id))
	return { id: params.id }
};


/**
* Creates a loader function for ensuring facility data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {Object} Empty object
*
* @example
* const { } = useLoaderData();
*/
export const facilitiesLoader = (queryClient) => async () => {
	await queryClient.ensureQueryData(facilitiesQuery())
	return {}
};