export function createErrorHandler(
	fieldError,
	fieldName,
	setFieldError
) {
	return (error) => {
		if (error !== fieldError && error !== '') {
			setFieldError?.(fieldName, error ? String(error) : undefined);
		}
	};
}
