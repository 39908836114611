import { Chip } from "@mui/material";
import { memo } from "react";

const FacilityChip = memo(({
	facility,
	...props
}) => {

	if (!facility) {
		return null;
	}

	return (
		<Chip label={facility.name} {...props} size="small" />
	);
});

FacilityChip.displayName = 'FacilityChip';

export default FacilityChip;