import { QueryClient, queryOptions } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { getUser, getUsers } from "~/requests/users";

/**
 * Creates a query for fetching user details based on the provided parameters.
 *
 * @param {object} [params={}] - The optional parameters for fetching user details.
 * @returns {object} The query options for fetching user details.
 *
 * @example
 * // Fetching all users
 * const allUsersQueryOptions = usersQuery();
 * const { data } = useQuery(allUsersQueryOptions);
 *
 * // Fetching users with specific parameters
 * const filteredUsersQueryOptions = usersQuery({ param1: value1, param2: value2 });
 * const { data } = useQuery(filteredUsersQueryOptions);
 */
export const usersQuery = (params = {}) => queryOptions({
	queryKey: ['users', params],
	queryFn: queryFunctionHelper(getUsers, params)
});

/**
 * Creates a query for fetching user details based on the user ID.
 *
 * @param {string} id - The ID of the user to fetch details for.
 * @returns {object} The query options for fetching user details.
 *
 * @example
 * const userQueryOptions = userQuery("userID");
 * const { data } = useQuery(userQueryOptions);
 */
export const userQuery = (id) => queryOptions({
	queryKey: ['users', id],
	queryFn: queryFunctionHelper(getUser, id),
	enabled: id !== null
});


/**
* Creates a loader function for ensuring user data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {function} The loader function that ensures user data is available.
*
* @throws {Error} Throws an error if no user ID is provided.
*
* @example
* const { id } = useLoaderData();
*/
export const userLoader = (queryClient) => async ({ params }) => {
	if (!params.id) {
		throw new Error('No user ID provided')
	}
	await queryClient.ensureQueryData(userQuery(params.id))
	return { id: params.id }
};


/**
* Creates a loader function for ensuring user data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {Object} Empty object
*
* @example
* const { } = useLoaderData();
*/
export const usersLoader = (queryClient) => async () => {
	await queryClient.ensureQueryData(usersQuery())
	return {}
};