import { QueryClient, queryOptions } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { getDepartment, getDepartments } from "~/requests/departments";

/**
 * Creates a query for fetching department details based on the provided parameters.
 *
 * @param {object} [params={}] - The optional parameters for fetching department details.
 * @returns {object} The query options for fetching department details.
 *
 * @example
 * // Fetching all departments
 * const alldepartmentsQueryOptions = departmentsQuery();
 * const { data } = useQuery(alldepartmentsQueryOptions);
 *
 * // Fetching departments with specific parameters
 * const filtereddepartmentsQueryOptions = departmentsQuery({ param1: value1, param2: value2 });
 * const { data } = useQuery(filtereddepartmentsQueryOptions);
 */
export const departmentsQuery = (params = {}) => queryOptions({
	queryKey: ['departments', params],
	queryFn: queryFunctionHelper(getDepartments, params)
});

/**
 * Creates a query for fetching department details based on the department ID.
 *
 * @param {string} id - The ID of the department to fetch details for.
 * @returns {object} The query options for fetching department details.
 *
 * @example
 * const departmentQueryOptions = departmentQuery("departmentID");
 * const { data } = useQuery(departmentQueryOptions);
 */
export const departmentQuery = (id) => queryOptions({
	queryKey: ['departments', id],
	queryFn: queryFunctionHelper(getDepartment, id),
	enabled: id !== null
});


/**
* Creates a loader function for ensuring department data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {function} The loader function that ensures department data is available.
*
* @throws {Error} Throws an error if no department ID is provided.
*
* @example
* const { id } = useLoaderData();
*/
export const departmentLoader = (queryClient) => async ({ params }) => {
	if (!params.id) {
		throw new Error('No department ID provided')
	}
	await queryClient.ensureQueryData(departmentQuery(params.id))
	return { id: params.id }
};


/**
* Creates a loader function for ensuring department data is available in the query client.
*
* @param {QueryClient} queryClient - The query client instance.
* @returns {Object} Empty object
*
* @example
* const { } = useLoaderData();
*/
export const departmentsLoader = (queryClient) => async () => {
	await queryClient.ensureQueryData(departmentsQuery())
	return {}
};