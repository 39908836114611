import { Form, Formik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Navigate, Route, Routes, useParams } from "react-router-dom";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Typography } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { DateTime } from "luxon";
import { enqueueSnackbar } from 'notistack';
import { useIsMounted } from "usehooks-ts";
import AssessmentEditDialog from '~/components/Dialogs/AssessmentEditDialog';
import FormChildrenTabs from "~/components/FormChildrenTabs";
import FormContainer from "~/components/Forms/FormContainer";
import { Gate } from "~/components/Gate";
import GlobalDialogsProvider from "~/components/GlobalDialogs";
import OldPage from "~/components/OldPage";
import config from "~/config";
import PageChecklist from "~/form/estimate/PageChecklist";
import PageExport from "~/form/estimate/PageExport";
import PageJobDetails from "~/form/estimate/PageJobDetails";
import PageLabour from "~/form/estimate/PageLabour";
import PageMisc from "~/form/estimate/PageMisc";
import PageNotes from "~/form/estimate/PageNotes";
import PageParts from "~/form/estimate/PageParts";
import PagePhotos from "~/form/estimate/PagePhotos";
import PageRemoveRefit from "~/form/estimate/PageRemoveRefit";
import PageReview from "~/form/estimate/PageReview";
import PageSublets from "~/form/estimate/PageSublets";
import PageTurret from "~/form/estimate/PageTurret";
import { triageSchema } from "~/form/estimate/formikMeta";
import { delay } from "~/helpers";
import { containsRequired } from "~/helpers/dataHelpers";
import { useGate } from "~/hooks/useGate";
import { submissionQuery, submissionValuesQuery } from "~/loaders/estimateFormLoader";
import { FormPricesProvider } from "~/providers/FormPricesProvider";
import { FormProvider } from "~/providers/FormProvider";
import { submitForm } from "~/requests/submissions";

const subMenuItems = {
	job: {
		key: "job",
		label: "JOB",
		title: "Job Details",
		url: "job",
		page: <PageJobDetails />
	},
	labour: {
		key: "labour",
		label: "LAB",
		title: "Labour",
		url: "labour",
		page: <PageLabour />
	},
	rr: {
		key: "rr",
		label: "R&R",
		title: "Remove & Refit",
		url: "rr",
		page: <PageRemoveRefit />
	},
	sublets: {
		key: "sublets",
		label: "SUB",
		title: "Sublets",
		url: "sublets",
		page: <PageSublets />
	},
	misc: {
		key: "misc",
		label: "MISC",
		title: "Miscellaneous",
		url: "misc",
		page: <PageMisc />
	},
	turret: {
		key: "turret",
		label: "TUR",
		title: "Turret",
		url: "turret",
		page: <PageTurret />
	},
	parts: {
		key: "parts",
		label: "PART",
		title: "Parts",
		url: "parts",
		page: <PageParts />
	},
	notes: {
		key: "notes",
		label: "NOTE",
		title: "Notes",
		url: "notes",
		page: <PageNotes />
	},
	photos: {
		key: "photos",
		label: "PHOTOS",
		title: "Photos",
		url: "photos",
		page: <PagePhotos />
	},
	checklist: {
		key: "checklist",
		label: "CHK",
		title: "Checklist",
		url: "checklist",
		page: <PageChecklist />
	},
	review: {
		key: "review",
		label: "REV",
		title: "Review",
		url: "review",
		page: <PageReview />
	}
};

const additionalSubMenuItems = {
	export: {
		key: "export",
		label: "EXP",
		title: "Export",
		url: "export",
		page: <PageExport />,
		allowed: (submission, gate) => submission?.is_completed && gate('tenant.apps.estimates.do_export')
	}
};

const staticRoutes = Object.values(subMenuItems).map((item) => (
	<Route
		key={item.key}
		path={item.url}
		element={item.page}
	/>
));


const FormEstimate = React.memo(() => {
	const { submissionId } = useParams();
	const { data: submission = null, isFetched: submissionFetched } = useQuery(submissionQuery(submissionId));
	const { data: loadedValues = {}, isFetched: valuesFetched } = useQuery(submissionValuesQuery(submissionId));

	const isFetched = useMemo(() => submissionFetched && valuesFetched, [submissionFetched, valuesFetched]);
	const isMounted = useIsMounted();
	const [open, setOpen] = useState(false);

	const { gate } = useGate();
	const queryClient = useQueryClient();
	const key = submissionQuery(submissionId).queryKey;

	useEffect(() => {
		delay(1000).then(() => {
			if (isMounted() && isFetched) {
				setOpen(true);
			}
		})
	}, [isMounted, isFetched]);

	const handleClose = useCallback(() => {
		setOpen(false);
	}, [setOpen]);

	const handleSubmit = useCallback((values, { setSubmitting }) => {
		setSubmitting(true);
		const _values = { ...values };

		const status = _values._status;
		delete _values._status;

		console.log(values);

		submitForm('estimate', submissionId, {
			data: _values,
			status
		})
			.then(() => {
				enqueueSnackbar('Estimate Saved');
				if (submission.parent) {
					queryClient.invalidateQueries({
						queryKey: submissionQuery(submission.parent.uuid).queryKey,
						type: 'all'
					});
				}
			})
			.catch((err) => {
				console.error(err);
				enqueueSnackbar(err.message, { variant: 'error' });
			})
			.finally(() => {
				queryClient.refetchQueries({
					queryKey: key,
					type: 'all'
				});
				setSubmitting(false);
			});

	}, [submissionId, queryClient, submission, key]);


	const menuItemsRules = useCallback((key, formik, submission) => {
		// optionally control turret
		if (key === 'turret') {
			return Object.keys(formik.values?.panels ?? {})
				.filter((panel_name) => panel_name.includes('turret_'))
				.some((panel_name) => {
					if (formik.values.panels[panel_name]) {
						return !!formik.values.panels[panel_name].replace_panel;
					}
					return false;
				});
		}

		// disable other menu items if JOB page not valid 
		if (key !== 'job') {
			if (!submission.workprovider_id || containsRequired(formik.errors.customer, "required") || containsRequired(formik.errors.insurance, "required") || containsRequired(formik.errors.booking, "required") || containsRequired(formik.errors.vehicle, "required")) {
				return null;
			}
		}
	}, []);

	const menuItemsErrors = useCallback((key, formik) => {
		switch (key) {
			case 'job':
				return formik.errors.customer || formik.errors.insurance || formik.errors.booking || formik.errors.vehicle;
			case 'labour':
				return formik.errors.labour_quote_method || formik.errors.prestige || formik.errors.panels || formik.errors.panel_notes;
			case 'r&r':
				return formik.errors['r&r'];
			case 'sublet':
				return formik.errors.sublets;
			case 'misc':
				return formik.errors.misc;
			case 'turret':
				return formik.errors.turret || formik.errors.turret_misc;
			case 'part':
				return formik.errors.parts;
			case 'note':
				return formik.errors.notes;
			case 'photos':
				return formik.errors.photos;
			case 'checklist':
				return formik.errors.checklist;
			default:
				return false;
		}
	}, []);


	// add in additional routes based on an allowed callback on the menu item
	const dynamicRoutes = useMemo(() => Object.values(additionalSubMenuItems).filter(item => item.allowed?.(submission, gate) || false).map((item) => (
		<Route
			key={item.key}
			path={item.url}
			element={item.page}
		/>
	)), [submission, gate]);
	const routes = useMemo(() => staticRoutes.concat(dynamicRoutes), [dynamicRoutes]);

	return (
		<>
			<GlobalDialogsProvider>
				{isFetched && (
					<FormPricesProvider submission={submission} loadedValues={loadedValues}>
						{({ initialValues }) => (
							<Formik
								initialValues={initialValues}
								validationSchema={() => triageSchema(submission)}
								onSubmit={handleSubmit}
								initialStatus="in_progress"
								validateOnMount
							>
								{({ isSubmitting, values }) => (
									<FormProvider
										menuItems={Object.values(subMenuItems)}
										additionalMenuItems={Object.values(additionalSubMenuItems)}
										menuItemsRules={menuItemsRules}
										menuItemsErrors={menuItemsErrors}
										initialValues={values}
										submission={submission}
									>
										{({ onBlur }) => (


											<OldPage title="Estimate Form" sx={{ backgroundColor: 'background.paper' }}>
												{submission.is_completed && (
													<FormContainer paddingBottom={2}>
														<Gate has="tenant.apps.audits.view">
															<FormChildrenTabs />
														</Gate>
													</FormContainer>
												)}

												{(submissionFetched && submission.assessment) && (
													<FormContainer paddingBottom={2}>
														<Accordion>
															<AccordionSummary
																expandIcon={<ExpandMoreIcon />}
																aria-controls="panel1a-content"
																id="panel1a-header"
															>
																<Grid container>
																	<Grid item xs={12} md={3} sx={{
																		display: 'flex',
																		alignItems: 'center',
																		justifyContent: 'center'
																	}}>
																		<Typography variant="h6">Assessment Details</Typography>
																	</Grid>
																	<Grid item xs={12} md={9} display="flex" alignItems={"center"}>
																		<Typography variant="body2"><b>Claim:</b> {submission.assessment.claim_number}</Typography>
																		<Typography paddingLeft={1} variant="body2"><b>Facility:</b> {submission.assessment?.facility?.name}</Typography>
																		<Typography paddingLeft={1} variant="body2"><b>Event:</b> {submission.assessment.facility?.event?.name}</Typography>
																	</Grid>
																</Grid>
															</AccordionSummary>
															<AccordionDetails>
																<Typography color="text.secondary">
																	Customer: {[submission.assessment.customer?.full_name, submission.assessment.customer?.company_name, `(${submission.assessment.customer?.postcode})`].join(' ')}
																</Typography>
																<Typography color="text.secondary">
																	Vehicle: {[submission.assessment.vehicle?.make, submission.assessment.vehicle?.model, submission.assessment.vehicle?.badge, `- ${submission.assessment.vehicle?.rego}`].join(' ')}
																</Typography>
																<Typography color="text.secondary">
																	Booking Date: {[DateTime.fromISO(submission.assessment.datetime).toLocaleString()].join(' ')}
																</Typography>
															</AccordionDetails>
														</Accordion>
													</FormContainer>
												)}

												<Form>
													<Routes>
														{routes}
														{/* Route to default page/tab */}
														<Route path="*" element={<Navigate replace to={submission.is_completed ? subMenuItems.review.url : subMenuItems.job.url} />} />
													</Routes>
												</Form>

											</OldPage>
										)}
									</FormProvider>
								)}
							</Formik>
						)}
					</FormPricesProvider>

				)}

				{!config.envIsLocal && (
					<Dialog
						open={open}
						onClose={handleClose}
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Please remember to take photos of the vehicle
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose} autoFocus>
								OK!
							</Button>
						</DialogActions>
					</Dialog>
				)}

				<AssessmentEditDialog />
			</GlobalDialogsProvider>
		</>
	);
});
FormEstimate.displayName = 'TriageForm';

export default FormEstimate;