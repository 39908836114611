import { baseParts } from "../form/estimate/data/data";

export const getPartsForPanel = (panel) => {
	return baseParts(null, panel.key);
}

export const mergeValuesWithInital = (values, template, defaultValue = null) => {
	if (Array.isArray(values) && !values.length) {
		return template;
	}

	if (Array.isArray(values) && Array.isArray(template)) {
		return deepMergeArray(values, template);
	} else if (typeof values == "object" && typeof template == "object") {
		return deepMergeObject(values, template, defaultValue);
	} else {
		throw new Error("Invalid parameters for mergeValuesWithInital");
	}
}

function deepMergeObject(target, source, defaultValue = null) {
	return Object.keys(source).reduce((merged, key) => {
		if (Array.isArray(source[key])) {
			merged[key] = deepMergeArray(target[key] || [], source[key]);
		} else if (source[key] instanceof Object) {
			merged[key] = deepMergeObject(target[key] || {}, source[key], defaultValue);
		} else {
			merged[key] = target[key] ?? source[key] ?? defaultValue;
		}
		return merged;
	}, { ...target });
}

function deepMergeArray(values, template) {
	// Create a map to store values by their .key property
	const valuesMap = new Map(values.map((obj) => [obj.key, obj]));

	// Iterate through the template
	const mergedResult = template.map((templateObject) => {
		const key = templateObject.key;

		if (templateObject._group) {
			// Preserve the template object if it has _group: true
			return templateObject;
		} else if (templateObject.disabled) {
			// Preserve the template object if its template item is disabled
			return templateObject;
		} else if (valuesMap.has(key)) {
			// Merge the template object with the matching value
			const valueObject = valuesMap.get(key);
			return { ...templateObject, ...valueObject };
		} else {
			// Return the template object as is
			return templateObject;
		}
	});

	// Add any leftover values to the end
	values.forEach((value) => {
		if (!mergedResult.some((obj) => obj.key === value.key)) {
			mergedResult.push(value);
		}
	});

	return mergedResult;
}

export const mergeObjectLeft = (left, right) => {
	const merged = { ...left };

	for (const key in left) {
		if (Array.isArray(left[key])) {
			merged[key] = right[key] ?? left[key];
		} else if (left[key] instanceof Object) {
			merged[key] = mergeObjectLeft(left[key], right[key]);
		} else {
			merged[key] = right[key] ?? left[key];
		}
	}
	return merged;
}

export function removeDuplicatesByKey(array) {
	return array.filter((item, index, self) =>
		index === self.findIndex((t) => JSON.stringify(t.key) === JSON.stringify(item.key))
	);
}


export const containsRequired = (obj, target) => {
	for (const key in obj) {
		if (obj[key] && typeof obj[key] === 'object') {
			if (containsRequired(obj[key], target)) {
				return true;
			}
		} else if (obj[key] && typeof obj[key] === 'string' && (obj[key]).toLowerCase().includes(target)) {
			return true;
		}
	}

	return false;
};

export const fieldForceUpperCase = (event, formik) => {
	formik.setFieldValue(event.target.name, ("" + event.target.value).toUpperCase())
}

export const addOtherPricedItem = (category, key, baseObject, formik, getMiscPrice) => {
	const objValue = Array.isArray(baseObject)
		? baseObject.find((item) => item.key === key)
		: baseObject[key];
	if (!objValue) return;

	const data = formik.values[category] || (Array.isArray(baseObject) ? [] : {});
	if (!data.some((item) => item.key === key)) {
		data.push(
			{
				...objValue,
				price: getMiscPrice(category, key),
			}
		);
		formik.setFieldValue(category, data);
	}
};


export const readOnlyHelper = (value) => ({
	...(value ? { value } : {}),
	disabled: value !== null && value !== undefined && value !== "",
	title: "Set from assessment"
});