import { QueryClient } from "@tanstack/react-query";

/**
 * Generates CRUD routes for a given base path.
 *
 * @param {Object} config - The configuration object.
 * @param {string} config.basePath - The base path for the CRUD routes.
 * @param {JSX.Element} config.crudPage - The component to be rendered for the CRUD list page.
 * @param {JSX.Element} config.newPage - The component to be rendered for the "new" page.
 * @param {JSX.Element} config.editPage - The component to be rendered for the "edit" page.
 * @param {Function} config.crudLoader - The loader function for the CRUD list page.
 * @param {Function} config.editLoader - The loader function for the "edit" page.
 *
 * @returns {Object} The route configuration object for React Router.
 *
 * @example
 * import { generateCrudRoutes } from './path/to/this/module';
 * import CrudPage from './CrudPage';
 * import NewPage from './NewPage';
 * import EditPage from './EditPage';
 * import { crudLoader, editLoader } from './loaders';
 *
 * const routes = generateCrudRoutes({
 *   basePath: '/items',
 *   crudPage: <CrudPage />,
 *   newPage: <NewPage />,
 *   editPage: <EditPage />,
 *   crudLoader,
 *   editLoader,
 * });
 */
export function generateCrudRoutes({
	basePath,
	crudPage,
	newPage,
	editPage,
	crudLoader,
	editLoader
}) {
	const queryClient = new QueryClient();

	return {
		path: basePath,
		children: [
			{
				index: true,
				element: crudPage,
				loader: crudLoader(queryClient),
			},
			{
				path: "new",
				element: newPage,
			},
			{
				path: ":id",
				element: editPage,
				loader: editLoader(queryClient),
				// https://tanstack.com/query/latest/docs/framework/react/examples/react-router
			},
		]
	}
}