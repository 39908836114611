import { Box, Avatar as MuiAvatar, Tooltip, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

function stringToColor(str) {
	if (!str) return '#000000';
	// Simple hash function to generate a numeric value based on the input string
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}

	// Convert the hash to a positive number
	hash = Math.abs(hash);

	// Generate a hex color code
	const color = '#' + (hash % 0xFFFFFF).toString(16).padStart(6, '0');
	return color;
}

function nameToInitials(name = "") {
	return name
		? name.trim().split(/\s+/).map(word => word[0].toUpperCase()).join('')
		: null;
}

const Avatar = React.memo(({ name, size = 48, sx = {}, noToolip = false, inline = false, ...props }) => {

	// const params = {
	// 	name,
	// 	rounded: true
	// };

	// const url = new URL("https://ui-avatars.com/api/");
	// url.search = new URLSearchParams(params).toString();
	const theme = useTheme();

	const OptionalTooltip = noToolip ? React.Fragment : Tooltip;

	return (
		<OptionalTooltip {...(noToolip ? {} : { title: name })}>
			<Box sx={sx} style={{ display: inline ? 'inline-block' : 'block' }}>
				<MuiAvatar sx={{ width: size, height: size, fontSize: (size * 0.4), bgcolor: stringToColor(name), color: theme.palette.getContrastText(stringToColor(name)) }} {...props}>
					{nameToInitials(name)}
				</MuiAvatar>
			</Box>
		</OptionalTooltip>
	);
});

Avatar.displayName = 'Avatar';


Avatar.propTypes = {
	name: PropTypes.string
};

export default Avatar;