// material-ui
import { Divider, List, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import MenuCollapse from './MenuCollapse';
import MenuItem from './MenuItem';

// types 
import PropTypes from 'prop-types';
import { useGate } from '~/hooks/useGate';


const MenuGroup = ({ item, onNavigation = null }) => {
	const theme = useTheme();
	const { gate } = useGate();

	// menu list collapse & items
	const items = (item.children ?? [])
		.filter(menu => gate(menu.gate ?? true))
		.map((menu, i) => {
			switch (menu.type) {
				case 'collapse':
					return <MenuCollapse key={i} menu={menu} level={1} />;
				case 'item':
					return <MenuItem key={i} item={menu} level={1} onNavigation={onNavigation} />;
				default:
					return (
						<Typography key={i} variant="h6" color="error" align="center">
							Menu Items Error
						</Typography>
					);
			}
		});

	if (items.length === 0) {
		return null;
	}

	return (
		<>
			<List
				subheader={
					item.title && (
						<Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
							{item.title}
							{item.caption && (
								<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
									{item.caption}
								</Typography>
							)}
						</Typography>
					)
				}
			>
				{items}
			</List>

			<Divider sx={{ mt: 0.25, mb: 1.25 }} />
		</>
	);
};

MenuGroup.propTypes = {
	item: PropTypes.object
};

export default MenuGroup;
