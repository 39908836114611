
import { useContext } from "react";
import { __ConfirmationDialogContext } from "../providers/dialogs/ConfirmationDialogProvider";

// supported types, delete, info, question, alert

export function useConfirm() {
	const context = useContext(__ConfirmationDialogContext);
	const { open, close } = context;

	return {
		openConfirm: open,
		closeConfirm: close,
	};
}
