
export const isObject = v => v && typeof v === 'object';

export const isArray = v => Array.isArray(v);

// Func to set all leafs in a node to value or fallback to the path
export const setLeaf = (obj, checkFn, value = null, path = []) => {
	if (checkFn(obj)) {
		for (var key in obj) {
			if (checkFn(obj[key])) {
				setLeaf(obj[key], checkFn, value, [...path, key]);
			} else {
				obj[key] = value ? value : [...path, key].join('.');
			}
		}
	}

	return obj;
};

// Function to get nested value from theme
export const getValue = (path, obj) =>
	path.split('.').reduce((acc, key) => acc && acc[key], obj);

export const leftMerge = (a, b) => {
	const res = {};
	for (const p in a)
		res[p] = (p in b ? b : a)[p];
	return res;
}
