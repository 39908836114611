export const states = [
	{
		label: 'QLD',
		value: 'QLD'
	},
	{
		label: 'NSW',
		value: 'NSW'
	},
	{
		label: 'VIC',
		value: 'VIC'
	},
	{
		label: 'SA',
		value: 'SA'
	},
	{
		label: 'WA',
		value: 'WA'
	},
	{
		label: 'TAS',
		value: 'TAS'
	},
	{
		label: 'NT',
		value: 'NT'
	},
	{
		label: 'ACT',
		value: 'ACT'
	}
]