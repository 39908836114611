import { Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Field } from "formik";
import { Checkbox } from 'formik-mui';
import FormInput from "~/components/Forms/FormInput";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import { useForm } from "../../hooks/useForm";

const items = [
	{
		key: "required_badges",
		label: "Have you list all require badges?",
	},
	{
		key: "taken_pictures",
		label: "Have you taken pictures?",
	},
];

const PageChecklist = () => {

	const { formik, isCompleted } = useForm();

	return (
		<EstimateFormPage>
			<PageTitle>Checklist</PageTitle>
			<Grid container paddingTop={1}>
				<Grid item xs={12}>
					<List>
						{items.map((value) => {
							const labelId = `checkbox-list-label-${value}`;
							return (
								<ListItem key={value.key}>
									<ListItemButton
										role={undefined}
										dense
										onClick={() => isCompleted ? null : formik.setFieldValue(`checklist.${value.key}`, !formik.values.checklist?.[value.key])}
									>
										<ListItemIcon>
											<Field
												component={FormInput}
												inputComponent={Checkbox}
												name={`checklist.${value.key}`}
												type="checkbox"
												edge="start"
												tabIndex={-1}
												disableRipple
												inputProps={{ 'aria-labelledby': labelId }}
											/>

										</ListItemIcon>
										<ListItemText id={labelId} primary={value.label} />
									</ListItemButton>
								</ListItem>
							);
						})}
					</List>
				</Grid>
			</Grid>
		</EstimateFormPage>
	);
};

export default PageChecklist;
