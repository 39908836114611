import { useCallback, useContext } from 'react';
import { GlobalStateContext } from '~/providers/GlobalStateProvider';

const useGlobalState = () => {
  const { state, setState, globalKeys } = useContext(GlobalStateContext);

  const setGlobalState = useCallback((key, value) => {
    setState((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, [setState]);

  return ({
    ...state,
    globalKeys,
    setGlobalState,
  });
}

export default useGlobalState