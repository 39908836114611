import React, { useCallback } from "react";
import { usePopupState } from "material-ui-popup-state/hooks";
import StartAuditDialog from "~/components/Dialogs/StartAuditDialog";

// context setup
const StartAuditDialogContext = React.createContext({
	popupState: {},
	open: () => { }
});
export const __StartAuditDialogContext = StartAuditDialogContext;

export const StartAuditDialogProvider = ({ children }) => {

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'StartAuditDialog',
	});

	const open = useCallback(() => {
		popupState.open();
	}, [popupState]);

	return (
		<StartAuditDialogContext.Provider
			value={{ popupState, open }}
		>
			<>

				<StartAuditDialog
					isOpen={popupState.isOpen}
					onClose={popupState.close}
				/>
				{children}
			</>
		</StartAuditDialogContext.Provider>
	);
};
