import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	FormHelperText,
	Grid,
	InputAdornment,
	MenuItem,
	Radio,
	Stack,
	Typography,
	useMediaQuery,
	useTheme
} from "@mui/material";
import { FastField, Field, Formik, useField, useFormikContext } from "formik";
import { Checkbox, CheckboxWithLabel, RadioGroup, Select, Switch, TextField } from "formik-mui";
import { useCallback, useMemo, useState } from 'react';
import * as Yup from 'yup';

import { LoadingButton } from '@mui/lab';
import clsx from 'clsx';
import { Form } from 'react-router-dom';
import CustomList from '../../../components/CustomList';
import { getPartsForPanel, mergeValuesWithInital } from '../../../helpers/dataHelpers';
import { useFormEffect } from '../../../hooks/useFormEffect';
import { usePrevious } from '../../../hooks/usePrevious';
import { baseRRItems, conventionalRepairTypes, panelsBase } from '../data/data';

import FormInput from '~/components/Forms/FormInput';
import { useForm } from "~/hooks/useForm";
import "../../estimate/styles/PanelLabour.css";

const initialValues = {
	report: false,
	pdr_class: null,
	pdr_class_costs: {},

	pdr_dent_count: null,
	pdr_dent_size: null,
	pdr_alloy: false,
	pdr_manual_price: null,

	conventional_repair: null,
	repair_heavy_cost: null,
	repair_full_conventional_cost: null,
	conventional_repair_extras: {
		'r&r': true,
		'paint': true
	},

	inconsistent_damage: false,
	inconsistent_damage_unrepairable: false,
	inconsistent_damage_percentage: null,

	replace_panel: false,
	replace_panel_extras: {
		'r&r': true,
		paint: true,
		paint_blend: true,
	},
	parts: [],
	blends: [],
	costs: {
		pdr_total: 0,
		repair_total: 0,
		replacement_extras_total: 0,
		total: 0
	}
};


const dentSizes = [
	{
		label: "15mm/A",
		value: "a"
	},
	{
		label: "25mm/B",
		value: "b"
	},
	{
		label: "35mm/C",
		value: "c"
	},
	{
		label: "50mm/D",
		value: "d"
	}
];

const schema = Yup.object().shape({
	report: Yup.boolean(),

	// fixed
	pdr_class: Yup.string().nullable(),

	// count
	pdr_dent_count: Yup.number().integer().nullable()
		.when(['replace_panel', 'pdr_manual_price', 'conventional_repair'], {
			is: (replace_panel, pdr_manual_price, conventional_repair) => !replace_panel && !pdr_manual_price && !conventional_repair,
			then: () => Yup.number().integer("Dent Count must be a whole number").required('Dent Count is required').moreThan(0, "Dent count must be greater than 0"),
		}),

	// size required
	pdr_dent_size: Yup.string().nullable()
		.when(['pdr_dent_count', 'pdr_manual_price', 'replace_panel'], {
			is: (count, price, replace) => count > 0 && !price && !replace,
			then: () => Yup.string().oneOf(dentSizes.map(dent => dent.value)).required('PDR Dent Size is required'),
		}),

	pdr_alloy: Yup.boolean(),

	conventional_repair: Yup.string().nullable(),
	repair_heavy_cost: Yup.number().nullable()
		.when('conventional_repair', {
			is: 'heavy',
			then: () => Yup.number().nullable().required("Repair Cost is required").min(0, "Repair Cost must be greater than 0"),
		}),

	repair_full_conventional_cost: Yup.number().nullable()
		.when('conventional_repair', {
			is: 'full',
			then: () => Yup.number().nullable().required("Repair Cost is required").min(0, "Repair Cost must be greater than 0"),
		}),

	pdr_manual_price: Yup.number().min(0, "Price must be greater than 0").nullable(),

	replace_panel: Yup.boolean(),
	inconsistent_damage: Yup.boolean(),
	inconsistent_damage_unrepairable: Yup.boolean(),
	inconsistent_damage_percentage: Yup.number().integer().nullable()
		.min(0, "Percentage must be greater than 0")
		.max(100, "Percentage must be less than or equal to 100"),
});


const MonitorRemovedParts = ({ setRemovedParts }) => {

	const { values } = useFormikContext();
	const prevParts = usePrevious(values.parts);

	useFormEffect(['parts'], (values) => {
		// get all previosu vaules.parts where not in values
		const removedParts = prevParts.filter(prevPart => !values.parts.includes(prevPart));

		setRemovedParts(removedParts);
	});

	return null;
};

const MonitorBlends = ({ setRemovedRr, blends }) => {

	const { values, setFieldValue } = useFormikContext();
	const previousValues = usePrevious(values.blends);

	useFormEffect(['blends'], (values) => {
		// get all previous vaules.blends where not in values where type = 'r&r'
		const removed = previousValues.filter(prev => !values.blends.includes(prev) && prev.type === 'r&r');

		setRemovedRr(removed);
	});

	useFormEffect(['replace_panel', 'conventional_repair'], (values) => {
		// enable all blends if replace_panel is enabled or concentional_repair is set to anything other than empty string
		if (values.replace_panel || values.conventional_repair) {
			setFieldValue('blends', blends);
		}

	});

	return null;
};

const PanelLabourModal = ({ panel = {}, isOpen = false, onClose, onSave, onDelete, useFixedValues = true, parentFormikValues }) => {

	const { isCompleted, estimateMeta } = useForm();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const parts = useMemo(() => getPartsForPanel(panel), [panel]);

	const [removedParts, setRemovedParts] = useState([]);
	const [removedRr, setRemovedRr] = useState([]);

	const handleSubmit = useCallback((values, { setSubmitting }) => {
		onSave(panel, values, removedParts, removedRr, () => {
			setSubmitting(false);
		});
	}, [onSave, panel, removedParts, removedRr]);

	const isNew = useMemo(() => panel.selected, [panel]);

	const getInitialMergedValues = useCallback(() => {
		// get parts from parent form but only where in parts list by key match
		const _parts = parentFormikValues.parts.filter(part => parts.some(p => p.key === part.key));

		const merged = {
			...initialValues,
			...panel.values,
		};

		return {
			...merged,
			parts: mergeValuesWithInital(_parts, merged.parts ?? []),
			// blends: mergeValuesWithInital(_rr, merged.blends ?? []), // same here?
		};
	}, [panel.blends_rr, panel.values, parentFormikValues, parts]);


	const blends = useMemo(() => {

		const data = [
			// Panels 
			...(panel.blends_panels || [])
				.filter(key => panelsBase(estimateMeta.bodyStyle).find(p => p.key === key))
				.map(key => ({
					type: 'panel',
					...panelsBase(estimateMeta.bodyStyle).find(p => p.key === key),
					hours: panel.hours.blends?.[key]?.paint || 0
				})),
			// RR
			...(panel.blends_rr || [])
				.filter(key => baseRRItems(estimateMeta.bodyStyle).find(p => p.key === key))
				.map(key => ({ type: 'r&r', ...baseRRItems(estimateMeta.bodyStyle).find(p => p.key === key) })),
		];
		// .sort((a, b) => (a.label ?? a.name).localeCompare((b.label ?? b.name)));
		return data;

	}, [estimateMeta.bodyStyle, panel]);

	return (
		<Dialog
			open={isOpen}
			onClose={onClose}
			scroll='paper'
			fullWidth
			fullScreen={isMobile}
			maxWidth="lg"
		>
			<Formik
				initialValues={getInitialMergedValues()}
				onSubmit={handleSubmit}
				validationSchema={schema}
			>
				{formik => (
					<Form onBlur={e => e.stopPropagation()}>
						<MonitorRemovedParts setRemovedParts={setRemovedParts} />
						<MonitorBlends setRemovedRr={setRemovedRr} blends={blends} />
						<Box className='labour-cost-modal-header'>
							<Grid container sx={{
								paddingLeft: 2,
								paddingRight: 2
							}}>
								<Grid item xs={6} alignItems="center" justifyContent="start" display="flex">
									<div style={{ font: '18px' }}>{panel.name}</div>
								</Grid>
								<Grid item xs={6} textAlign="right">
									<FastField
										component={FormInput}
										inputComponent={CheckboxWithLabel}
										name="report"
										type="checkbox"
										Label={{ label: "Report" }}
									/>
								</Grid>
							</Grid>
						</Box>
						<DialogContent>
							<Stack spacing={2}>
								<Box>
									<Typography variant="h3" marginBottom={2}>
										Paintless Dent Repair
									</Typography>
									{useFixedValues
										? <FixedCostPDR panel={panel} formik={formik} readOnly={isCompleted} disabled={formik.values.replace_panel} />
										: <DentCountPDR panel={panel} formik={formik} disabled={formik.values.replace_panel || formik.values.conventional_repair == conventionalRepairTypes.full.value} />
									}
								</Box>

								<Box>
									<Typography variant="h3" marginBottom={2}>
										Inconsistent Damage
									</Typography>

									<Grid container spacing={1}>
										<Grid item xs={12} md={4}>
											<Box
												className={clsx(
													'labour-modal-radio-check-buttons',
													{
														'active': formik.values.inconsistent_damage === true
													})
												}
												height="100%"
											>
												<FormControlLabel control={
													<Field
														component={FormInput}
														inputComponent={Switch}
														name="inconsistent_damage"
														type="checkbox"
													/>
												}
													label="Inconsistent Damage"
													labelPlacement='end'
												/>
											</Box>
										</Grid>
										{formik.values.inconsistent_damage === true && (
											<>
												<Grid item xs={6} md={4}>
													<Box
														className={clsx(
															'labour-modal-radio-check-buttons',
															{
																'active': formik.values.inconsistent_damage === true
															})
														}
														height="100%"
													>
														<Field
															component={FormInput}
															inputComponent={CheckboxWithLabel}
															name="inconsistent_damage_unrepairable"
															type="checkbox"
															Label={{ label: 'Irrepairable by PDR' }}
														/>
													</Box>

												</Grid>
												<Grid item xs={6} md={4}>
													<Field
														component={FormInput}
														inputComponent={TextField}
														name="inconsistent_damage_percentage"
														label="Percentage Not Consistent"
														variant="outlined"
														fullWidth
														InputProps={{
															startAdornment: <InputAdornment position="start">%</InputAdornment>,
															inputMode: 'numeric',
															min: 1,
															max: 100,
														}}
													/>
												</Grid>
											</>
										)}
									</Grid>
								</Box>

								<Box>
									<Typography variant="h3" marginBottom={2}>
										Conventional Repair
									</Typography>

									<Grid container spacing={1}>
										<Grid item xs={12}>
											<Field
												component={RadioGroup}
												name="conventional_repair"
											>
												<Grid container spacing={1}>
													<Grid item xs={4}>
														<CustomRadioInput
															panel={panel}
															component={Radio}
															name="conventional_repair"
															label={conventionalRepairTypes.light.label}
															value={conventionalRepairTypes.light.value}
															cost={panel.costs?.conventional_repair?.light}
															disabled={formik.values.replace_panel}
															readOnly={isCompleted}
														/>

													</Grid>
													<Grid item xs={4}>
														<CustomRadioInput
															panel={panel}
															component={Radio}
															name="conventional_repair"
															label={conventionalRepairTypes.heavy.label}
															value={conventionalRepairTypes.heavy.value}
															cost={panel.costs?.conventional_repair?.heavy}
															costProps={{
																name: conventionalRepairTypes.heavy.cost_array,
																labels: conventionalRepairTypes.heavy.labels
															}}
															disabled={formik.values.replace_panel}
															readOnly={isCompleted}
														/>
													</Grid>
													<Grid item xs={4}>
														<CustomRadioInput
															panel={panel}
															component={Radio}
															name="conventional_repair"
															label={conventionalRepairTypes.full.label}
															value={conventionalRepairTypes.full.value}
															cost={panel.costs?.conventional_repair?.full}
															costProps={{
																name: conventionalRepairTypes.full.cost_array,
																labels: conventionalRepairTypes.full.labels
															}}
															disabled={formik.values.replace_panel}
															readOnly={isCompleted}
														/>
													</Grid>
												</Grid>
											</Field>
										</Grid>

										<Grid item xs={6}>
											<Box
												className={clsx(
													'labour-modal-radio-check-buttons',
													{
														'active': formik.values.conventional_repair_extras?.['r&r'] === true
													})
												}
											>
												<Field
													component={FormInput}
													inputComponent={CheckboxWithLabel}
													name="conventional_repair_extras.r&r"
													type="checkbox"
													Label={{ label: 'R&R' }}
													disabled={formik.values.replace_panel || formik.values.conventional_repair === null}
													readOnly
												/>
												<Cost value={panel.costs?.conventional_repair?.['r&r']} disabled={isCompleted} paddingX={1} />
											</Box>
										</Grid>
										<Grid item xs={6}>
											<Box
												className={clsx(
													'labour-modal-radio-check-buttons',
													{
														'active': formik.values.conventional_repair_extras?.paint === true
													})
												}
											>
												<Field
													component={FormInput}
													inputComponent={CheckboxWithLabel}
													name="conventional_repair_extras.paint"
													type="checkbox"
													Label={{ label: 'Paint' }}
													disabled={formik.values.replace_panel || formik.values.conventional_repair === null}
													readOnly
												/>
												<Cost value={panel.costs?.conventional_repair?.paint} disabled={isCompleted} paddingX={1} />
											</Box>
										</Grid>
									</Grid>
								</Box>


								{panel.can_replace && (

									<Box>
										<Box marginBottom={2} className="labour-cost-modal-subheadings">
											<Typography variant="h3" sx={{ cursor: "pointer" }}
												onClick={() => isCompleted ? null : formik.setFieldValue("replace_panel", !formik.values.replace_panel)}
											>
												Replace Panel
											</Typography>
											<Field
												component={FormInput}
												inputComponent={Checkbox}
												name="replace_panel"
												type="checkbox"
											/>
										</Box>

										{panel.key.startsWith('turret') && (
											<FormHelperText>Remember to select the turret size on the TUR page</FormHelperText>
										)}

										<Grid container spacing={1}>
											<Grid item xs={6}>
												<Box
													className={`labour-modal-radio-check-buttons ${panel ? 'active' : ''}`}
												>
													<Field
														component={FormInput}
														inputComponent={CheckboxWithLabel}
														name="replace_panel_extras.r&r"
														type="checkbox"
														Label={{ label: 'R&R' }}
														disabled={formik.values.replace_panel === false}
														checked
														readOnly
													/>
													<Cost value={panel.costs?.replace?.['r&r']} empty paddingX={1} />
												</Box>
											</Grid>
											<Grid item xs={6}>
												<Box
													className={`labour-modal-radio-check-buttons ${panel ? 'active' : ''}`}
												>
													<Field
														component={FormInput}
														inputComponent={CheckboxWithLabel}
														name="replace_panel_extras.paint"
														type="checkbox"
														Label={{ label: 'Paint' }}
														disabled={formik.values.replace_panel === false}
														checked
														readOnly
													/>
													<Cost value={panel.costs?.replace?.paint} empty paddingX={1} />
												</Box>
											</Grid>
											{/* <Grid item xs={4}>
												<Box
													className={`labour-modal-radio-check-buttons ${panel ? 'active' : ''}`}
												>
													<Field
														component={FormInput}
														inputComponent={CheckboxWithLabel}
														name="replace_panel_extras.paint_blend"
														type="checkbox"
														Label={{ label: 'Paint Blend' }}
														disabled={formik.values.replace_panel === false}
														checked
														readOnly
													/>
													<Cost value={panel.costs?.replace?.paint_blend} empty paddingX={1} />
												</Box>
											</Grid> */}
										</Grid>
									</Box>
								)}

								{!!blends.length && (
									<CustomList.Main name="blends" headers={[[""]]} readOnly={isCompleted}>
										<CustomList.List>

											<CustomList.Group
												expanded={formik.values.conventional_repair || formik.values.replace_panel}
												defaultExpanded={formik.values.blends.length > 0 || formik.values.replace_panel}
												heading={
													<Typography variant="h3" component="h3">
														Blends
													</Typography>
												}
											>
												{blends.map(item => (
													<CustomList.ListItem key={item.key} value={item}>
														<CustomList.ListColumn>
															<CustomList.Checkbox
																label={
																	<Stack direction='row' alignItems='center' gap={1}>
																		<Typography>{item.label ?? item.name}</Typography> <Chip size="small" label={item.type} />
																	</Stack>
																}
																value={item}
															/>
														</CustomList.ListColumn>
													</CustomList.ListItem>
												))}
											</CustomList.Group>
										</CustomList.List>
									</CustomList.Main>
								)}

								{!!parts.length && (
									<CustomList.Main name="parts" headers={[[""]]} readOnly={isCompleted}>
										<CustomList.List>

											<CustomList.Group
												defaultExpanded={formik.values.parts.length > 0 || formik.values.replace_panel}
												heading={
													<Typography variant="h3" component="h3">
														Parts
													</Typography>
												}
											>
												{parts.map(part => (
													<CustomList.ListItem key={part.key} value={part}>
														<CustomList.ListColumn>
															<CustomList.Checkbox
																label={part.label}
																value={part}
															/>
														</CustomList.ListColumn>
													</CustomList.ListItem>
												))}
											</CustomList.Group>
										</CustomList.List>
									</CustomList.Main>
								)}
							</Stack>
						</DialogContent>
						<DialogActions>
							{!isCompleted && (
								<Button
									variant="contained"
									color="error"
									onClick={() => onDelete(panel)}
									disabled={!isNew}
									sx={{
										marginRight: 'auto'
									}}
								>
									Delete
								</Button>
							)}

							<Button
								variant="contained"
								color="primary"
								onClick={e => onClose(e, 'cancel')}
							>
								{isCompleted ? "Close" : "Cancel"}
							</Button>

							{!isCompleted && (
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={formik.submitForm}
									loading={formik.isSubmitting}
								>
									Update
								</LoadingButton>
							)}

						</DialogActions>
					</Form>
				)
				}
			</Formik>
		</Dialog>
	)
};

export default PanelLabourModal;


const FixedCostPDR = ({ panel, formik, disabled, readOnly }) => {

	return (
		<Field
			component={RadioGroup}
			name="pdr_class"
		>
			<Grid container spacing={0.5}>
				<Grid item xs={4}>
					<CustomRadioInput
						panel={panel}
						name="pdr_class"
						label="Light"
						value="light"
						cost={panel.costs?.pdr?.fixed?.light}
						manualInputName="pdr_class_costs.light"
						disabled={disabled}
						readOnly={readOnly}
					/>
				</Grid>

				<Grid item xs={4}>
					<CustomRadioInput
						panel={panel}
						name="pdr_class"
						label="Medium"
						value="medium"
						cost={panel.costs?.pdr?.fixed?.medium}
						manualInputName="pdr_class_costs.medium"
						disabled={disabled}
						readOnly={readOnly}
					/>
				</Grid>

				<Grid item xs={4}>
					<CustomRadioInput
						panel={panel}
						name="pdr_class"
						label="Heavy"
						value="heavy"
						cost={panel.costs?.pdr?.fixed?.heavy}
						manualInputName="pdr_class_costs.heavy"
						disabled={disabled}
						readOnly={readOnly}
					/>

				</Grid>

				<Grid item xs={4}>
					<CustomRadioInput
						panel={panel}
						name="pdr_class"
						label="Extreme"
						value="extreme"
						cost={panel.costs?.pdr?.fixed?.extreme}
						manualInputName="pdr_class_costs.extreme"
						disabled={disabled}
						readOnly={readOnly}
					/>
				</Grid>

				<Grid item xs={4}>
					<CustomRadioInput
						panel={panel}
						name="pdr_class"
						label="Combo"
						value="combo"
						cost={panel.costs?.pdr?.fixed?.combo}
						manualInputName="pdr_class_costs.combo"
						disabled={disabled}
						readOnly={readOnly}
					/>
				</Grid>

			</Grid>
		</Field>
	)
};


const DentCountPDR = ({ panel, formik, disabled }) => {

	return (
		<Grid container spacing={1}>

			<Grid item xs={6} md={3}>
				<Field
					component={FormInput}
					inputComponent={TextField}
					name="pdr_dent_count"
					label="Dent Count"
					fullWidth
					type="number"
					InputProps={{
						inputProps: { min: 0 }
					}}
					disabled={disabled}
				/>
			</Grid>


			<Grid item xs={6} md={3} className="select-field-full-width">
				<Field
					component={FormInput}
					inputComponent={Select}
					name="pdr_dent_size"
					label="Size"
					disabled={disabled}
				>
					{dentSizes.map((dentObj, i) =>
						<MenuItem
							key={i}
							value={dentObj.value}
						>
							{dentObj.label}
						</MenuItem>
					)}
				</Field>
			</Grid>

			<Grid item xs={6} md={3} className="select-field-full-width">
				<Box
					className={clsx(
						'labour-modal-radio-check-buttons',
						{
							'active': formik.values.pdr_alloy === true
						})
					}
					height="100%"
					alignContent={'center'}
					display={'flex'}
				>
					<FormControlLabel
						control={
							<Field
								component={FormInput}
								inputComponent={Switch}
								label="Alloy"
								name="pdr_alloy"
								type="checkbox"
							/>
						}
						label="Alloy"
						labelPlacement='end'
						disabled={disabled}
					/>
				</Box>


			</Grid>

			<Grid item xs={6} md={3}>
				<Field
					component={FormInput}
					inputComponent={TextField}
					name="pdr_manual_price"
					label="Manual Price Override"
					fullWidth
					type="number"
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
						inputMode: 'numeric',
						min: 0,
					}}
					disabled={disabled}
				/>
			</Grid>

		</Grid>
	)
};

const Cost = ({ value, disabled = false, labels = [], manualInputName = null, empty = false, ...rest }) => {

	if (value) {

		if (Array.isArray(value)) {
			const values = useMemo(() => value.map((v, i) => ({ 'label': labels[i] || i, 'value': v })), [labels, value]);

			return (
				<div>
					<Field
						component={FormInput}
						inputComponent={Select}
						{...rest}
						label="Cost"
						disabled={disabled}
						formControl={{
							fullWidth: true,
							size: "small",
						}}
						onClick={e => e.stopPropagation()}
					>
						{values.map((v, i) =>
							<MenuItem
								key={i}
								value={v.value}
							>
								{v.label}
							</MenuItem>
						)}
					</Field>
				</div>
			);
		} else {
			return null;

			// return (
			//   <div>
			//     <Typography sx={{ paddingTop: 1, paddingBottom: 1, fontWeight: 600 }} {...rest}>
			//       ${toCurrency(value)}
			//     </Typography>
			//   </div>
			// );
		}
	} else {
		if (empty) {
			return null;
		}

		return manualInputName ? (
			<div>
				<Field
					name={manualInputName}
					label="Manual Price"
					component={TextField}
					disabled={disabled}
					onClick={e => e.stopPropagation()}
					InputProps={{
						startAdornment: <InputAdornment position="start">$</InputAdornment>,
						inputMode: 'numeric',
						min: 0,
					}}
				/>
			</div>
		) : null
	}
}

const CustomRadioInput = ({
	panel,
	name,
	label = "",
	value = null,
	cost,
	costProps = {},
	manualInputName = null,
	disabled = false,
	noCost = false,
	readOnly = false,
	...rest

}) => {

	const { isSubmitting } = useFormikContext();
	const [field, meta, helpers] = useField(name);

	const _disabled = disabled || isSubmitting;

	return (
		<Box
			className={clsx('labour-modal-radio-check-buttons', { 'active': field.value === value })}
			sx={{ cursor: _disabled ? 'not-allowed' : 'pointer' }}
			onClick={() => {
				if (!_disabled && !readOnly) {
					helpers.setValue(field.value === value ? null : value)
				}
			}}
		>
			<FormControlLabel control={
				<Field
					component={FormInput}
					inputComponent={Radio}
					name={name}
					value={value}
				/>
			}
				label={label}
				disabled={_disabled}
				sx={{ pointerEvents: 'none' }}
				{...rest}
			/>
			{!noCost && (
				<Cost value={cost} disabled={_disabled} manualInputName={manualInputName} paddingX={1} {...costProps} />
			)}
		</Box>
	)
}
