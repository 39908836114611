import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MapIcon from '@mui/icons-material/Map';
import { Button, ButtonGroup, Card, CardContent, Chip, Divider, Fab, Grid, IconButton, Stack } from '@mui/material';
import { GridToolbar } from '@mui/x-data-grid-premium';
import { useQuery } from '@tanstack/react-query';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import WorkProviderDropdown from '~/components/Dropdowns/WorkProviderDropdown';
import FilterBar from '~/components/FilterBar/FilterBar';
import FixedFooter from '~/components/FixedFooter';
import { Gate } from '~/components/Gate';
import Loader from '~/components/Loader';
import Page from '~/components/Page';
import PageTitle from '~/components/PageTitle';
import { useConfirm } from '~/hooks/useConfirm';
import { useCrud } from '~/hooks/useCrud';
import { useFilters } from '~/hooks/useFilters';
import { useGate } from '~/hooks/useGate';
import { eventsQuery } from '~/loaders/eventsLoader';
import { FilterBarProvider } from '~/providers/FilterBarProvider';
import { deleteEvent } from '~/requests/events';
import AddIcon from '@mui/icons-material/Add';

const DataGridPremium = React.lazy(() => import('@mui/x-data-grid-premium').then(module => ({ default: module.DataGridPremium })));

const defaultFilters = {
	is_deleted: null,
	workprovider_uuid: null,
}

const EventsPage = () => {
	const [isLoading, setIsLoading] = useState(false);

	return (
		<Page title="Events">
			<FilterBarProvider defaults={defaultFilters}>
				<Grid container marginBottom={3} spacing={2}>
					<Grid item xs={12} lg={2.5} alignItems="center" display="flex">
						<PageTitle gutterBottom={false} icon={<MapIcon />}>Events</PageTitle>
					</Grid>
					<Grid item xs={12} lg={9.5}>
						<FilterBar>
							{({ filters, updateFilterValue, Wrap }) => (
								<>
									<ButtonGroup variant='outlined' disableElevation>
										<Button variant={!filters.is_deleted ? 'contained' : 'outlined'}
											onClick={() => updateFilterValue('is_deleted', null)}
										>
											Active
										</Button>
										<Button variant={filters.is_deleted ? 'contained' : 'outlined'}
											onClick={() => updateFilterValue('is_deleted', true)}
										>
											Inactive
										</Button>
									</ButtonGroup>
									<Divider orientation='vertical' flexItem />
									<Wrap>
										<WorkProviderDropdown name="workprovider_uuid" value={filters.workprovider_uuid} size="small" sx={{ flex: 1 }} onChange={updateFilterValue} fullWidth />
									</Wrap>
								</>
							)}
						</FilterBar>
					</Grid>
				</Grid>

				<CrudTable onLoading={setIsLoading} />

				<FixedFooter>
					<Gate has="tenant.manage.events.manage">
						<Link to='/manage/events/new'>
							<Fab
								color="primary"
								variant="extended"
								disabled={isLoading}
							>
								<AddIcon sx={{ mr: 1 }} />
								Create Event
							</Fab>
						</Link>
					</Gate>
				</FixedFooter>
			</FilterBarProvider>
		</Page>
	)
}

export default EventsPage



const RowActionButtons = (params) => {

	const { showDelete = true } = params;

	const { openConfirm, closeConfirm } = useConfirm();
	const crud = useCrud({ deleteRequest: deleteEvent, queryKey: 'events' });

	const handleDeleteClick = (uuid) => {
		// Implement your delete logic here
		openConfirm({
			type: 'delete',
			title: 'Delete Event',
			message: 'Are you sure you want to delete this event?',
			onConfirm: () => {
				crud.doDelete({
					id: uuid,
					toastLabel: 'Event',
					onSettled: () => closeConfirm()
				});
			},
			onCancel: () => {
				closeConfirm();
			}
		});
	};

	return (
		<Gate has="tenant.manage.events.manage">
			<Link to={`/manage/events/${params.row.uuid}`}>
				<IconButton aria-label="edit">
					<EditIcon />
				</IconButton>
			</Link>
			{showDelete && (
				<IconButton aria-label="delete" onClick={() => handleDeleteClick(params.row.uuid)}>
					<DeleteIcon />
				</IconButton>
			)}
		</Gate>
	);
};

const CrudTable = ({ onLoading }) => {

	const { filters } = useFilters();

	const { data: events, isLoading } = useQuery(eventsQuery(filters));
	const [sortModel, setSortModel] = useState([{ field: 'date', sort: 'desc' }]);

	const { gate } = useGate();

	const columns = useMemo(() => ([
		{ field: 'name', headerName: 'Name', flex: 1, minWidth: 120 },
		{ field: 'date', headerName: 'Date', flex: 1, minWidth: 80, renderCell: ({ value }) => value ? new Date(value).toLocaleDateString() : '' },
		{ field: 'workproviders', headerName: 'Work Providers', flex: 1, minWidth: 120, renderCell: ({ row }) => row.workproviders.map(wp => <Chip key={wp.id} label={wp.name} size="small" sx={{ mr: 0.5 }} />) },
		{ field: 'location', headerName: 'Location', flex: 1, minWidth: 120, renderCell: ({ row }) => `${row.suburb ?? ''} ${row.postcode ?? ''}` },
		{ field: 'assessments_count', headerName: 'Assessments', flex: 1, minWidth: 80 },
		...(gate('tenant.manage.events.manage') ? [
			{
				field: 'actions',
				type: 'custom',
				resizable: false,
				filterable: false,
				disabled: true,
				sortable: false,
				editable: false,
				groupable: false,
				headerName: 'Actions',
				minWidth: 110,
				renderCell: (params) => (<RowActionButtons {...params} showDelete={!!!filters.is_deleted} />)
			}
		] : [])
		,
	]), [filters.is_deleted, gate]);

	useEffect(() => {
		onLoading(isLoading);
	}, [isLoading, onLoading]);

	return (
		<Card>
			<CardContent>
				<Suspense fallback={<Loader />}>
					<DataGridPremium
						columns={columns}
						loading={isLoading}
						rows={events || []}
						sx={{ border: 0 }}
						slots={{ toolbar: GridToolbar }}
						slotProps={{
							toolbar: {
								showQuickFilter: true,
							},
						}}
						sortModel={sortModel}
						onSortModelChange={setSortModel}
						throttleRowsMs={2000}
						autoHeight
						autosizeOnMount
					/>
				</Suspense>
				{isLoading && (
					<Loader />
				)}
			</CardContent>
		</Card>
	);
}