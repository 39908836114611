import apiHandler from "../apiHandler";

export const getDepartments = (params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "departments",
	dataOrParams: params,
	signal
});

export const getDepartment = (uuid, signal = undefined) => apiHandler({
	method: "get",
	url: "departments/" + uuid,
	signal
});

export const createDepartment = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `departments`,
	dataOrParams: params,
	signal
});

export const updateDepartment = (id, params = {}, signal = null) => apiHandler({
	method: "put",
	url: `departments/${id}`,
	dataOrParams: params,
	signal
});

export const deleteDepartment = (id, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `departments/${id}`,
	dataOrParams: params,
	signal
});

export const restoreDepartment = (id, params = {}, signal = null) => apiHandler({
	method: "post",
	url: `departments/${id}/restore`,
	dataOrParams: params,
	signal
});