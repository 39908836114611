import { Button, MenuItem, Stack } from "@mui/material";
import { Field, Formik } from "formik";
import { Select, TextField } from "formik-mui";
import { useCallback } from "react";
import { Form } from "react-router-dom";
import { createUserFeedback } from "~/requests/feedback";
import * as Sentry from "@sentry/browser";
import * as Yup from 'yup';
import { enqueueSnackbar } from 'notistack';
import { useAuth } from "~/hooks/useAuth";


const schema = Yup.object().shape({
	category: Yup.string().required('Category is required'),
	message: Yup.string().required('Message is required').max(500, 'Message must be less than 500 characters')
});

const UserFeedbackForm = () => {

	const { user } = useAuth();

	const collectMetadata = useCallback(() => {
		return {
			version: __APP_VERSION__,
			url: window.location.href,
			view: window.location.pathname, // Or however you manage views in React
			userAgent: navigator.userAgent,
			serviceWorker: navigator.serviceWorker.controller ?? 'None',
			language: navigator.language,
			resolution: {
				width: window.screen.width,
				height: window.screen.height
			},
			viewport: {
				width: window.innerWidth,
				height: window.innerHeight
			},
			referrer: document.referrer,
			localTime: new Date().toLocaleString()
		};
	}, []);

	const handleSubmit = useCallback((values, { setSubmitting, resetForm }) => {
		setSubmitting(true);

		const eventId = Sentry.captureMessage("User Feedback");

		Sentry.captureFeedback({
			name: user.name,
			email: user.email,
			message: values.message,
			associatedEventId: eventId,
		});

		values.meta = {
			...collectMetadata(),
			eventId
		};

		createUserFeedback(values)
			.then(() => {
				enqueueSnackbar('Feedback Submitted');
				resetForm();
			})
			.catch((err) => {
				console.error(err);
				enqueueSnackbar(err.message, { variant: 'error' });
			})
			.finally(() => {
				setSubmitting(false);
			});

	}, [collectMetadata, user.email, user.name]);

	return (
		<Formik initialValues={{ category: '', message: '' }} onSubmit={handleSubmit} validationSchema={schema}>
			{({ values, isSubmitting, submitForm, resetForm }) => (

				<Form>
					<Stack spacing={2}>
						<Field
							component={Select}
							name="category"
							label="Type"
							formControl={{
								fullWidth: true,
								required: true
							}}
						>
							<MenuItem value="idea">Idea</MenuItem>
							<MenuItem value="issue">Issue</MenuItem>
							<MenuItem value="feedback">Feedback</MenuItem>
							<MenuItem value="other">Other</MenuItem>
						</Field>

						<Field
							component={TextField}
							name="message"
							label="Message"
							fullWidth
							multiline
							required
							inputProps={{ maxLength: 500 }}
							helperText={`${values.message.length}/500`}
							rows={10}
						/>
						<Button
							variant="contained"
							color="primary"
							fullWidth
							type="submit"
							onClick={submitForm}
						>
							Submit
						</Button>
					</Stack>
				</Form>
			)}
		</Formik>
	);
}

export default UserFeedbackForm;