import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box } from "@mui/system";
import { useLayoutProvider } from "~/providers/LayoutProvider";

const FormContainer = ({ paddingBottom = 6, className = '', children }) => {

	const { sideDrawerOpen } = useLayoutProvider();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const drawerWidth = isMobile ? 50 : 80;

	return (
		<Box
			component="main"
			style={{
				marginLeft: (sideDrawerOpen ? drawerWidth : 0),
			}}
			className={className}
			sx={{
				flexGrow: 1,
				p: 0,
				pb: paddingBottom,
			}}>
			{children}
		</Box>
	);
}

export default FormContainer;