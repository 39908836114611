import { DateTime } from 'luxon';

export const mobileNumberRegex = /^\+?[\d\s]+$/;
export const phoneNumberRegex = /^\+?[\d\s\(\)\-]+$/;

export const capitalizeFirstLetter = (str) => {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export const toDecimal = (value, decimalPlaces = 2, removeTrailingZeros = false, zeroValue = 0) => {
	if (isNaN(value)) return zeroValue;
	const x = Number(value).toFixed(decimalPlaces);
	return +x !== 0 ? (removeTrailingZeros ? parseFloat(x) : x) : zeroValue;
};

export const toCurrency = (value, decimalPlaces = 0, zeroValue = '0') => {
	if (isNaN(value)) return zeroValue;
	let nf = new Intl.NumberFormat('en-AU', { minimumFractionDigits: decimalPlaces });
	let x = Number(value).toFixed(decimalPlaces);
	return +x !== 0 ? nf.format(x) : zeroValue;
}; 0
export const toCurrencyDifferency = (a, b, zeroValue = '0') => {
	const result = (+a || 0) - (+b || 0);
	const sign = result < 0 ? '-' : '+';
	if (result === 0) return zeroValue;

	return `${sign} $${toCurrency(Math.abs(result), 0, zeroValue)}`;
};

export const toDate = (value, format = 'dd/MM/yy hh:mm a', defaultValue = '') => {
	if (!value) return defaultValue;
	return DateTime.fromISO(value).toFormat(format);
}

export const concatinate = (strings = [], delimiter = ' ') => {
	return strings.join(delimiter);
}

export const toHumanReadable = (value = '', defaultValue = '') => {
	if (!value) return defaultValue;
	return value.replace(/_/g, ' ').replace(/(?:^|\s)\S/g, a => a.toUpperCase());
}

// sprintf but check at least one args exists 
export const sprintf = (str, ...args) =>
	str.replace(/%s/g, () => args.shift() || '');

export const toPercentage = (value, decimalPlaces = 0, zeroValue = '0') => {
	if (isNaN(value)) return zeroValue;
	value = value * 100;
	let x = Number(value).toFixed(decimalPlaces);
	return +x !== 0 ? `${x}%` : zeroValue;
}

export const base64ToBlob = (base64, mime) => {
	const byteString = atob(base64.split(',')[1]);
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}
	return new Blob([ab], { type: mime });
};

export const base64ToFile = (base64, filename) => {
	const mime = base64.split(',')[0].match(/:(.*?);/)[1];
	const blob = base64ToBlob(base64, mime);
	return new File([blob], filename, { type: mime });
};

// handle y/ies pluralization
export const pluralize = (word, enabled) => {
	if (!enabled) return word;

	// Handle words ending with a consonant followed by 'y'
	if (word.endsWith('y') && !/[aeiou]y$/i.test(word)) {
		return word.slice(0, -1) + 'ies';
	}

	// Default pluralization by adding 's'
	return word + 's';
};