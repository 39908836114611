import { Radio, TextField } from '@mui/material';
import { Checkbox, CheckboxWithLabel, Select, Switch, ToggleButtonGroup } from 'formik-mui';
import { forwardRef, useContext, useMemo } from 'react';
import { mergeValuesWithInital } from '~/helpers/dataHelpers';
import { __FormContext } from '~/providers/FormProvider';
import { DatePicker } from '../formik-mui-x-datepickers/DatePicker';
import InputMask from 'react-input-mask';

const FormInput = forwardRef(({ mask = null, inputComponent: Component, field, ..._props }, ref) => {

	const { submission } = useContext(__FormContext);
	const isCompleted = useMemo(() => !!submission?.is_completed, [submission]);

	const isReadOnly = isCompleted || _props.readOnly;

	const props = useMemo(() => ({
		..._props,
		field: {
			...field,
			onChange: (e) => {
				field.onChange(e);

				if (_props.autoSave) {
					_props.form.submitForm();
				}

			}
		}
	}), [_props, field]);

	const componentProps = useMemo(
		() => {
			switch (Component) {

				case Select:
					return isReadOnly
						? mergeValuesWithInital(props, { inputProps: { readOnly: true } })
						: props

				case DatePicker:
				case Radio:
					return isReadOnly
						? mergeValuesWithInital(props, { readOnly: true })
						: props

				case ToggleButtonGroup:
					return isReadOnly
						? mergeValuesWithInital(props, { disabled: true })
						: props

				case Checkbox:
				case CheckboxWithLabel:
				case Switch:
					return isReadOnly
						? mergeValuesWithInital(props, { onChange: () => { } })
						: props

				default:
					return isReadOnly
						? mergeValuesWithInital(props, { InputProps: { readOnly: true } })
						: props
			}
		}, [Component, isReadOnly, props]);

	return mask ? (
		<InputMask
			mask={mask}
			value={field.value || ''}
			onChange={props.field.onChange}
			disabled={componentProps.disabled}
		>
			<Component ref={ref} {...componentProps} />
		</InputMask>
	) : (
		<Component ref={ref} {...componentProps} />
	);

});

FormInput.displayName = 'FormInput';

export default FormInput;