import apiHandler from "~/apiHandler";

/**
 * Searches for users based on a search term.
 *
 * @param {string} term - The search term to use.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the search results.
 *
 * @example
 * const results = await searchUsers("John Doe", controller.signal);
 */
export const searchUsers = (term, signal = undefined) => apiHandler({
	method: "get",
	url: "submissions/search",
	dataOrParams: {
		q: term
	},
	signal
});

/**
 * Retrieves a list of users.
 *
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the list of users.
 *
 * @example
 * const userList = await getUsers({ page: 1, limit: 10 }, controller.signal);
 */
export const getUsers = (params = {}, signal = null) => apiHandler({
	method: "get",
	url: "users",
	dataOrParams: params,
	signal
});

/**
 * Retrieves information about a specific user.
 *
 * @param {string} id - The ID of the user to retrieve.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the user information.
 *
 * @example
 * const user = await getUser("123456", { includeProfile: true }, controller.signal);
 */
export const getUser = (id, params = {}, signal = null) => apiHandler({
	method: "get",
	url: `users/${id}`,
	dataOrParams: params,
	signal
});

/**
 * Creates a new user.
 *
 * @param {object} [params={}] - The user data to be created.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the created user data.
 *
 * @example
 * const newUser = await createUser({ name: "Alice", email: "alice@example.com" }, controller.signal);
 */
export const createUser = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `users`,
	dataOrParams: params,
	signal
});

/**
 * Updates an existing user.
 *
 * @param {string} id - The ID of the user to update.
 * @param {object} [params={}] - The updated user data.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves with the updated user data.
 *
 * @example
 * const updatedUser = await updateUser("123456", { name: "Bob" }, controller.signal);
 */
export const updateUser = (id, params = {}, signal = null) => apiHandler({
	method: "put",
	url: `users/${id}`,
	dataOrParams: params,
	signal
});

/**
 * Deletes a user.
 *
 * @param {string} id - The ID of the user to delete.
 * @param {object} [params={}] - Optional parameters for the request.
 * @param {AbortSignal|null} [signal=null] - The optional AbortSignal for canceling the request.
 * @returns {Promise<any>} A Promise that resolves when the user is deleted.
 *
 * @example
 * // Deleting a user with ID "123456"
 * const deletionResult = await deleteUser("123456", { force: true }, controller.signal);
 */
export const deleteUser = (id, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `users/${id}`,
	dataOrParams: params,
	signal
});

export const restoreUser = (id, params = {}, signal = null) => apiHandler({
	method: "post",
	url: `users/${id}/restore`,
	dataOrParams: params,
	signal
});

export const getRoles = (params = {}, signal = null) => apiHandler({
	method: "get",
	url: "permissions/roles",
	dataOrParams: params,
	signal
});

export const checkUsername = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: "users/check-username",
	dataOrParams: params,
	signal
});