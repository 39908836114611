import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { toCurrencyDifferency, toHumanReadable, toPercentage } from "~/helpers/stringHelpers";
import { Gate } from "./Gate";
import { useMemo } from "react";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ReportOffIcon from '@mui/icons-material/ReportOff';

const getColour = (status) => {
	if (status === 'fail') return 'error.main';
	if (status === 'pass') return 'success.main';
	if (status === 'warning') return 'warning.main';
	return 'text.primary';
}

const SubmissionSummaryAudit = ({
	audit
}) => {

	const ActionedIcon = useMemo(() => audit?.actioned_status === 'approved' ? ThumbUpIcon : ReportOffIcon, [audit]);

	if (!audit) return null;

	return (
		<Box sx={{
			borderRadius: 1,
			borderStyle: 'solid',
			borderWidth: 1,
			px: 2,
			py: 0.2,
			borderColor: getColour(audit.status),
			color: getColour(audit.status),
			width: '100%',
		}}>
			<Stack direction='row' spacing={2} justifyContent="space-between" alignItems="center">
				<Gate has='tenant.apps.audits.see_financials'>
					<Typography>{toCurrencyDifferency(audit.difference_total, 0)}</Typography>
				</Gate>
				<Typography>{audit.difference_count} issue</Typography>
				<Typography>{toPercentage(audit.difference_percentage)}</Typography>
				<Tooltip title={toHumanReadable(audit?.actioned_status)}>
					<ActionedIcon color="secondary" sx={{ fontSize: 18 }} />
				</Tooltip>
			</Stack>
		</Box>
	);
}

export default SubmissionSummaryAudit;