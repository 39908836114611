import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useMemo, useState } from 'react';
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { pluralize } from '~/helpers/stringHelpers';
import { getFacilities } from "~/requests/facilities";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const FacilityAutocomplete = ({
	value = undefined,
	initialValue = undefined,
	multiple = true,
	workproviderId = null,
	onChange,
	...props
}) => {

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { data: data = [], isLoading } = useQuery({
		queryKey: ['facilities', workproviderId],
		queryFn: queryFunctionHelper(getFacilities, { workprovider_uuid: workproviderId }),
		staleTime: 1000 * 60 * 5,
	});

	const _value = useMemo(() => (
		typeof value === 'string' ? data.find(item => item.uuid === value) : value
	), [value, data]);

	return (
		<Autocomplete
			value={initialValue ?? _value}
			multiple={multiple}
			isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
			options={data.sort((a, b) => -b.name.localeCompare(a.name))}
			disableCloseOnSelect={multiple}
			limitTags={isMobile ? 1 : 3}
			loading={isLoading}
			getOptionLabel={(option) => option.name || ''}
			className='mimicSelect'
			onChange={(e, newValue) => {
				onChange('facilities', newValue);
			}}
			renderOption={(renderProps, option, { selected, inputValue }) => {
				const { key, ...optionProps } = renderProps;
				const matches = match(option.name, inputValue, { insideWords: true });
				const parts = parse(option.name, matches);

				return (
					<li key={key} {...optionProps}>
						{multiple && (
							<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
						)}
						{parts.map((part, index) => (
							<span key={index} style={{ fontWeight: part.highlight ? 700 : 400, whiteSpace: 'pre' }}>
								{part.text}
							</span>
						))}
					</li>
				);
			}}
			renderInput={(params) => (
				<TextField {...params} label={pluralize('Facility', multiple)} />
			)}
			{...props}
		/>
	);
}

export default FacilityAutocomplete;