import React from 'react';
import { Typography, Box } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

const GenericFailed = ({ message }) => {
	return (
		<Box display="flex" alignItems="center" p={2} bgcolor="error.main" color="error.contrastText">
			<ErrorIcon fontSize="large" />
			<Typography variant="body1" sx={{ ml: 2 }}>
				{message}
			</Typography>
		</Box>
	);
};

export default GenericFailed;