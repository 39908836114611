import { Accordion, AccordionDetails, AccordionSummary, Box, Chip, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Typography, useTheme } from "@mui/material";
import { ExpandMore, VideoLibrary, Close, Star, FeedbackOutlined } from '@mui/icons-material';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { useLocation } from 'react-router-dom';
import { useMemo } from "react";
import { useGate } from "~/hooks/useGate";
import UserFeedbackForm from "./UserFeedbackForm";

const videos = [
	{
		id: 1,
		title: 'Setting up for storms',
		url: 'https://www.loom.com/share/100c163a63624d71ae287eab5246f61e?sid=90e050e5-852f-411f-90dc-853cadca97ec',
		recomended: ['/manage/events', '/manage/facilities'],
		category: "management",
		gate: "tenant.manage.events.manage || tenant.manage.facilities.manage"
	},
	{
		id: 2,
		title: 'Automatic Parts Pricing Feature Walkthrough',
		url: 'https://www.loom.com/share/42a12c30dc2e420893616018a616c06d?sid=04294a86-aa65-4bd7-8605-4ad8455620f8',
		recomended: ["/estimates"],
		category: "estimating",
		gate: "tenant.apps.estimates.manage"
	},
	{
		id: 3,
		title: 'User Management Overview',
		url: 'https://www.loom.com/share/7915cbdcad8b445eb316bb965dc55559?sid=0d6a5eed-56b5-4eae-9f9e-09c6aef1a678',
		recomended: ["/manage/users"],
		category: "management",
		gate: "tenant.manage.users.manage"
	},
	{
		id: 4,
		title: 'Audit Feature Overview',
		url: 'https://www.loom.com/share/128c844f67c14b9baf32f62e589c6de3?sid=c290ac8c-5792-48e3-8812-52acf9fe9680',
		recomended: ["/audits"],
		category: "estimating",
		gate: "tenant.apps.audits.manage"
	},
];

const HelpCenterDrawer = ({
	open,
	toggleDrawer
}) => {
	const theme = useTheme();
	const location = useLocation();
	const { gate } = useGate();

	const filteredVideos = useMemo(() => {
		return videos
			.filter(video => gate(video.gate)) // Enforce the gate
			.sort((a, b) => {
				const aIsRecommended = a.recomended.some(path => location.pathname.startsWith(path));
				const bIsRecommended = b.recomended.some(path => location.pathname.startsWith(path));

				// Recommended videos should come first
				if (aIsRecommended && !bIsRecommended) return -1;
				if (!aIsRecommended && bIsRecommended) return 1;
				return 0; // If both are recommended or both aren't, maintain original order
			});
	}, [location.pathname, gate]);

	return (
		<Drawer
			anchor="right"
			open={open}
			onClose={() => toggleDrawer(false)}
			sx={{
				'& .MuiDrawer-paper': {
					[theme.breakpoints.up('md')]: {
						marginTop: `${theme.headerHeight}px`,
					}
				}
			}}
		>
			<Box
				component="div"
				sx={{
					padding: theme.spacing(2),
					width: {
						xs: '100vw',
						sm: '90vw',
					},
					maxWidth: 400,
					position: 'relative'
				}}
			>

				<IconButton
					sx={{ position: 'absolute', top: 8, right: 8 }}
					onClick={() => toggleDrawer(false)}
				>
					<Close />
				</IconButton>
				<Stack direction="row" spacing={1} alignItems="center" sx={{
					mb: 2
				}}>
					<HelpCenterIcon fontSize="large" />
					<Typography variant="h1" gutterBottom>
						Help Center
					</Typography>
				</Stack>

				<Typography variant="body1">Helpful Videos</Typography>

				<List>
					{filteredVideos.length > 0 ? (
						filteredVideos.map((video) => {
							const isRecommended = video.recomended.some(path => location.pathname.startsWith(path));

							return (
								<ListItemButton
									component="a"
									href={video.url}
									key={video.id}
									target="_blank"
									sx={{
										backgroundColor: isRecommended ? 'rgba(0, 123, 255, 0.1)' : 'transparent', // Highlight recommended
										borderLeft: isRecommended ? '4px solid #007bff' : 'none', // Optional left border for recommended
										'&:hover': {
											backgroundColor: isRecommended ? 'rgba(0, 123, 255, 0.2)' : 'rgba(0, 0, 0, 0.04)'
										}
									}}
								>
									{/* Conditionally render the star icon for recommended videos */}
									{isRecommended ? <Star sx={{ mr: 1, color: '#007bff' }} /> : <VideoLibrary sx={{ mr: 1 }} />}

									<ListItemText
										primary={video.title}
										secondary={(
											<Chip
												label={video.category}
												color="primary"
												size="small"
												sx={{ borderRadius: '8px', mt: 1 }}
											/>
										)}
									/>
								</ListItemButton>
							);
						})
					) : (
						<Typography variant="body2">No videos available at the moment.</Typography>
					)}
				</List>

				<Divider sx={{ my: 2 }} />

				<Accordion>
					<AccordionSummary expandIcon={<ExpandMore />}>
						<FeedbackOutlined sx={{ mr: 1 }} />
						<Typography variant="body1">Leave Feedback</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<UserFeedbackForm />
					</AccordionDetails>
				</Accordion>
			</Box>
		</Drawer>
	);
}

export default HelpCenterDrawer;