import CRUDEntityPage from '~/components/CRUD/CRUDEntityPage';
import { eventQuery } from '~/loaders/eventsLoader';
import EventForm from '~/pages/forms/crud/EventForm';
import { restoreEvent, updateEvent } from '~/requests/events';

const EventEditPage = () => {

	return <CRUDEntityPage
		entityLabel="Event"
		entityQuery={eventQuery}

		updateRequest={updateEvent}
		restoreRequest={restoreEvent}

		queryKey={'events'}
		entityForm={EventForm}
	/>;
};

export default EventEditPage;
