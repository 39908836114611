import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from "react";
import { useIsMounted } from "usehooks-ts";
import { getWindow } from "../helpers/windowHelper";
import { getCsrfCookie, getProfile, login as loginRequest, logout as logoutRequest, } from "../requests/auth";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import Loader from '~/components/Loader';

// context setup
const AuthContext = React.createContext({
	authenticated: false,
	refreshProfile: () => { },
	user: {
		id: null,
		name: null,
		email: null,
		usertype: null,
		is_employee: null,
		created_at: null,
		updated_at: null,
	},
	login: () => { },
	logout: () => { },
});
export const __AuthContext = AuthContext;

export const AuthProvider = ({
	children
}) => {

	const [isLoaded, setIsLoaded] = useState(false);
	const [authenticated, setAuthenticated] = useState(false);
	const [user, setUser] = useState(null);
	const isMounted = useIsMounted()

	const { data: queryUser } = useQuery({
		queryKey: ['profile'],
		queryFn: queryFunctionHelper(getProfile),
		enabled: authenticated
	});

	useEffect(() => {
		if (queryUser) {
			setUser(queryUser);
		}
	}, [queryUser]);

	const login = ({ data, onSuccess = null, onFailure = null, onFinish = null }) => {
		getCsrfCookie()
			.catch((err) => {
				console.error(err);
				onFailure?.('Failed to login. Please try again.');
				setAuthenticated(false);
			})
			.then(response =>
				loginRequest(data)
					.then(response => {
						onSuccess?.();
						setAuthenticated(true);
						setUser(response.data.data);
					})
					.catch((err) => {
						console.error(err.response);
						onFailure?.(err.response?.data?.data?.message);
						setAuthenticated(false);
					})
			)
			.finally(() => onFinish?.());
	};

	const logout = async (tellApi = true) => {
		if (tellApi) await logoutRequest();
		setAuthenticated(false);
	};

	const checkAuthenticated = useCallback(() => {
		getProfile().then(response => {
			setAuthenticated(true);
			setUser(response.data.data);
		}).catch(err => {
			setAuthenticated(false);
		}).finally(() => {
			setIsLoaded(true);
		});
	}, []);


	useEffect(() => {
		if (isMounted()) checkAuthenticated();
	}, [isMounted, checkAuthenticated])

	getWindow().auth.logout = logout;

	return (
		<AuthContext.Provider
			value={{ authenticated, refreshProfile: checkAuthenticated, login, logout, user }}
		>
			{(isLoaded) && children}
			{!isLoaded && <Loader />}
		</AuthContext.Provider>
	);
};

AuthProvider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]).isRequired,
}
