import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import S from 'string';
import * as Sentry from "@sentry/react";
import config from './config';
import {
	useLocation,
	useNavigationType,
	createRoutesFromChildren,
	matchRoutes,
} from "react-router-dom";
import { validateMuiLicense } from './MuiLicense';


Sentry.init({
	dsn: "https://3e7cb0c8c524773abf7df2e0ac45e6fa@o4506371737911296.ingest.us.sentry.io/4506371743088640",
	enabled: !config.envIsLocalOrDev,
	environment: config.app.environment,
	integrations: [
		// See docs for support of different versions of variation of react router
		// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
		Sentry.reactRouterV6BrowserTracingIntegration({
			useEffect: React.useEffect,
			useLocation,
			useNavigationType,
			createRoutesFromChildren,
			matchRoutes
		}),
		Sentry.browserProfilingIntegration(),
		Sentry.captureConsoleIntegration({
			levels: ['error', 'warn']
		}),
		Sentry.replayIntegration(),
		Sentry.httpClientIntegration(),
		Sentry.sessionTimingIntegration()
	],

	// Tracing
	tracesSampleRate: 0.5, //  Capture 100% of the transactions
	// Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
	tracePropagationTargets: ["localhost", /^https:\/\/api\.portal\.phms\.com\.au\/api/],

	// Set profilesSampleRate to 1.0 to profile every transaction.
	// Since profilesSampleRate is relative to tracesSampleRate,
	// the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
	// For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
	// results in 25% of transactions being profiled (0.5*0.5=0.25)
	profilesSampleRate: 1.0,
	// Session Replay
	replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
	replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// LicenseInfo.setLicenseKey('065c6120835e26f3f69ac9c36747324aTz04Nzc5NSxFPTE3NDM4MzQ5MDkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

const root = ReactDOM.createRoot(document.getElementById('root'));
S.extendPrototype();

validateMuiLicense();
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
