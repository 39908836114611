import { Button, Card, CardActions, CardContent } from "@mui/material";
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import { getMediaLibraryProps } from "~/helpers/mediaLibraryHelper";
import '~/assets/styles/media-library.scss';
import React, { useCallback, useEffect, useRef, useState } from "react";
import { usePrevious } from "~/hooks/usePrevious";
import { Field } from "formik";
import { useForm } from "~/hooks/useForm";
import PhotosDropzone from "~/components/PhotosDropzone/PhotosDropzone";
import Camera from "~/components/Camera/Camera";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import { base64ToFile } from "~/helpers/stringHelpers";

const PagePhotos = () => {

	const photosDropdownRef = useRef();
	const [isReadyToSubmit, setIsReadyToSubmit] = useState(true);
	const previousIsReadyToSubmit = usePrevious(isReadyToSubmit);

	const { submitForm, isCompleted, submission } = useForm();

	useEffect(() => {
		if (previousIsReadyToSubmit === false && isReadyToSubmit === true) {
			// ready to upload 
			submitForm();
		}
	}, [isReadyToSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

	const handleSave = useCallback((photos, onFinish) => {
		const dataTransfer = new DataTransfer();

		photos.forEach((image, index) => {
			const file = base64ToFile(image.src, `photo-${submission.summary.rego}-${image.key}.jpg`);
			dataTransfer.items.add(file);
		});

		const fileInput = document.querySelector('.media-library-uploader input[type="file"]');

		if (fileInput) {
			fileInput.files = dataTransfer.files;

			// Manually trigger a change event if needed
			const event = new Event('change', { bubbles: true });
			fileInput.dispatchEvent(event);
		}

		onFinish?.();
	}, []);


	return (
		<EstimateFormPage>
			<PageTitle>Photos</PageTitle>

			<Card>
				<CardContent>
					<Field name="photos">
						{({ field, form, meta }) => (
							<PhotosDropzone
								ref={photosDropdownRef}
								name={field.name}
								initialValue={field.value}
								onChange={(photos) => form.setFieldValue(field.name, photos)}
								validationRules={{
									accept: ["image/*"],
									maxSizeInKB: 1024 * 15,
								}}
								sortable={false}
								onIsReadyToSubmitChange={setIsReadyToSubmit}
								disabled={isCompleted}
								onDelete={() => {
									submitForm();
								}}
								{...getMediaLibraryProps()}
							/>
						)}
					</Field>



				</CardContent>
				<CardActions>
					{!isCompleted && (
						<>
							<Button size="small" onClick={() => {
								photosDropdownRef.current?.clear?.();
							}}>Remove All Photos</Button>
							<Camera onSave={handleSave}>
								{({ open }) => (
									<Button sx={{ ml: 2 }} startIcon={<CameraAltIcon />} onClick={open}>Open Camera</Button>
								)}
							</Camera>
						</>
					)}
				</CardActions>
			</Card>
		</EstimateFormPage>
	);
};

export default PagePhotos;
