import { useContext, useMemo } from 'react';
import { __PermissionContext } from "~/providers/PermissionProvider";
import { abilitiesTemplate } from '~/data/abilitiesTemplate';

export function useGate() {

	const { gate, abilities, deriveTemplate } = useContext(__PermissionContext);
	const client = useMemo(() => abilities.client, [abilities])


	return {
		/**
		 * @param {(boolean|string|string[])} expression_or_abilitys
		 */
		gate,
		abilities,
		client,
		deriveTemplate,
		abilitiesTemplate
	};
}
