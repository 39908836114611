import apiHandler from "../apiHandler";

export const searchFacilities = (term, signal = undefined) => apiHandler({
	method: "get",
	url: "facilities/search",
	dataOrParams: {
		q: term
	},
	signal
});

export const getFacilities = (params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "facilities",
	dataOrParams: params,
	signal
});

export const getFacility = (uuid, signal = undefined) => apiHandler({
	method: "get",
	url: "facilities/" + uuid,
	signal
});

export const getFacilityTimeSlots = (uuid, params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "facilities/" + uuid + "/slots",
	dataOrParams: params,
	signal
});

export const createFacility = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `facilities`,
	dataOrParams: params,
	signal
});

export const updateFacility = (id, params = {}, signal = null) => apiHandler({
	method: "put",
	url: `facilities/${id}`,
	dataOrParams: params,
	signal
});

export const deleteFacility = (id, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `facilities/${id}`,
	dataOrParams: params,
	signal
});

export const restoreFacility = (id, params = {}, signal = null) => apiHandler({
	method: "post",
	url: `facilities/${id}/restore`,
	dataOrParams: params,
	signal
});