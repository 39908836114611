import AddIcon from '@mui/icons-material/Add';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Box, Button, Checkbox, Collapse, FormControlLabel, IconButton, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { FieldArray, useField } from 'formik';
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import FormTitleBar from './Forms/FormTitleBar';

export const ListContext = createContext({});

const CustomList = {};

CustomList.Main = ({
	children,
	headers = [],
	values = [],
	baseItems = [],
	mergeKey = 'key',
	name,
	allowAdd = null,
	newLabel = "New Item",
	readOnly = false,
	...rest
}) => {

	const [field] = useField(name);

	const isArrayValue = Array.isArray(field.value);

	const selected = useMemo(() => {
		if (!isArrayValue) {
			return 0;
		}

		return field.value.length;
	}, [field.value, isArrayValue]);

	return (
		<FieldArray name={name}>
			{(arrayHelpers) => (
				<ListContext.Provider value={{
					headers,
					arrayHelpers,
					name,
					selected,
					isArrayValue,
					allowAdd: readOnly ? false : !!allowAdd,
					addDataFn: allowAdd,
					newLabel,
					list: values,
					mergeKey,
					values,
					baseItems,
					readOnly,
				}}>
					<div {...rest}>
						{children}
					</div>
				</ListContext.Provider>

			)}
		</FieldArray>
	);
};

CustomList.Main.displayName = 'CustomList';

const ContextCheck = () => {

	const context = React.useContext(ListContext)
	if (context === undefined) {
		throw new Error('ListContext children components must be used within a CustomList Component')
	}
}

CustomList.Title = ({ children, ...rest }) => {
	ContextCheck();
	const { selected, isArrayValue } = useContext(ListContext);

	return (
		<FormTitleBar title={children} count={isArrayValue ? selected : null} />
	);
};

CustomList.Title.displayName = 'CustomListTitle';

CustomList.List = ({ children, ...rest }) => {
	ContextCheck();
	const { headers, allowAdd, addDataFn, newLabel, arrayHelpers, list } = useContext(ListContext);


	const handleAdd = useCallback(() => {
		addDataFn(arrayHelpers, list);
	}, [addDataFn, arrayHelpers, list]);

	return (
		<TableContainer>
			<Table size="small" {...rest}>

				<TableHead>
					<TableRow>
						{headers.map((header) => {
							const [headerName, headerWidth = null] = header;

							return (
								<TableCell key={headerName} align={headerWidth ? "center" : "left"} sx={{
									border: 0,
									width: `${headerWidth}%`,
								}}>
									<Typography sx={{
										fontWeight: "bold",
										textTransform: "uppercase",
									}}>
										{headerName}
									</Typography>
								</TableCell>
							)
						})}
					</TableRow>
				</TableHead>

				<TableBody>
					{children instanceof Function ? children(list) : children}
				</TableBody>
				<TableFooter>
					{allowAdd && (
						<TableRow>
							<TableCell colSpan={headers.length}>
								<Button variant="outlined" size="large" onClick={handleAdd} fullWidth startIcon={<AddIcon />}>{newLabel}</Button>
							</TableCell>
						</TableRow>
					)}
				</TableFooter>
			</Table>
		</TableContainer>
	);
};

CustomList.List.displayName = 'CustomListList';

CustomList.Group = ({ children = null, heading = "", expanded = null, defaultExpanded = false, ...rest }) => {
	ContextCheck();
	const { headers } = useContext(ListContext);

	const [open, setOpen] = useState(defaultExpanded);
	useEffect(() => {
		if (expanded !== null) {
			setOpen(expanded);
		}
	}, [expanded]);

	return (
		<>
			<CustomList.GroupHeading expandable={() => setOpen(prev => !prev)} expanded={open}>{heading instanceof Function ? heading() : heading}</CustomList.GroupHeading>
			<TableRow>
				<TableCell colSpan={headers.length} padding='none'>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Table size="small">
							<TableHead>
								<TableRow>
									{headers.map((header) => {
										const [headerName, headerWidth = null] = header;

										return (
											<TableCell key={headerName} align={headerWidth ? "center" : "left"} sx={{
												border: 0,
												width: `${headerWidth}%`,
											}}>

											</TableCell>
										)
									})}
								</TableRow>
							</TableHead>

							<TableBody>
								{children instanceof Function ? children() : children}
							</TableBody>
						</Table>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
};

CustomList.Group.displayName = 'CustomListGroup';

CustomList.GroupHeading = ({ children = null, expandable = null, expanded = false, ...rest }) => {
	ContextCheck();
	const { headers } = useContext(ListContext);

	return (
		<TableRow {...rest} sx={{
			backgroundColor: "#f4f4f4",
		}}>
			<TableCell colSpan={headers.length}>
				<Box sx={{
					pl: 1,
					display: "flex",
					alignItems: "center",
					cursor: expandable ? "pointer" : "default",
				}}
					onClick={expandable}
				>

					<Typography variant="body1" sx={{
						textTransform: "uppercase",
						flexGrow: 1,
					}}>
						{children}
					</Typography>
					{expandable !== null && (
						<IconButton
							size="small"

						>
							{expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
						</IconButton>
					)}
				</Box>
			</TableCell>
		</TableRow>
	);
};

CustomList.GroupHeading.displayName = 'CustomListGroupHeading';

CustomList.ListItem = ({ children, value = {}, ...rest }) => {
	ContextCheck();

	const { name, mergeKey, allowAdd, values, baseItems, isArrayValue } = useContext(ListContext);
	const [field] = useField(name);

	const formikIndex = useMemo(() => {
		if (!field.value) {
			return null;
		}

		if (!isArrayValue) {
			return null;
		}

		const index = (field.value || []).findIndex((item) => item?.[mergeKey] === value[mergeKey]);

		return index === -1 ? null : index;
	}, [field.value, mergeKey, value, isArrayValue]);

	// chekc if item.key exists in values
	const isVirtual = useMemo(() => {
		if (!isArrayValue) {
			return false;
		}

		return !!(field.value || []).find((item) => item?.[mergeKey] === value[mergeKey] && !baseItems.find((baseItem) => baseItem?.[mergeKey] === value[mergeKey]));

	}, [isArrayValue, field.value, mergeKey, value, baseItems]);


	return (
		<TableRow {...rest}>
			{children instanceof Function ? children({ index: formikIndex, allowAdd, isVirtual }) : children}
		</TableRow>
	);
};

CustomList.ListItem.displayName = 'CustomListItem';

CustomList.ListColumn = ({ children, center = false, ...rest }) => {
	ContextCheck();

	return (
		<TableCell sx={{
			textAlign: center ? "center" : null
		}} {...rest}>
			<div style={{
				display: "flex",
				alignItems: "center",
				gap: 8,
			}}>
				{children}
			</div>
		</TableCell>
	);
};
CustomList.ListColumn.displayName = 'CustomListColumn';

CustomList.Checkbox = ({ value, label = '', disabled = false }) => {
	ContextCheck();

	const { arrayHelpers, name, mergeKey, readOnly } = useContext(ListContext);
	const [field] = useField(name);

	const formikIndex = useMemo(() => (field.value || []).findIndex((item) => item?.[mergeKey] === value[mergeKey]), [field.value, mergeKey, value]);
	const checked = useMemo(() => formikIndex !== -1, [formikIndex]);

	const handleChange = useCallback((event) => {
		if (event.target.checked) {
			arrayHelpers.push({ ...value });
		} else {
			arrayHelpers.remove(formikIndex)
		}
	}, [arrayHelpers, value, formikIndex]);

	return (
		<FormControlLabel label={label} control={
			<Checkbox
				checked={checked}
				inputProps={{ 'aria-label': 'controlled' }}
				disabled={value?.disabled}
				onChange={readOnly ? undefined : handleChange}
			/>
		}
		/>
	);
};
CustomList.Checkbox.displayName = 'CustomListCheckbox';



export default CustomList;