import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useFormikContext, getIn } from 'formik';
import _debounce from 'lodash.debounce';

const zipArrays = (keysArray, valuesArray) => Object.fromEntries(keysArray.map((value, index) => [value, valuesArray[index]]));


export function useFormEffect(watching = [], callback = null) {
	const formik = useFormikContext();
	const { values } = formik;

	const watchingValues = useMemo(() => watching.map((key) => getIn(values, key)), [values, watching]);
	const callbackRef = useRef(callback);
	const isFirstRender = useRef(true); // Track initial render
	const prevWatchingValuesRef = useRef(watchingValues);

	useEffect(() => {
		callbackRef.current = callback;
	}, [callback]);

	const handleDebounceFn = useCallback(() => {
		callbackRef.current?.(zipArrays(watching, watchingValues), formik);
	}, [formik, watching, watchingValues]);

	const debounceCallback = useCallback(_debounce(handleDebounceFn, 200), [handleDebounceFn]);

	useEffect(() => {
		// Skip the callback on the first render
		if (isFirstRender.current) {
			isFirstRender.current = false;
			return;
		}

		if (!prevWatchingValuesRef.current.every((value, index) => value === watchingValues[index])) {
			debounceCallback();
		}

		prevWatchingValuesRef.current = watchingValues;
	}, [debounceCallback, watchingValues]);

	return formik;
}