import { FormControl, FormHelperText, InputLabel, MenuItem } from '@mui/material'
import { states } from '~/form/estimate/data/states'
import Select from '@mui/material/Select'

const StateDropdown = ({ field, form, label, fullWidth = false, ...props }) => {
	const { name } = field;
	const { touched, errors } = form;

	return (
		<FormControl fullWidth={fullWidth} error={touched[name] && errors[name] ? true : false} {...props}>
			<InputLabel id={`${name}-label`}>{label}</InputLabel>
			<Select
				defaultValue=""
				labelId={`${name}-label`}
				label={label}
				{...field}
				{...props}
			>
				{states.map((item, i) => (
					<MenuItem key={i} value={item.value}>{item.label}</MenuItem>
				))}
			</Select>
			{touched[name] && errors[name] ? (
				<FormHelperText error>{errors[name]}</FormHelperText>
			) : null}
		</FormControl>
	)
}

export default StateDropdown