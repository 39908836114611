
import EventIcon from '@mui/icons-material/Event';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { getIn } from 'formik';

import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { createErrorHandler } from './errorHandler';

function fieldToDatePicker(props, isTouchDevice) {
	const {
		field: { onChange: _onChange, ...field },
		form: { isSubmitting, touched, errors, setFieldValue, setFieldError, setFieldTouched },
		textField: { helperText, onBlur, ...textField } = {},
		disabled,
		label,
		onChange,
		onError,
		slotProps,
		...rest } = props;

	const fieldError = getIn(errors, field.name);
	const showError = getIn(touched, field.name) && !!fieldError;

	return {
		slotProps: {
			textField: {
				error: showError,
				helperText: showError ? fieldError : helperText,
				clearable: true,
				onBlur: onBlur ??
					function () {
						setFieldTouched(field.name, true, true);
					},
				...textField,
			},
			...slotProps
		},
		disabled: disabled ?? isSubmitting,
		onChange:
			onChange ??
			function (date) {
				// Do not switch this order, otherwise you might cause a race condition
				// See https://github.com/formium/formik/issues/2083#issuecomment-884831583
				setFieldTouched(field.name, true, false);
				setFieldValue(field.name, date.toISO(), true);
			},
		onError: onError ?? createErrorHandler(fieldError, field.name, setFieldError),
		label,
		...field,
		...rest,
		value: field.value ? DateTime.fromISO(field.value) : null,
	};
}

export function DatePicker({ ...props }) {
	const isTouchDevice = useMemo(() => {
		return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
	}, []);
	return <MuiDatePicker {...fieldToDatePicker(props, isTouchDevice)} />;
}
