export const panelsBase = (bodyTypeKey = null) => {
	const panels = [
		{
			name: "Bonnet",
			key: "bonnet",
			requires_body_type: [],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd',
			blends_panels: ['l_h_f_guard', 'r_h_f_guard'],
			blends_rr: ['front_bumper', 'fittings_l_h_f_guard', 'fittings_r_h_f_guard']
		},
		{
			name: "R/H/F Guard",
			key: "r_h_f_guard",
			requires_body_type: [],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['bonnet', 'r_h_f_door'],
			blends_rr: ['fittings_bonnet', 'front_bumper', 'fittings_r_h_f_door', 'right_head_light']
		},
		{
			name: "R/H/F Door",
			key: "r_h_f_door",
			requires_body_type: [],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['r_h_f_guard', 'r_h_r_door'],
			blends_rr: ['fittings_r_h_f_guard', 'front_bumper', 'fittings_r_h_r_door']
		},
		{
			name: "R/H/R Door",
			key: "r_h_r_door",
			requires_body_type: ['coupe', 'sedan', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', '4dr_ute', 'minivan', 'truck', 'commercial'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['r_h_f_door', 'r_h_r_quarter', 'r_h_ute_bside'],
			blends_rr: ['fittings_r_h_f_door', 'fittings_r_h_r_quarter', 'right_tail_light', 'rear_bumper', 'fittings_r_h_bside']
		},
		{
			name: "R/H/R QTR",
			key: "r_h_r_quarter",
			requires_body_type: ['sedan', 'coupe', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', 'minivan'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['r_h_r_door', 'bootlid', 'r_h_cant_rail'],
			blends_rr: ['fittings_r_h_r_door', 'fittings_bootlid', 'fittings_l_h_cant_rail']
		},
		{
			name: "Bootlid",
			key: "bootlid",
			requires_body_type: ['sedan', 'coupe'], //'coupe', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', 'minivan', 'truck', 'commercial'
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd',
			blends_panels: ['l_h_r_quarter', 'r_h_r_quarter', 'l_h_cant_rail', 'r_h_cant_rail'],
			blends_rr: ['fittings_l_h_r_quarter', 'left_tail_light', 'fittings_r_h_r_quarter', 'right_tail_light', 'rear_bumper', 'fittings_l_h_cant_rail', 'fittings_r_h_cant_rail']
		},
		{
			name: "T/Gate",
			key: "tgate",
			requires_body_type: ['5dr_hatchback', '3dr_hatchback', 'station_wagon', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['l_h_r_quarter', 'r_h_r_quarter', 'l_h_cant_rail', 'r_h_cant_rail', 'l_h_ute_bside', 'r_h_ute_bside'],
			blends_rr: ['fittings_l_h_r_quarter', 'left_tail_light', 'fittings_r_h_r_quarter', 'right_tail_light', 'rear_bumper', 'fittings_l_h_cant_rail', 'fittings_r_h_cant_rail', 'fittings_l_h_bside', 'fittings_r_h_bside']
		},
		{
			name: "T/Gate Upper",
			key: "tgate_upper",
			requires_body_type: ['5dr_hatchback', '3dr_hatchback', 'station_wagon', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['l_h_r_quarter', 'r_h_r_quarter', 'l_h_cant_rail', 'r_h_cant_rail', 'l_h_ute_bside', 'r_h_ute_bside'],
			blends_rr: ['fittings_l_h_r_quarter', 'left_tail_gate_tail_light', 'fittings_r_h_r_quarter', 'right_tail_gate_tail_light', 'rear_bumper', 'fittings_l_h_cant_rail', 'fittings_r_h_cant_rail', 'fittings_l_h_bside', 'fittings_r_h_bside']
		},
		{
			name: "T/Gate Lower",
			key: "tgate_lower",
			requires_body_type: ['5dr_hatchback', '3dr_hatchback', 'station_wagon', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['l_h_r_quarter', 'r_h_r_quarter', 'l_h_cant_rail', 'r_h_cant_rail', 'l_h_ute_bside', 'r_h_ute_bside'],
			blends_rr: ['fittings_l_h_r_quarter', 'left_tail_gate_tail_light', 'fittings_r_h_r_quarter', 'right_tail_gate_tail_light', 'rear_bumper', 'fittings_l_h_cant_rail', 'fittings_r_h_cant_rail', 'fittings_l_h_bside', 'fittings_r_h_bside']
		},
		{
			name: "L/H/R QTR",
			key: "l_h_r_quarter",
			requires_body_type: ['sedan', 'coupe', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', 'minivan'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['l_h_r_door', 'bootlid', 'l_h_cant_rail'],
			blends_rr: ['fittings_l_h_r_door', 'fittings_bootlid', 'fittings_l_h_cant_rail']
		},
		{
			name: "L/H/R Door",
			key: "l_h_r_door",
			requires_body_type: ['coupe', 'sedan', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', '4dr_ute', 'minivan', 'truck', 'commercial'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['l_h_f_door', 'l_h_r_quarter', 'l_h_ute_bside'],
			blends_rr: ['fittings_l_h_f_door', 'fittings_l_h_r_quarter', 'left_tail_light', 'rear_bumper', 'fittings_l_h_bside']
		},
		{
			name: "L/H/F Door",
			key: "l_h_f_door",
			requires_body_type: [],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['l_h_f_guard', 'l_h_r_door'],
			blends_rr: ['front_bumper', 'fittings_l_h_f_guard', 'fittings_l_h_r_door',]
		},
		{
			name: "L/H/F Guard",
			key: "l_h_f_guard",
			requires_body_type: [],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['bonnet', 'l_h_f_door'],
			blends_rr: ['fittings_bonnet', 'front_bumper', 'fittings_l_h_f_door', 'left_head_light']
		},

		{
			name: "Turret Coupe",
			key: "turret_coupe",
			requires_body_type: ['coupe'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret Sedan",
			key: "turret_sedan",
			requires_body_type: ['sedan'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret Hatchback",
			key: "turret_hatchback",
			requires_body_type: ['3dr_hatchback', '5dr_hatchback'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret Wagon",
			key: "turret_wagon",
			requires_body_type: ['station_wagon'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret 4x4",
			key: "turret_4x4",
			requires_body_type: ['4x4'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret Ute",
			key: "turret_ute",
			requires_body_type: ['2dr_ute', '4dr_ute'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret Minivan",
			key: "turret_minivan",
			requires_body_type: ['minivan'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret Truck",
			key: "turret_truck",
			requires_body_type: ['truck'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "Turret Commercial",
			key: "turret_commercial",
			requires_body_type: ['commercial'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'd'
		},
		{
			name: "L/H Cant Rail",
			key: "l_h_cant_rail",
			requires_body_type: [],
			group: ['common'],
			can_replace: false,
			values: {},
			panel_group: 'b',
			blends_panels: ['l_h_f_screen_pillar', 'l_h_r_quarter', 'l_h_ute_bside'],
			blends_rr: ['fittings_l_h_r_quarter', 'left_tail_light', 'rear_bumper', 'fittings_l_h_bside']
		},
		{
			name: "R/H Cant Rail",
			key: "r_h_cant_rail",
			requires_body_type: [],
			group: ['common'],
			can_replace: false,
			values: {},
			panel_group: 'b',
			blends_panels: ['r_h_f_screen_pillar', 'r_h_r_quarter', 'l_h_ute_bside'],
			blends_rr: ['fittings_r_h_r_quarter', 'right_tail_light', 'rear_bumper', 'fittings_r_h_bside']
		},
		{
			name: "L/H Ute B'side",
			key: "l_h_ute_bside",
			requires_body_type: ['2dr_ute', '4dr_ute'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['l_h_f_door', 'tgate', 'l_h_cant_rail'],
			blends_rr: ['fittings_l_h_f_door', 'fittings_tgate', 'fittings_l_h_cant_rail']
		},
		{
			name: "R/H Ute B'side",
			key: "r_h_ute_bside",
			requires_body_type: ['2dr_ute', '4dr_ute'],
			group: ['common'],
			can_replace: true,
			values: {},
			panel_group: 'a',
			blends_panels: ['r_h_f_door', 'tgate', 'r_h_cant_rail'],
			blends_rr: ['fittings_r_h_f_door', 'fittings_tgate', 'fittings_r_h_cant_rail']
		},
		{
			name: "Plenum Grille",
			key: "plenum_grille",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: true,
			values: {},
			panel_group: 'a'
		},
		{
			name: "Front Apron",
			key: "front_apron",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: true,
			values: {},
			panel_group: 'a'
		},
		{
			name: "L/F Screen Pillar",
			key: "l_h_f_screen_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "L/F/U Door Frame",
			key: "l_h_f_door_upper_frame",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "L/H Center Pillar",
			key: "l_h_center_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "L/R/U Door Frame",
			key: "l_h_r_door_upper_frame",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "L/H/R Cab Pillar",
			key: "l_h_r_cab_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "L/H/R QTR Panel Pillar",
			key: "l_h_r_quarter_panel_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "R/H/F Screen Pillar",
			key: "r_h_f_screen_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "R/F/U Door Frame",
			key: "r_h_f_door_upper_frame",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "R/R/U Door Frame",
			key: "r_h_r_door_upper_frame",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "R/H Centre Pillar",
			key: "r_h_centre_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "R/H/R Cab Pillar",
			key: "r_h_r_cab_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "R/H/R QTR Panel Pillar",
			key: "r_h_r_quarter_panel_pillar",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'a'
		},
		{
			name: "Fuel Filler Flap",
			key: "fuel_filler_flap",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: true,
			values: {},
			panel_group: 'a'
		},
		{
			name: "Rear Cab Panel",
			key: "rear_cab_panel",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: true,
			values: {},
			panel_group: 'a'
		},
		{
			name: "Catwalk Panel",
			key: "catwalk_panel",
			requires_body_type: [],
			group: ['uncommon'],
			can_replace: false,
			values: {},
			panel_group: 'd'
		},
		{
			name: "T/Gate/L Barndoor",
			key: "tgate_left_barndoor",
			requires_body_type: ['5dr_hatchback', '3dr_hatchback', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
			group: ['uncommon'],
			can_replace: true,
			values: {},
			panel_group: 'a'
		},
		{
			name: "T/Gate/R Barndoor",
			key: "tgate_right_barndoor",
			requires_body_type: ['5dr_hatchback', '3dr_hatchback', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
			group: ['uncommon'],
			can_replace: true,
			values: {},
			panel_group: 'a'
		},
	];

	if (bodyTypeKey) {
		return panels.filter((panel) => {
			return panel.requires_body_type.length ? panel.requires_body_type.includes(bodyTypeKey) : true;
		});
	}
	return panels;
};

export const baseRRItems = (bodyTypeKey = null) => {
	const items = [
		{
			_group: true,
			name: "Trims",
		},
		{
			_requires_body_type: ['coupe'],
			_review_label_override: 'PDR Coupe Trims and Fittings',
			trims: true,
			key: "coupe",
			label: "Coupe",
			qty: 1,
		},
		{
			_requires_body_type: ['sedan'],
			_review_label_override: 'PDR Sedan Trims and Fittings',
			trims: true,
			key: "sedan",
			label: "Sedan",
			qty: 1,
		},
		{
			_requires_body_type: ['station_wagon', '3dr_hatchback', '5dr_hatchback'],
			_review_label_override: 'PDR Wagon/Hatchback Trims and Fittings',
			trims: true,
			key: "hatch_wagon",
			label: "Hatch / Wagon",
			qty: 1,
		},
		{
			_requires_body_type: ['4x4'],
			_review_label_override: 'PDR SUV Trims and Fittings',
			trims: true,
			key: "4wd",
			label: "4WD",
			qty: 1,
		},
		{
			_requires_body_type: ['2dr_ute'],
			_review_label_override: 'PDR UTE Trims and Fittings',
			trims: true,
			key: "2dr_ute",
			label: "2dr Ute",
			qty: 1,
		},
		{
			_requires_body_type: ['4dr_ute'],
			_review_label_override: 'PDR UTE Trims and Fittings',
			trims: true,
			key: "4dr_ute",
			label: "4dr Ute",
			qty: 1,
		},
		{
			_requires_body_type: [],
			_review_label_override: 'PDR Trims and Fittings',
			trims: true,
			key: "euro_complex",
			label: "Euro/Complex",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "new_turret_partial_trims",
			label: "New Turret Partial Trims",
			_review_label_override: 'PDR Trims and Fittings - Partial',
			trims: false, // no need to specially handle this one 
			qty: 1,
		},
		{
			_group: true,
			name: "Glass",
		},
		{
			_requires_body_type: [],
			_hidden_from_review: true,
			key: "front_screen",
			label: "Front Screen",
			price: null,
			qty: 1,
		},
		{
			_requires_body_type: [],
			_hidden_from_review: true,
			key: "rear_screen",
			label: "Rear Screen",
			price: null,
			qty: 1,
		},
		{
			_requires_body_type: [],
			_hidden_from_review: true,
			key: "left_glass_quarter_panel",
			label: "L/H 1/4 Glass",
			price: null,
			qty: 1,
		},
		{
			_requires_body_type: [],
			_hidden_from_review: true,
			key: "right_glass_quarter_panel",
			label: "R/H 1/4 Glass ",
			price: null,
			qty: 1,
		},
		{
			_group: true,
			name: "Fittings",
		},
		{
			_requires_body_type: [],
			key: "fittings_bonnet",
			label: "Bonnet Fittings",
			linkedPanel: 'bonnet',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_l_h_f_door",
			label: "L/H/F Door Fittings",
			linkedPanel: 'l_h_f_door',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_r_h_f_door",
			label: "R/H/F Door Fittings",
			linkedPanel: 'r_h_f_door',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_r_h_r_door",
			label: "R/H/R Door Fittings",
			linkedPanel: 'r_h_r_door',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_l_h_r_door",
			label: "L/H/R Door Fittings",
			linkedPanel: 'l_h_r_door',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_l_h_f_guard",
			label: "L/H/F Guard Fittings",
			linkedPanel: 'l_h_f_guard',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_r_h_f_guard",
			label: "R/H/F Guard Fittings",
			linkedPanel: 'r_h_f_guard',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: ['sedan', 'coupe', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', 'minivan'],
			key: "fittings_l_h_r_quarter",
			label: "L/H/R Quarter Inner Trims",
			linkedPanel: 'l_h_r_quarter',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: ['sedan', 'coupe', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', 'minivan'],
			key: "fittings_r_h_r_quarter",
			label: "R/H/R Quarter Inner Trims",
			linkedPanel: 'r_h_r_quarter',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: ['sedan', 'coupe'],
			key: "fittings_bootlid",
			label: "Bootlid Fittings",
			linkedPanel: 'bootlid',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_l_h_cant_rail",
			label: "L/H Cant Rail Fittings",
			linkedPanel: 'l_h_cant_rail',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: [],
			key: "fittings_r_h_cant_rail",
			label: "R/H Cant Rail Fittings",
			linkedPanel: 'r_h_cant_rail',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			key: "fittings_tgate",
			label: "Tail gate inc Fittings",
			linkedPanel: 'tgate',
			qty: 1,
			exportsAs: 'conventional',
			_requires_body_type: ['5dr_hatchback', '3dr_hatchback', 'station_wagon', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
		},
		{
			_requires_body_type: [],
			key: "tailgate_app_fittings",
			label: "Tailgate App Fittings",
			qty: 1,
		},
		{
			_requires_body_type: ['4dr_ute', '2dr_ute', 'truck'],
			key: "fittings_l_h_bside",
			label: "L/H B'side Fittings",
			linkedPanel: 'l_h_ute_bside',
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			_requires_body_type: ['4dr_ute', '2dr_ute', 'truck'],
			key: "fittings_r_h_bside",
			label: "R/H B'side Fittings",
			linkedPanel: 'r_h_ute_bside',
			qty: 1,
			exportsAs: 'conventional'
		},

		{
			_group: true,
			name: "Body",
		},
		{
			key: "front_bumper",
			label: "Front Bumper",
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			key: "rear_bumper",
			label: "Rear Bumper",
			qty: 1,
			exportsAs: 'conventional'
		},

		{
			_group: true,
			name: "Moulds",
		},
		{
			key: "l_h_turret_mould",
			label: "L/H Turret Mould",
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			key: "r_h_turret_mould",
			label: "R/H Turret Mould",
			qty: 1,
			exportsAs: 'conventional'
		},

		{
			_group: true,
			name: "Lights",
		},
		{
			key: "left_head_light",
			label: "L/H Head Light",
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			key: "right_head_light",
			label: "R/H Head Light",
			qty: 1,
			exportsAs: 'conventional'
		},
		{
			key: "left_tail_light",
			label: "L/H QTR Tail Light",
			qty: 1,
			exportsAs: 'conventional',
			_link_panel: ['l_h_r_quarter_panel_pillar', 'l_h_r_quarter'],
			_requires_body_type: ['sedan', 'coupe', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', 'minivan'],
		},
		{
			key: "right_tail_light",
			label: "R/H QTR Tail Light",
			qty: 1,
			exportsAs: 'conventional',
			_link_panel: ['r_h_r_quarter_panel_pillar', 'r_h_r_quarter'],
			_requires_body_type: ['sedan', 'coupe', '3dr_hatchback', '5dr_hatchback', 'station_wagon', '4x4', 'minivan'],
		},
		{
			key: "right_tail_gate_tail_light",
			label: "R/H T/Gate Tail Light",
			qty: 1,
			exportsAs: 'conventional',
			_link_panel: ['tgate_upper', 'tgate_lower'],
			_requires_body_type: ['5dr_hatchback', '3dr_hatchback', 'station_wagon', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
		},
		{
			key: "left_tail_gate_tail_light",
			label: "L/H T/Gate Tail Light",
			qty: 1,
			exportsAs: 'conventional',
			_link_panel: ['tgate_upper', 'tgate_lower'],
			_requires_body_type: ['5dr_hatchback', '3dr_hatchback', 'station_wagon', '4x4', 'minivan', '2dr_ute', '4dr_ute', 'truck', 'commercial'],
		},

		{
			_group: true,
			name: "Items",
		},
		{
			_requires_body_type: [],
			key: "roof_rail_racks",
			label: "Roof Rail/Racks",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "door_assembly",
			label: "Door Assembly",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "sunroof",
			label: "Sunroof",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "snorkel",
			label: "Snorkel",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "side_steps",
			label: "Side Step",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "sports_bar",
			label: "Sports Bar",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "canopy",
			label: "Canopy",
			qty: 1,
		},
		{
			_requires_body_type: ['2dr_ute', '4dr_ute'],
			key: "tray",
			label: "Tray",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "bull_bar",
			label: "Bullbar",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "awning",
			label: "Awning",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "aerial",
			label: "Aerial",
			qty: 1,
		},
		{
			_requires_body_type: [],
			key: "aircon_vents_piping",
			label: "Aircon Vents & Piping",
			_review_label_override: 'Aircon Vents & Piping (if required)',
			qty: 1,
		},

	];

	if (bodyTypeKey) {
		return items.filter((item) => {
			return item._requires_body_type?.length ? item._requires_body_type?.includes(bodyTypeKey) : true;
		});
	}
	return items;
}
export const baseParts = (bodyTypeKey = null, panel = null) => {
	let parts = [];

	parts.push({
		_group: true,
		name: "Panels",
	});
	(panelsBase()).filter(panel => panel.can_replace).forEach(panel => {
		parts.push({
			key: panel.key,
			label: panel.name,
			_requires_body_type: panel.requires_body_type,
			// _link_panel: [panel.key],
		});
	});
	parts = [
		...parts,
		//// Moulds
		{
			_group: true,
			name: "Moulds",
		},
		{
			key: "bonnet_mould",
			label: "Bonnet Mould",
			_link_panel: ['bonnet']
		},
		{
			key: "left_turret_mould",
			label: "L/H Turret Mould",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "right_turret_mould",
			label: "R/H Turret Mould",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "left_turret_mould_clips",
			label: "L/H Turret Mould Clips",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "right_turret_mould_clips",
			label: "R/H Turret Mould Clips",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "left_front_belt_mould",
			label: "L/H/F Door Belt Mould",
			_link_panel: ['l_h_f_door']
		},
		{
			key: "right_front_belt_mould",
			label: "R/H/F Door Belt Mould",
			_link_panel: ['r_h_f_door']
		},
		{
			key: "right_front_door_front_vertical_mould",
			label: "R/H/F Door Front Vertical Mould",
			_link_panel: ['r_h_f_door']
		},
		{
			key: "right_front_door_rear_vertical_mould",
			label: "R/H/F Door Rear Vertical Mould",
			_link_panel: ['r_h_f_door']
		},
		{
			key: "right_rear_door_front_vertical_mould",
			label: "R/H/R Door Front Vertical Mould",
			_link_panel: ['r_h_r_door']
		},
		{
			key: "right_rear_door_rear_vertical_mould",
			label: "R/H/R Door Rear Vertical Mould",
			_link_panel: ['r_h_r_door']
		},
		{
			key: "left_front_door_front_vertical_mould",
			label: "L/H/F Door Front Vertical Mould",
			_link_panel: ['l_h_f_door']
		},
		{
			key: "left_front_door_rear_vertical_mould",
			label: "L/H/F Door Rear Vertical Mould",
			_link_panel: ['l_h_f_door']
		},
		{
			key: "left_rear_door_front_vertical_mould",
			label: "L/H/R Door Front Vertical Mould",
			_link_panel: ['l_h_r_door']
		},
		{
			key: "left_rear_door_rear_vertical_mould",
			label: "L/H/R Door Rear Vertical Mould",
			_link_panel: ['l_h_r_door']
		},
		{
			key: "left_front_upper_frame_mould",
			label: "L/F/U Door Frame Mould",
			_link_panel: ['l_h_f_door']
		},
		{
			key: "right_front_upper_frame_mould",
			label: "R/F/U Door Frame Mould",
			_link_panel: ['r_h_f_door']
		},
		{
			key: "left_rear_belt_mould",
			label: "L/H/R Door Belt Mould",
			_link_panel: ['l_h_r_door']
		},
		{
			key: "right_rear_belt_mould",
			label: "R/H/R Door Belt Mould",
			_link_panel: ['r_h_r_door']
		},
		{
			key: "left_rear_upper_frame_mould",
			label: "L/R/U Door Frame Mould",
			_link_panel: ['l_h_r_door']
		},
		{
			key: "right_rear_upper_frame_mould",
			label: "R/R/U Door Frame Mould",
			_link_panel: ['r_h_r_door']
		},
		{
			key: "left_door_mould_rivets",
			label: "L/H Door Mould Rivets",
			_link_panel: ['l_h_f_door', 'l_h_r_door']
		},
		{
			key: "right_door_mould_rivets",
			label: "R/H Door Mould Rivets",
			_link_panel: ['r_h_f_door', 'r_h_r_door']
		},
		{
			key: "left_1/4_glass_mould",
			label: "L/H 1/4 Glass Mould",
			_link_panel: ['l_h_r_quarter_panel_pillar', 'l_h_r_quarter']
		},
		{
			key: "right_1/4_glass_mould",
			label: "R/H 1/4 Glass Mould",
			_link_panel: ['r_h_r_quarter_panel_pillar', 'r_h_r_quarter']
		},
		{
			key: "tgate_mould",
			label: "T/Gate Mould",
			_link_panel: ['tgate', 'tgate_upper', 'tgate_lower']
		},
		{
			key: "bootlid_mould",
			label: "Bootlid Mould",
			_link_panel: ['bootlid']
		},
		//// Lights
		{
			_group: true,
			name: "Lights",
		},
		{
			key: "left_tail_light",
			label: "L/H QTR Tail Light",
			_link_panel: ['l_h_r_quarter_panel_pillar', 'l_h_r_quarter']
		},
		{
			key: "right_tail_light",
			label: "R/H QTR Tail Light",
			_link_panel: ['r_h_r_quarter_panel_pillar', 'r_h_r_quarter']
		},
		{
			key: "left_head_light",
			label: "L/H Head Light"
		},
		{
			key: "right_head_light",
			label: "R/H Head Light"
		},
		{
			key: "right_tail_gate_tail_light",
			label: "R/H T/Gate Tail Light",
			_link_panel: ['tgate_upper', 'tgate_lower']
		},
		{
			key: "left_tail_gate_tail_light",
			label: "L/H T/Gate Tail Light",
			_link_panel: ['tgate_upper', 'tgate_lower']
		},
		{
			key: "left_head_light_cover",
			label: "L/H Head Light Cover",
			_link_panel: ['l_h_f_guard']
		},
		{
			key: "right_head_light_cover",
			label: "R/H Head Light Cover",
			_link_panel: ['r_h_f_guard']
		},

		//// Other
		{
			_group: true,
			name: "Other",
		},
		{
			key: "bonnet_protector",
			label: "Bonnet Protector",
			_link_panel: ['l_h_r_quarter_panel_pillar', 'r_h_r_quarter_panel_pillar', 'bonnet']
		},
		{
			key: "spoiler",
			label: "Spoiler",
			_link_panel: ['tgate_upper', 'tgate_lower', 'bootlid']
		},
		{
			key: "sound_pad_clips",
			label: "Sound Pad Clips",
			_link_panel: ['bonnet']
		},
		{
			key: "right_roof_rail",
			label: "R/H Roof Rail",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "left_roof_rail",
			label: "L/H Roof Rail",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "right_front_roof_rail_end_cap",
			label: "R/H/F Roof Rail End Cap",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "left_front_roof_rail_end_cap",
			label: "L/H/F Roof Rail End Cap",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "right_roof_rail_end_cap_rear",
			label: "Right Roof Rail End Cap Rear",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "left_rear_roof_rail_end_cap_rear",
			label: "L/H/R Roof Rail End Cap",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "right_rear_roof_rail_end_cap_rear",
			label: "R/H/R Roof Rail End Cap",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "left_front_weathershield",
			label: "L/H/F Weathershield",
			_link_panel: ['l_h_f_door']
		},
		{
			key: "right_front_weathershield",
			label: "R/H/F Weathershield",
			_link_panel: ['r_h_f_door']
		},
		{
			key: "left_rear_weathershield",
			label: "L/H/R Weathershield",
			_link_panel: ['l_h_r_door']
		},
		{
			key: "right_rear_weathershield",
			label: "R/H/R Weathershield",
			_link_panel: ['r_h_r_door']
		},
		{
			key: "left_mirror_cover",
			label: "L/H Mirror Cover"
		},
		{
			key: "right_mirror_cover",
			label: "R/H Mirror Cover"
		},
		{
			key: "left_wheel_arch_flare",
			label: "L/H Wheel Arch Flare",
			_link_panel: ['l_h_f_guard']
		},
		{
			key: "right_wheel_arch_flare",
			label: "R/H Wheel Arch Flare",
			_link_panel: ['r_h_f_guard']
		},
		{
			key: "aerial",
			label: "Aerial",
			_link_panel: ['turret_coupe', 'turret_sedan', 'turret_hatchback', 'turret_wagon', 'turret_4x4', 'turret_ute', 'turret_commercial']
		},
		{
			key: "frame_tape",
			label: "Frame Tapes",
			_link_panel: ['l_h_f_door', 'l_h_r_door', 'r_h_f_door', 'r_h_r_door']
		}
	];
	let list = parts;
	if (bodyTypeKey) {
		list = list.filter((item) => {
			return item._requires_body_type?.length ? item._requires_body_type?.includes(bodyTypeKey) : true;
		});
	}
	if (panel) {
		list = list.filter((item) => {
			return item._link_panel?.length ? item._link_panel?.includes(panel) : false;
		});
	}
	return list;
};
export const baseMiscItems = [
	{
		key: "enviro_levy",
		label: "Enviro Levy",
	},
	{
		key: "cavity_wax",
		label: "Cavity Wax",
	},
	{
		key: "clips",
		label: "Clips as Required",
	},
	{
		key: "glass_allowance",
		label: "Glass Allowance",
	},
	{
		key: "scan_reset",
		label: "Scan & Reset",
	},
	{
		key: "seam_sealer",
		label: "Seam Sealer - Supply",
	},
	{
		key: "booth_allowance",
		label: "Booth Allowance",
	},
	{
		key: "wash_vac",
		label: "Wash & Vac",
	},
	{
		key: "sanitisation_clean",
		label: "Sanitisation Clean",
	}
];
export const baseTurretMiscItems = [
	{
		key: "panel_bond",
		label: "Panel Bond",
		adornment: "$",
	},
	{
		key: "anti_flutter",
		label: "Anti-Flutter",
		adornment: "$",
	},
	{
		key: "consumables_allowance",
		label: "Consumables Allowance",
		adornment: "%",
	},
	{
		key: "weld_through_primer",
		label: "Weld Through Primer",
		adornment: "$",
	}
];
export const bodyTypes = [
	{
		label: "Coupe",
		value: "coupe"
	},
	{
		label: "Sedan",
		value: "sedan"
	},
	{
		label: "3dr Hatchback",
		value: "3dr_hatchback"
	},
	{
		label: "5dr Hatchback",
		value: "5dr_hatchback"
	},
	{
		label: "Station Wagon",
		value: "station_wagon"
	},
	{
		label: "4x4",
		value: "4x4"
	},
	{
		label: "2dr Ute",
		value: "2dr_ute"
	},
	{
		label: "4dr Ute",
		value: "4dr_ute"
	},
	{
		label: "Minivan",
		value: "minivan"
	},
	{
		label: "Truck",
		value: "truck"
	},
	{
		label: "Commercial",
		value: "commercial"
	},
];
export const transmissionTypes = [
	{
		label: "Automatic",
		value: "automatic"
	},
	{
		label: "Manual",
		value: "manual"
	}
];
export const paintTypes = [
	{
		label: "Solid",
		value: "solid"
	},
	{
		label: "Metallic",
		value: "metallic"
	},
	{
		label: "Pearl",
		value: "pearl"
	},
];
export const vehicleSizes = [
	{
		label: "Small (i30, Corolla)",
		value: "small"
	},
	{
		label: "Medium (Tusxon, ASX)",
		value: "medium"
	},
	{
		label: "Large (Prado, Cruiser)",
		value: "large"
	},
];
export const conventionalRepairTypes = {
	light: {
		label: "P2P",
		value: "light"
	},
	heavy: {
		label: "P2R",
		value: "heavy",
		cost_array: "repair_heavy_cost",
		labels: ['Light', 'Heavy']
	},
	full: {
		label: "Full Conv",
		value: "full",
		cost_array: "repair_full_conventional_cost",
		labels: ['Light', 'Medium', 'Heavy']
	},
}