import { Check } from '@mui/icons-material';
import { Button, Tooltip } from '@mui/material';
import { memo } from 'react';

const map = {
	"in_progress": {
		label: "In Progress",
		colour: "secondary"
	},
	"completed": {
		label: "Completed",
		colour: "success"
	},
}

const FormStatusButton = memo(({
	status,
	...props
}) => {
	return (
		<Tooltip title={status == "in_progress" ? "Continue Form" : "Open"}>
			<Button
				variant="outlined"
				fullWidth
				color={map?.[status]?.colour}
				{...props}
			>
				{status == "completed" && (
					<Check color='success' />
				)}
				{map?.[status]?.label}
			</Button>
		</Tooltip >
	);
});
FormStatusButton.displayName = 'FormStatusButton';

export default FormStatusButton;