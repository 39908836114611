import { abilitiesTemplate } from "~/data/abilitiesTemplate";
import { isObject, setLeaf } from "~/helpers/objectHelper";
import { useAuth } from "~/hooks/useAuth";
import { clone, cloneDeep } from "lodash";
import React, { useCallback, useEffect, useMemo } from "react";
import { useQuery } from '@tanstack/react-query';
import { getMyAbilities } from "~/requests/auth";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import GenericFailed from "~/components/GenericFailed";

/**
 * @typedef TPermissionContext
 * @property {Function} gate
 * @property {Object} abilities
 * @property {Function} getAbilitiesTemplate
 * @property {Object} policy
 * @property {Function} deriveTemplate
 */

/** @type {import('react').Context<TPermissionContext>} */
const PermissionContext = React.createContext({
	gate: (_has) => false,
	abilities: abilitiesTemplate,
	getAbilitiesTemplate: () => null,
	deriveTemplate: () => null,
	getAbilityPaths: () => null,
});
export const __PermissionContext = PermissionContext;




export const PermissionProvider = ({
	children,
	...props
}) => {

	const [abilities, setAbilities] = React.useState(abilitiesTemplate);
	const [loaded, setLoaded] = React.useState(false);
	const { authenticated, logout } = useAuth();

	const { data: data_abilities, isError, isSuccess } = useQuery({
		queryKey: ['me', 'abilities'],
		queryFn: queryFunctionHelper(getMyAbilities),
		enabled: authenticated,
		staleTime: 1000 * 60,
		retry: 3,
	});

	const getAbilitiesTemplate = useCallback(() => {
		return cloneDeep(abilitiesTemplate);
	}, []);

	const deriveTemplate = useCallback((abilities = [], template = null) => {
		var newTemplate = clone(template || getAbilitiesTemplate());

		abilities.forEach(ability => {
			const paths = ability.split(".").filter(path => path !== "*");
			ability.split(".").reduce((o, path) => {
				if (path === '*') {
					return setLeaf(o, isObject, null, paths);
				} else if (typeof o?.[path] == "boolean") {
					o[path] = ability;
				}
				return o?.[path];
			}, newTemplate);
		});

		return newTemplate;
	}, [getAbilitiesTemplate]);

	const getAbilityPaths = useCallback((abilities) => {

		function iter(o, p) {
			if (isObject(o)) {
				const keys = Object.keys(o);

				if (keys.length) {
					return keys.forEach(function (k) {
						iter(o[k], p.concat(k));
					});
				}
			} else {
				if (o) { // determin truthy
					paths.push(p.join('.'));
				}
			}
		}

		const paths = [];
		iter(abilities, []);

		return paths;

	}, []);

	const abilityPaths = useMemo(() => getAbilityPaths(abilities), [abilities, getAbilityPaths]);

	const failedToLoad = useCallback(() => {
		console.error('Failed to load abilities');

		setLoaded(false);
		logout(true);
	}, [logout]);

	useEffect(() => {
		if (isError) {
			failedToLoad();
		}

		if (isSuccess) {
			if (data_abilities) {
				setAbilities(deriveTemplate(data_abilities));
				setLoaded(true);
			} else {
				failedToLoad();
			}
		}
	}, [data_abilities, deriveTemplate, failedToLoad, isError, isSuccess]);

	// Accepts a expression or an ability string which it will look up
	const gate = useCallback((expression_or_abilitys = false) => {
		if (typeof expression_or_abilitys === 'string') {

			// if expression_or_abilities contains || or &&, then eval it
			if (expression_or_abilitys.includes('||') || expression_or_abilitys.includes('&&') | expression_or_abilitys.includes('!')) {

				// Used for eval
				const { tenant, client, system } = abilities;

				return eval(expression_or_abilitys);
			}

			return abilityPaths.includes(expression_or_abilitys);
		}

		if (Array.isArray(expression_or_abilitys)) {
			return expression_or_abilitys.every(v => typeof v === 'string' ? abilityPaths.includes(v) : !!(v) === true);
		}

		return !!(expression_or_abilitys) === true;
	}, [abilityPaths]);


	if (!authenticated) {
		return children;
	}

	if (isError) {
		return <GenericFailed message="Failed to load abilities" />;
	}

	return (
		<PermissionContext.Provider
			value={{ gate, abilities, deriveTemplate }}
			{...props}
		>
			{loaded ? children : null}
		</PermissionContext.Provider>
	);
};
