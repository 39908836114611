import React from 'react';
import { Grid, Stack, Typography } from '@mui/material';


const ValueLabel = ({ label, value, emptyValue = "-" }) => {
	return (
		<Grid container>
			<Stack spacing={0}>
				<Typography sx={{
					fontWeight: "bold",
					fontSize: "0.9rem"
				}}>
					{label}
				</Typography>
				<Typography>{value || emptyValue}</Typography>
			</Stack>
		</Grid>
	);
}

export default ValueLabel;