import { useEffect, useRef } from 'react';
import invariant from 'tiny-invariant';

const useKeypress = (keys, handler) => {

	const target = useRef(null);

	invariant(
		Array.isArray(keys) || typeof keys === 'string',
		'Expected `keys` to be an array or string'
	);
	if (Array.isArray(keys)) {
		keys.forEach((key, i) => {
			invariant(
				typeof key === 'string',
				`Expected \`keys[${i}]\` to be a string`
			);
		});
	}
	invariant(
		typeof handler === 'function' || handler == null,
		'Expected `handler` to be a function'
	);

	const eventListenerRef = useRef(null);

	useEffect(() => {
		eventListenerRef.current = (event) => {
			shimKeyboardEvent(event);
			if (keys === '*' || Array.isArray(keys) ? keys.includes(event.key) : keys === event.key) {
				handler?.(event);
			}
		};
	}, [keys, handler]);

	useEffect(() => {
		const eventListener = (event) => {
			eventListenerRef.current(event);
		};

		const targetNode = target?.current || window;
		targetNode.addEventListener('keydown', eventListener);
		return () => {
			targetNode.removeEventListener('keydown', eventListener);
		};
	}, [target]);

	return {
		ref: target
	}
};

const aliases = new Map([
	['Win', 'Meta'],
	['Scroll', 'ScrollLock'],
	['Spacebar', ' '],
	['Down', 'ArrowDown'],
	['Left', 'ArrowLeft'],
	['Right', 'ArrowRight'],
	['Up', 'ArrowUp'],
	['Del', 'Delete'],
	['Crsel', 'CrSel'],
	['Exsel', 'ExSel'],
	['Apps', 'ContextMenu'],
	['Esc', 'Escape'],
	['Decimal', '.'],
	['Multiply', '*'],
	['Add', '+'],
	['Subtract', '-'],
	['Divide', '/'],
]);

const shimKeyboardEvent = (event) => {
	if (aliases.has(event.key)) {
		const key = aliases.get(event.key);

		Object.defineProperty(event, 'key', {
			configurable: true,
			enumerable: true,
			get() {
				return key;
			},
		});
	}
};

export default useKeypress;
