import { Box } from '@mui/material';
import { useDocumentTitle } from "usehooks-ts";
import PropTypes from 'prop-types';
import React, { memo } from 'react';

const Page = memo(({
	title = '',
	children = null,
	sx = {},
	providers = [],
	...props

}) => {
	useDocumentTitle(`PHMS - ${title}`);

	return (
		<Box sx={sx} {...props}>
			{providers.reduceRight((acc, Provider) => React.createElement(Provider, null, acc), children)}
		</Box>
	);
});

Page.displayName = 'Page';

Page.propTypes = {
	title: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	]),
	providers: PropTypes.array,
	sx: PropTypes.object,
};
export default Page;