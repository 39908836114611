import { BottomNavigation, BottomNavigationAction, Box, DialogContent, IconButton, ImageList, ImageListItem, ImageListItemBar, Paper, Tooltip } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Image } from 'mui-image';
import CancelIcon from '@mui/icons-material/Cancel';
import { CameraContext } from "./CameraContext";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SlideshowLightbox } from "lightbox.js-react";
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import DeselectIcon from '@mui/icons-material/Deselect';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import UploadIcon from '@mui/icons-material/Upload';
import debounce from "lodash.debounce";

const CameraGallery = () => {
	const { photos, closeGallery, selectedPhotos, savePhotos } = useContext(CameraContext);

	const [cols, setCols] = useState(6);
	const [lightboxstartIndex, setLightboxstartIndex] = useState(0);
	const [lightboxIsOpen, setLightboxIsOpen] = useState(false);

	const lightBoxImages = useMemo(() => photos.map((photo) => {
		return {
			src: photo.src,
			alt: "",
		};
	}), [photos])

	const calculateCols = useCallback(() => {
		const dialogWidth = document.querySelector("#camera-dialog .MuiDialog-paper")?.offsetWidth || 0;
		const calculatedCols = Math.max(1, Math.floor(dialogWidth / 250));
		setCols(calculatedCols);
	}, []);

	// Use lodash.debounce to debounce the calculateCols function
	const debouncedCalculateCols = useCallback(debounce(calculateCols, 200, { leading: true }), []);

	useEffect(() => {
		calculateCols(); // Initial calculation
		window.addEventListener('resize', debouncedCalculateCols); // Update on resize

		return () => window.removeEventListener('resize', debouncedCalculateCols); // Cleanup on unmount
	}, [debouncedCalculateCols]);

	return (
		<Paper sx={{
			position: 'absolute',
			top: 0,
			right: 0,
			bottom: 0,
			left: 0,
			zIndex: 1060,
			display: 'flex',
			flexDirection: 'column',
		}}>
			<DialogContent>
				<ImageList cols={cols} gap={12} sx={{ my: 0 }}>
					{photos.map((photo, index) => {
						return (
							<ImageListItem key={photo.key}
								sx={{
									position: 'relative',
									cursor: 'pointer',
									'&:hover .MuiImageListItemBar-root': {
										opacity: 1,
									}
								}}
								onClick={() => {
									setLightboxstartIndex(index);
									setLightboxIsOpen(true);
								}}
							>
								<Image
									src={photo.src}
									duration={1000}
									sx={{
										aspectRatio: 1
									}}
								/>
								<ImageListItemBar
									actionIcon={
										<IconButton
											sx={{ color: 'rgba(255, 255, 255, 1)' }}
											onClick={(e) => {
												e.stopPropagation();
												photo.toggle();
											}}>
											{!photo.selected && (
												<CheckBoxOutlineBlankIcon />
											)}
											{photo.selected && (
												<CheckBoxIcon />
											)}
										</IconButton>
									}
								>
								</ImageListItemBar>
							</ImageListItem>
						)
					})}
				</ImageList>

				<SlideshowLightbox
					images={lightBoxImages}
					showThumbnails={true}
					open={lightboxIsOpen}
					lightboxIdentifier="formPhotosLightbox"
					fullScreen
					onClose={() => setLightboxIsOpen(false)}
					nextArrow={
						<IconButton aria-label="Next" color="secondary" variant="outlined">
							<NavigateNextRoundedIcon />
						</IconButton>
					}
					prevArrow={
						<IconButton aria-label="Next" color="secondary" variant="outlined">
							<NavigateBeforeRoundedIcon />
						</IconButton>
					}
					startingSlideIndex={lightboxstartIndex}
				/>
			</DialogContent>
			<BottomNavigation
				showLabels
				sx={{
					py: 1
				}}
			>
				<BottomNavigationAction label="Camera" icon={<CameraAltIcon />} color="primary" onClick={closeGallery} />
				{photos.length === selectedPhotos.length && (
					<BottomNavigationAction label="Deselect All" icon={<DeselectIcon />} onClick={() => photos.forEach(photo => photo.toggle(false))} />
				)}
				{photos.length !== selectedPhotos.length && (
					<BottomNavigationAction label="Select All" icon={<SelectAllIcon />} onClick={() => photos.forEach(photo => photo.toggle(true))} />
				)}
				<BottomNavigationAction label="Upload" icon={<UploadIcon />} disabled={selectedPhotos.length === 0} onClick={savePhotos} />
			</BottomNavigation>
		</Paper>
	);
}

export default CameraGallery;