import { LicenseInfo } from '@mui/x-license';
import { md5 } from '@mui/x-license/encoding/md5';
import { PLAN_SCOPES } from '@mui/x-license/utils/plan';
import { LICENSE_MODELS } from '@mui/x-license/utils/licenseModel';


// Lifetime License Key:
// e0d9bb8070ce0054c9d9ecb6e82cb58fTz0wLEU9MzI0NzIxNDQwMDAwMDAsUz1wcmVtaXVtLExNPXBlcnBldHVhbCxLVj0y
// Order Number: 0
// Expiry Timestamp: 32472144000000(2999-01-01)
// Scope: Premium
// Licensing Model: Perpetual
// Key Version: 2


export function validateMuiLicense() {

	let orderNumber = '87795';
	let expiryTimestamp = Date.now(); // Expiry is based on when the package was created, ignored if perpetual license
	let scope = PLAN_SCOPES[1]; // 'pro' or 'premium'
	let licensingModel = LICENSE_MODELS[0]; // 'perpetual', 'subscription', 'subscription'
	let licenseInfo = `O=${orderNumber},E=${expiryTimestamp},S=${scope},LM=${licensingModel},KV=2`;
	LicenseInfo.setLicenseKey(md5(btoa(licenseInfo)) + btoa(licenseInfo));

}