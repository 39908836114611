import { useContext } from "react";
import { __AssessmentDialogContext } from "~/providers/dialogs/AssessmentDialogProvider";
import { __AssessmentEditDialogContext } from "~/providers/dialogs/AssessmentEditDialogProvider";
import { __StartAuditDialogContext } from "~/providers/dialogs/StartAuditDialogProvider";
import { __StartEstimateDialogContext } from "~/providers/dialogs/StartEstimateDialogProvider";

export function useDialog() {
	const AEContext = useContext(__AssessmentEditDialogContext);
	const AContext = useContext(__AssessmentDialogContext);
	const SEContext = useContext(__StartEstimateDialogContext);
	const SAContext = useContext(__StartAuditDialogContext);

	return {
		openAssessmentDialog: AContext.open,

		openAssessmentEditDialog: AEContext.open,

		openStartEstimateDialog: SEContext.open,
		startEstimateDialogIsOpen: SEContext.popupState.isOpen,

		openStartAuditDialog: SAContext.open,
		startAuditDialogIsOpen: SAContext.popupState.isOpen
	};
}