import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid, MenuItem, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { FastField, Field, Formik } from "formik";
import { TextField, } from "formik-mui";
import { DateTime } from "luxon";
import { bindDialog } from 'material-ui-popup-state';
import { forwardRef, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-router-dom';
import * as Yup from 'yup';
import { transmissionTypes } from '~/form/estimate/data/data';
import { fieldForceUpperCase } from '~/helpers/dataHelpers';
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { mobileNumberRegex, phoneNumberRegex } from "~/helpers/stringHelpers";
import { useCrud } from "~/hooks/useCrud";
import { createAssessment, updateAssessment } from "~/requests/assessments";
import { getFacilityTimeSlots } from "~/requests/facilities";
import { __AssessmentEditDialogContext } from '../../providers/dialogs/AssessmentEditDialogProvider';
import EventAutocomplete from "../Dropdowns/EventAutocomplete";
import FacilityAutocomplete from "../Dropdowns/FacilityAutocomplete";
import WorkProviderDropdown from "../Dropdowns/WorkProviderDropdown";
import { DatePicker } from "../formik-mui-x-datepickers/DatePicker";
import { Select } from "../formik-mui/Select";
import { leftMerge } from "~/helpers/objectHelper";
import StateDropdown from "../Dropdowns/StateDropdown";
import ReactInputMask from "react-input-mask";

const defaultValues = {
	facility_uuid: null,
	event_uuid: null,
	workprovider_uuid: null,
	claim_number: '',
	assessment_datetime: '',
	vehicle: {
		build_year: '',
		rego: '',
		make: '',
		model: '',
		badge: '',
		vin: '',
		colour: '',
		transmission: null,
	},
	customer: {
		first_name: '',
		last_name: '',
		company_name: '',
		mobile: '',
		mobile_alt: '',
		phone_work: '',
		phone_home: '',
		email: '',
		email_alt: '',
		suburb: '',
		postcode: '',
	}
};

const buildYearValidation = (value) => {
	if (!value) {
		return true; // If value is null or undefined, let other validations handle it
	}

	const yearRegex = /^\d{4}$/; // YYYY format
	const monthYearRegex = /^(0[1-9]|1[0-2])\/\d{4}$/; // MM/YYYY format

	if (yearRegex.test(value)) {
		const year = parseInt(value, 10);
		return year >= 1950 && year <= new Date().getFullYear() + 2;
	}

	if (monthYearRegex.test(value)) {
		const [monthPart, yearPart] = value.split('/');
		const monthNum = parseInt(monthPart, 10);
		const year = parseInt(yearPart, 10);

		const isValidMonth = monthNum >= 1 && monthNum <= 12;
		const isValidYear = year >= 1950 && year <= new Date().getFullYear() + 2;

		return isValidMonth && isValidYear;
	}

	return false;
};

const validationSchema = () => Yup.object().shape({
	wp_uuid: Yup.string().nullable(),
	claim_number: Yup.string().nullable(),
	assessment_datetime: Yup.date().nullable(),

	customer: Yup.object().shape({
		first_name: Yup.string().nullable(),
		last_name: Yup.string().nullable(),
		company_name: Yup.string().nullable(),
		mobile: Yup.string().matches(mobileNumberRegex, "Invalid mobile number").nullable(),
		mobile_alt: Yup.string().matches(mobileNumberRegex, "Invalid mobile number").nullable(),
		phone_work: Yup.string().matches(phoneNumberRegex, "Invalid phone number").nullable(),
		phone_home: Yup.string().matches(phoneNumberRegex, "Invalid phone number").nullable(),
		email: Yup.string().email("Invalid email").nullable(),
		email_alt: Yup.string().email("Invalid email").nullable(),
		suburb: Yup.string().nullable(),
		postcode: Yup.number().typeError('Invalid postcode').min(1000, 'Invalid postcode').max(9999, 'Invalid postcode').nullable(),
	}).test('customer', 'A first name, last name or company name is required', (customer) => {
		if (customer.first_name || customer.last_name || customer.company_name) return true;
		return new Yup.ValidationError('A first name, last name or company name is required', null, 'customerGeneral');
	}).test('contact', 'At least one phone, mobile or email is required', (customer) => {
		if (customer.mobile || customer.mobile_alt || customer.phone_work || customer.phone_home || customer.email || customer.email_alt) return true;
		return new Yup.ValidationError('At least one phone, mobile or email is required', null, 'customerGeneral');
	}),

	vehicle: Yup.object().shape({
		build_year: Yup.string().nullable().test('build', 'Build year must be YYYY or MM/YYYY', buildYearValidation),
		make: Yup.string().nullable(),
		model: Yup.string().nullable(),
		badge: Yup.string().nullable(),
		rego: Yup.string().max(9, "Invalid rego").nullable(),
		vin: Yup.string().nullable().length(17, "VIN must be 17 characters"),
		colour: Yup.string().nullable(),
		transmission: Yup.string().nullable(),
	}),
});

const AssessmentEditDialog = () => {
	const { popupState, assessment } = useContext(__AssessmentEditDialogContext);

	const crud = useCrud({ createRequest: createAssessment, updateRequest: updateAssessment, queryKey: ['assessments', assessment?.uuid] });

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const [facilityUuid, setFacilityUuid] = useState(assessment?.facility?.uuid);
	const [assessmentDateTime, setAssessmentDateTime] = useState(assessment?.assessment_datetime);

	const isNew = !!assessment?.id;

	const timeSlotsQuery = useQuery({
		queryKey: ['facilities', facilityUuid, 'slots'],
		queryFn: queryFunctionHelper(getFacilityTimeSlots, facilityUuid),
		enabled: popupState.isOpen && !!facilityUuid,
	});

	const timeSlotsForDate = useMemo(() => {
		const time = assessmentDateTime ? DateTime.fromISO(assessmentDateTime).toFormat('HH:mm') : "00:00";
		let slots = timeSlotsQuery.data && assessmentDateTime
			? timeSlotsQuery.data[DateTime.fromISO(assessmentDateTime).toFormat('yyyy-MM-dd')] || []
			: [];

		if (time !== "00:00" && !slots.includes(time)) {
			// Add existing time to list
			slots.push(time);
		}

		return slots.sort();
	}, [timeSlotsQuery.data, assessmentDateTime]);

	const availableDates = useMemo(() => Object.keys(timeSlotsQuery.data || {}).map(date => DateTime.fromFormat(date, 'yyyy-MM-dd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }))
		, [timeSlotsQuery.data]);

	const isDateValid = useCallback((date, avalible) => avalible.some(d => d.hasSame(date, 'day'))
		, []);

	const handleSubmit = (values, formik) => {
		let data = { ...values };
		if (data.assessment_datetime && data.assessment_time) {
			// stitch the date and time together
			data.assessment_datetime = DateTime.fromISO(data.assessment_datetime).set({ hour: data.assessment_time.split(':')[0], minute: data.assessment_time.split(':')[1] }).toISO();
		}

		crud.doSave({
			id: assessment.uuid,
			values: data,
			toastLabel: "Assessment",
			onSuccess: () => popupState.close(),
		});
		return false;
	};

	const initialValues = useMemo(() => leftMerge({
		...defaultValues,
		assessment_time: assessment?.assessment_datetime ? DateTime.fromISO(assessment.assessment_datetime).toFormat('HH:mm') : '',
	}, { ...assessment }), [assessment]);

	useEffect(() => {
		setFacilityUuid(assessment?.facility?.uuid);
	}, [assessment?.facility?.uuid]);

	useEffect(() => {
		setAssessmentDateTime(assessment?.assessment_datetime);
	}, [assessment?.assessment_datetime]);

	const timeRange = useMemo(() => Array.from(
		{ length: (18 * 60 - 8 * 60) / 15 },
		(_, i) => DateTime.fromObject({ hour: 8 }).plus({ minutes: i * 15 }).toFormat('HH:mm')
	), []);

	return (
		<>
			<Dialog
				{...bindDialog(popupState)}
				maxWidth='md'
				scroll='paper'
				fullScreen={isMobile}
			>
				<DialogTitle>{isNew ? 'Add' : 'Edit'} Assessment</DialogTitle>
				<Formik
					initialValues={initialValues}
					validationSchema={validationSchema}
					onSubmit={handleSubmit}
				>
					{formik => (
						<Form>
							<DialogContent dividers>
								<Stack gap={2}>

									<Typography variant="h4">
										Insurer
									</Typography>

									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Field name="workprovider_uuid">
												{({ field, meta }) => (
													<WorkProviderDropdown
														{...field}
														fullWidth
														size="small"
														formikMeta={meta}
														onChange={(name, value) => {
															formik.setFieldValue(name, value);
															formik.setFieldValue('facility_uuid', null);
														}}
													/>
												)}
											</Field>
										</Grid>
										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="claim_number"
												label="Claim Number"
												fullWidth
											/>
										</Grid>
									</Grid>

									<Typography variant="h4">
										Facility & Booking
									</Typography>

									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Field name="facility_uuid">
												{({ field, meta }) => (
													<FacilityAutocomplete
														multiple={false}
														initialValue={assessment?.facility}
														value={field.value}
														fullWidth
														size="small"
														workproviderId={formik.values.workprovider_uuid}
														noOptionsText="No facilities for work provider"
														onChange={(name, item) => {
															formik.setFieldValue(field.name, item?.uuid || null);
															setFacilityUuid(item?.uuid || null);
														}}
													/>
												)}
											</Field>
										</Grid>
										<Grid item xs={6}>
											<Field name="event_uuid">
												{({ field, meta }) => (
													<EventAutocomplete
														multiple={false}
														value={field.value}
														fullWidth
														size="small"
														workproviderId={formik.values.workprovider_uuid}
														noOptionsText="No events for work provider"
														onChange={(name, item) => formik.setFieldValue(field.name, item?.uuid || null)}
													/>
												)}
											</Field>
										</Grid>
									</Grid>

									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Field
												component={DatePicker}
												name="assessment_datetime"
												label="Date"
												disablePast={isNew}
												format="dd/MM/yyyy"
												shouldDisableDate={date => isNew && !isDateValid(date, availableDates)
												}
												slotProps={{ textField: { size: 'small', fullWidth: true } }}
												onChange={date => {
													const newDate = DateTime.fromISO(date).set({ hour: 0, minute: 0 }).toISO();
													setAssessmentDateTime(newDate);
													formik.setFieldValue('assessment_datetime', newDate);
												}}
											/>
										</Grid>
										<Grid item xs={6}>
											<Field
												component={Select}
												size="small"
												name="assessment_time"
												label="Time"
												disabled={timeSlotsQuery.isLoading}
												fullWidth
												endAdornment={timeSlotsQuery.isFetching ? <CircularProgress size={20} /> : null}
												renderValue={(isNew && timeSlotsForDate.length === 0) ? () => 'No slots available' : undefined}
											>
												{isNew && timeSlotsForDate.map((time, i) =>
													<MenuItem key={i} value={time}>
														{time}
													</MenuItem>
												)}

												{!isNew && timeRange.map((time, i) =>
													<MenuItem key={i} value={time}>
														{time}
													</MenuItem>
												)}
											</Field>
										</Grid>
									</Grid>

									<Typography variant="h4">
										Vehicle
									</Typography>

									<Grid container spacing={2}>
										<Grid item xs={6}>
											<Field
												component={TextField}
												size="small"
												name="vehicle.rego"
												label="Rego"
												fullWidth
												onChange={e => fieldForceUpperCase(e, formik)}
											/>
										</Grid>
										<Grid item xs={6}>
											<FastField
												name="vehicle.rego_state"
												label="Rego State"
												component={StateDropdown}
												fullWidth
												size="small"
											/>
										</Grid>
										<Grid item xs={6}>
											<Field
												component={TextField}
												size="small"
												name="vehicle.vin"
												label="VIN"
												onChange={e => fieldForceUpperCase(e, formik)}
												fullWidth
											/>
										</Grid>
										<Grid item xs={6}>
											<FastField
												component={VehicleBuildInput}
												inputComponent={TextField}
												size="small"
												name="vehicle.build_year"
												label="Year"
												fullWidth
												onBlur={e => formik.setFieldValue('vehicle.build_year', e.target.value.replace(/[-\\]/g, '/'))}
											/>
										</Grid>
										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="vehicle.make"
												label="Make"
												fullWidth
											/>
										</Grid>
										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="vehicle.model"
												label="Model"
												fullWidth
											/>
										</Grid>
										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="vehicle.badge"
												label="Badge"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="vehicle.colour"
												label="Colour"
												fullWidth
											/>
										</Grid>
										<Grid item xs={6}>
											<FastField
												component={Select}
												size="small"
												name="vehicle.transmission"
												label="Transmission"
												defaultValue=""
												fullWidth
											>
												{transmissionTypes.map((item, i) => (
													<MenuItem key={i} value={item.value}>{item.label}</MenuItem>
												))}
											</FastField>
										</Grid>
									</Grid>


									<Box>
										<Typography variant="h4" color={formik.errors.customerGeneral ? 'error' : ''}>
											Customer
										</Typography>
										<FormHelperText error>{formik.errors.customerGeneral}</FormHelperText>
									</Box>

									<Grid container spacing={2}>
										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.first_name"
												label="First Name"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.last_name"
												label="Last Name"
												fullWidth
											/>
										</Grid>

										<Grid item xs={12}>
											<FastField
												component={TextField}
												size="small"
												name="customer.company_name"
												label="Company Name"
												fullWidth
											/>

										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.mobile"
												label="Mobile"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.mobile_alt"
												label="Mobile Alt"
												fullWidth
											/>

										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.phone_work"
												label="Phone Work"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.phone_home"
												label="Phone Home"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.email"
												label="Email"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.email_alt"
												label="Email Alt"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.suburb"
												label="Suburb"
												fullWidth
											/>
										</Grid>

										<Grid item xs={6}>
											<FastField
												component={TextField}
												size="small"
												name="customer.postcode"
												label="Postcode"
												fullWidth

											/>
										</Grid>
									</Grid>

								</Stack>
							</DialogContent>
							<DialogActions>

								<Button onClick={() => popupState.close()}>
									Cancel
								</Button>
								<Button variant="contained" onClick={() => formik.submitForm()}>
									Save Assessment
								</Button>

							</DialogActions>

						</Form>
					)}
				</Formik>

			</Dialog>
		</>
	);
};

const VehicleBuildInput = forwardRef(({ inputComponent: Component, field, disabled = false, onBlur, ...props }, ref) => (
	<ReactInputMask
		mask="99/9999"
		value={field.value || ''}
		onChange={field.onChange}
		disabled={disabled}
		onBlur={onBlur}
	>
		<Component ref={ref} disabled={disabled} field={field} {...props} />
	</ReactInputMask>
));
VehicleBuildInput.displayName = 'VehicleBuildInput';

export default AssessmentEditDialog;
