import apiHandler from "../apiHandler";

export const getPaintCodes = (params = {}, signal = undefined) => apiHandler({
	method: "get",
	url: "paint-codes",
	dataOrParams: params,
	signal
});

export const getPaintCode = (id, signal = undefined) => apiHandler({
	method: "get",
	url: "paint-codes/" + id,
	signal
});

export const createPaintCode = (params = {}, signal = null) => apiHandler({
	method: "post",
	url: `paint-codes`,
	dataOrParams: params,
	signal
});

export const updatePaintCode = (id, params = {}, signal = null) => apiHandler({
	method: "put",
	url: `paint-codes/${id}`,
	dataOrParams: params,
	signal
});

export const deletePaintCode = (id, params = {}, signal = null) => apiHandler({
	method: "delete",
	url: `paint-codes/${id}`,
	dataOrParams: params,
	signal
});

export const importPaintCodes = (data = {}, signal = null) => apiHandler({
	method: "post",
	url: `paint-codes/import`,
	dataOrParams: data,
	signal
});

export const getManufacturers = (params = {}, signal = null) => apiHandler({
	method: "get",
	url: `paint-codes/get-manufacturers`,
	dataOrParams: params,
	signal
});
