import { useQuery } from "@tanstack/react-query";
import { usePopupState } from "material-ui-popup-state/hooks";
import React, { useCallback, useState } from "react";
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { getAssessment } from '~/requests/assessments';

// context setup
const AssessmentEditDialogContext = React.createContext({
	popupState: {},
	open: (assessment_uuid = null) => { },
	assessment: null
});
export const __AssessmentEditDialogContext = AssessmentEditDialogContext;

export const AssessmentEditDialogProvider = ({ children }) => {

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'AssessmentEditDialog',
	});

	const [assessmentUuid, setAssessmentUuid] = useState(null);

	const { data: assessment } = useQuery({
		queryKey: ['assessments', assessmentUuid],
		queryFn: queryFunctionHelper(getAssessment, assessmentUuid),
		refetchOnWindowFocus: false,
		enabled: !!assessmentUuid,
	});

	// mutate for edit


	// mutate for delete 
	// perhaps make generic crud mutate ?

	const open = useCallback((assessment_uuid = null) => {
		setAssessmentUuid(assessment_uuid);
		popupState.open();
	}, [popupState]);


	return (
		<AssessmentEditDialogContext.Provider
			value={{ popupState, open, assessment }}
		>
			{children}
		</AssessmentEditDialogContext.Provider>
	);
};

AssessmentEditDialogProvider.propTypes = {
}
