import apiHandler from "../apiHandler";

export const getCsrfCookie = () => apiHandler({
	method: "get",
	url: "/sanctum/csrf-cookie"
});

export const login = (credentials) => apiHandler({
	method: "post",
	url: "/auth/login",
	dataOrParams: credentials
});

export const logout = () => apiHandler({
	method: "post",
	url: "/auth/logout"
});

export const getProfile = () => apiHandler({
	method: "get",
	url: "/me"
});

export const updateProfile = (params = {}, signal = null) => apiHandler({
	method: "put",
	url: "/me",
	dataOrParams: params,
	signal
});

export const getMyAbilities = () => apiHandler({
	method: "get",
	url: "/me/abilities"
});