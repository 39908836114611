import { Dialog, DialogContent, Slide } from "@mui/material";
import { forwardRef, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import GlobalSearch from "~/components/GlobalSearch";
import { useDialog } from "~/hooks/useDialog";
import { searchAssessments } from "~/requests/assessments";
import { searchSubmissions } from "~/requests/submissions";
import AssessmentCard from "../AssessmentCard";
import SubmissionSummaryTile from "../SubmissionSummaryTile";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="down" ref={ref} {...props} />;
});


// const { data: assessmentResults = [], isFetched: assessmentsFetched, isLoading: assessmentsLoading, isError: assessmentsError } = useQuery({
// 	queryKey: ['search', 'assessments', searchTerm],
// 	queryFn: queryFunctionHelper(searchAssessments, searchTerm),
// 	refetchOnWindowFocus: false,
// 	enabled: enabled,
// 	retry: false,
// });



const SearchDialog = ({ isOpen, triggerClose }) => {
	const { openAssessmentDialog } = useDialog();
	const navigate = useNavigate();

	const searchModels = useMemo(() => [
		{
			key: 'assessments',
			label: 'Assessments',
			endpoint: searchAssessments,
			onClick: (item) => {
				triggerClose();
				openAssessmentDialog(item.uuid);
			},
			render: (item, key, onClick) => <AssessmentCard key={key} assessment={item} onClick={onClick} />
		},
		{
			key: 'submissions',
			label: 'Estimates',
			endpoint: searchSubmissions,
			onClick: (item) => {
				navigate(`/forms/estimate/${item.uuid}`);
			},
			render: (item, key, onClick) => <SubmissionSummaryTile key={key} submission={item} onClick={onClick} showAuthor />,
			params: {
				form: 'estimate'
			}
		},
		{
			key: 'audits',
			label: 'Audits',
			endpoint: searchSubmissions,
			onClick: (item) => {
				navigate(`/forms/estimate_audit/${item.uuid}`);
			},
			render: (item, key, onClick) => <SubmissionSummaryTile key={key} submission={item} onClick={onClick} showAuthor />,
			params: {
				form: 'estimate_audit'
			}
		}

	], [navigate, openAssessmentDialog, triggerClose]);

	return (
		<Dialog
			open={isOpen}
			onClose={triggerClose}
			fullWidth
			maxWidth="lg"
			TransitionComponent={Transition}
			className="search-dialog dialog-align-top"
		>
			<DialogContent sx={{ p: 1 }} style={{ display: 'flex', flexDirection: 'column' }}>
				<GlobalSearch models={searchModels} />
			</DialogContent>
		</Dialog>
	);
};

export default SearchDialog;