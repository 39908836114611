import { Box, Divider, Stack, Typography } from '@mui/material'
import Image from 'mui-image';
import config from '~/config';

const QuoteCard = ({ quote }) => {
	return (
		<>
			<Stack direction="row" gap={3} sx={{ mb: 1 }}>
				<Typography fontWeight={500}>
					Estimate No: {quote.estimateNumber}
				</Typography>
				<Typography fontWeight={500}>
					Rego: {quote.vehicleRegistration}
				</Typography>
			</Stack>
			<Stack direction="row" gap={2}>
				<Image
					src={`${config.api.base_url}/export/ibs/image/${quote.imageId}`}
					width={120}
					height={170}
					fit='cover'
					alt="Vehicle Image"
					style={{ borderRadius: 8 }}
					showLoading
				/>
				<Box>
					<Stack direction='column' padding={1}>
						<Typography variant="body2">
							Insurer: {quote.insurerName}
						</Typography>
						<Typography variant="body2">
							Owner: {quote.ownerDisplayName}
						</Typography>
						<Typography variant="body2">
							Vehicle: {[quote.vehicleMake, quote.vehicleModel].join(' ')}
						</Typography>
						<Typography variant="body2">
							Created: {quote.created}
						</Typography>
						<Divider sx={{ mt: 1, mb: 1 }} />
						<Typography variant="body2">
							Authorised: {!quote.awaitingAuthorisation ? 'Yes' : 'No'}
						</Typography>
						<Typography variant="body2">
							Invoiced: {quote.isInFullyInvoiced ? 'Yes' : 'No'}
						</Typography>
						<Typography variant="body2">
							Quote Written: {quote.isWritten ? 'Yes' : 'No'}
						</Typography>
					</Stack>
				</Box>
			</Stack>
		</>
	)
}

export default QuoteCard