import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Autocomplete, Checkbox, Chip, TextField, useMediaQuery, useTheme } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useMemo, useState } from 'react';
import { getInsurerColour } from "~/helpers/generalHelper";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { pluralize } from '~/helpers/stringHelpers';
import { getEvents } from "~/requests/events";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EventAutocomplete = ({
	value = undefined,
	multiple = true,
	workproviderId = null,
	onChange,
	...props
}) => {

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const { data: data = [], isLoading } = useQuery({
		queryKey: ['events', workproviderId],
		queryFn: queryFunctionHelper(getEvents, { workprovider_uuid: workproviderId }),
		staleTime: 1000 * 60 * 5,
	});

	const [open, setOpen] = useState(false);

	const _value = useMemo(() => (
		typeof value === 'string' ? data.find(item => item.uuid === value) : value
	), [value, data]);


	return (
		<Autocomplete
			open={open}
			onOpen={() => setOpen(true)}
			onClose={() => setOpen(false)}
			multiple={multiple}
			value={_value}
			isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
			options={data}
			disableCloseOnSelect
			limitTags={isMobile ? 1 : 3}
			loading={isLoading}
			getOptionLabel={(option) => option.name || ''}
			className='mimicSelect'
			onChange={(e, newValue) => {
				onChange('events', newValue);
				if (!multiple) setOpen(false);
			}}
			renderOption={(props, option, { selected, inputValue }) => {
				const matches = match(option.name, inputValue);
				const parts = parse(option.name, matches);

				return (
					<li {...props} key={option.uuid}>
						{multiple && (
							<Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
						)}
						{parts.map((part, index) => (
							<span key={index} style={{ fontWeight: part.highlight ? 700 : 400, whiteSpace: 'pre' }}>
								{part.text}
							</span>
						))}

						{option.workproviders.map((workprovider) => (
							<Chip
								key={workprovider.id}
								label={workprovider.name}
								size="small"
								sx={{ ml: 'auto', backgroundColor: getInsurerColour(workprovider?.name), color: '#fff' }}
							/>
						))}
					</li>
				);
			}}
			renderInput={(params) => (
				<TextField {...params} label={pluralize('Event', multiple)} />
			)}
			{...props}
		/>
	);
}

export default EventAutocomplete;