import { Box, Chip, Drawer, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Gate } from '~/components/Gate';
import MenuGroup from '~/components/Sidemenu/MenuGroup';
import menuItems from '~/nav/menuItems';

const MenuList = memo(({
	onNavigation = null
}) => {
	return (
		<>
			{menuItems.map((item, id) => (
				<Gate key={id} has={item.gate ?? null} passNull>
					<MenuGroup onNavigation={onNavigation} item={{ ...item, id }} />
				</Gate>
			))}
		</>
	);
});
MenuList.displayName = 'MenuList';

const Sidebar = ({ drawerOpen, drawerToggle, onClose }) => {

	const theme = useTheme();
	const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));


	const handleNavigation = useCallback((url) => {
		if (!matchUpMd) {
			onClose();
		}
	}, [matchUpMd, onClose]);

	return (
		<Box component="nav" sx={{ flexShrink: { md: 0 }, width: matchUpMd ? theme.sidebarMenuWidth : 'auto' }}>
			<Drawer
				variant={matchUpMd ? 'persistent' : 'temporary'}
				anchor="left"
				open={drawerOpen}
				onClose={onClose}
				sx={{
					'& .MuiDrawer-paper': {
						width: theme.sidebarMenuWidth,
						background: theme.palette.background.paper,
						color: theme.palette.text.primary,
						[theme.breakpoints.up('md')]: {
							marginTop: `${theme.headerHeight}px`,
						}
					}
				}}
			>
				<Box
					component="div"
					sx={{
						height: `calc(100vh - ${theme.headerHeight}px)`,
						padding: theme.spacing(2),
					}}
				>

					<MenuList onNavigation={handleNavigation} />
					<Stack direction="row" justifyContent="center" sx={{ mb: 2 }}>
						<Link to="changelog">
							<Chip label={__APP_VERSION__} disabled chipcolor="secondary" size="small" sx={{ cursor: 'pointer' }} />
						</Link>
					</Stack>
				</Box>
			</Drawer>
		</Box>
	);
};

Sidebar.propTypes = {
	drawerOpen: PropTypes.bool,
	drawerToggle: PropTypes.func,
	window: PropTypes.object
};

export default Sidebar;
