import { TabContext, TabPanel } from '@mui/lab';
import { Avatar, Box, Card, Chip, CircularProgress, Stack, Typography } from '@mui/material';
import { useQueries } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import useKeypress from '../hooks/useKeyPress';
import SearchField from './SearchField';


const getModel = (index, models) => models[index];

const NoResults = () => (
	<Card sx={{ padding: '8px' }}>
		<Typography>
			No Results
		</Typography>
	</Card>
);

const GlobalSearch = ({ models = [] }) => {
	const [searchTerm, setSearchTerm] = useState("");
	const [selectedResultsTab, setSelectedResultsTab] = useState(models[0].key);

	const minLength = 2;
	const enabled = searchTerm.length >= minLength;

	const queryResults = useQueries({
		queries: models.map((model) => ({
			queryKey: ['search', model.key, searchTerm],
			queryFn: queryFunctionHelper(model.endpoint, searchTerm, model.params),
			refetchOnWindowFocus: false,
			enabled: enabled,
			retry: false,
		})),
	})



	const { ref } = useKeypress(['Enter'], e => {
		if (e.key === 'Enter') {
			// click the first item in the list depending what tab is selected 
			const selectedModel = getModel(models.findIndex((model) => model.key === selectedResultsTab), models);
			const currentIndex = models.findIndex((model) => model.key === selectedResultsTab);
			const selectedResult = queryResults[currentIndex];

			if (selectedResult) {
				selectedModel.onClick(selectedResult.data[0]);
			}
		}
	});


	const isLoading = queryResults.some((result) => result.isLoading);

	useEffect(() => {
		// when queryResults is loaded, if the currently selectedResults has no data, move to the next avalible. selectedResults is the same as getModel(index, models).key so need to loop through queryResults to determin if there is data or not
		const currentIndex = models.findIndex((model) => model.key === selectedResultsTab);

		const selectedResult = queryResults[currentIndex];

		if (selectedResult.data?.length === 0 && selectedResult.isFetched) {
			const nextModel = models.find((model) => {
				const index = models.findIndex((m) => m.key === model.key);
				const result = queryResults[index];
				return result.data?.length > 0;
			});

			if (nextModel) {
				setSelectedResultsTab(nextModel.key);
			}
		}

	}, [models, queryResults, selectedResultsTab])


	return (
		<div className='global-search-container'>
			<SearchField
				inputRef={ref}
				placeholder='Search'
				minLength={minLength}
				className='global-search-field'
				onChange={setSearchTerm}
				isLoading={enabled && (isLoading)}
			/>

			<TabContext value={selectedResultsTab}>
				{enabled && (

					<>
						<Box sx={{ mt: 1 }}>
							<Stack direction='row' spacing={0.5}>
								{queryResults.map((result, index) => {
									const model = getModel(index, models);
									const data = result.data ?? [];


									return (
										<Chip
											key={index}
											avatar={result.isLoading ? <CircularProgress size={18} /> : <Avatar>{data.length}</Avatar>}
											label={model.label}
											color={selectedResultsTab === model.key ? 'primary' : 'default'}
											disabled={!data.length || result.isLoading || result.isError}
											onClick={() => setSelectedResultsTab(model.key)}
										/>
									);
								})}
							</Stack>
						</Box>

						<div className='global-search-results'>
							<Stack direction='column' spacing={1}>

								{queryResults.map((result, index) => {
									const model = getModel(index, models);
									const data = result.data ?? [];

									return (
										<TabPanel key={model.key} value={model.key} sx={{ p: 0, display: 'flex', flexDirection: 'column', gap: 1 }}>
											{(data.length == 0 && result.isFetched) && (
												<NoResults />
											)}
											{data.map((item) => model.render(item, item.uuid, () => model.onClick(item)))}
										</TabPanel>
									)


								})}

							</Stack>
						</div>
					</>
				)}
			</TabContext>
		</div>
	);
}

export default GlobalSearch;

GlobalSearch.propTypes = {
	models: PropTypes.array
}