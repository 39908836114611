import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useAuth } from '~/hooks/useAuth';
import { NavLink } from 'react-router-dom';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import LogoutIcon from "@mui/icons-material/Logout";
import { bindPopover } from 'material-ui-popup-state/hooks';
import { memo } from 'react';
import { Chip, Stack } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const ProfilePopover = memo(({ popupState, triggerHelp }) => {
	const { user, logout } = useAuth();

	return (
		<Popover
			{...bindPopover(popupState)}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<Box sx={{ p: '16px 20px ' }}>
				<Typography variant="subtitle1">{user.name}</Typography>
				<Typography color="text.secondary" variant="body2">
					{user.email}
				</Typography>
				<Stack direction='row' gap={1} sx={{ mt: 0.5 }}>
					{user.roles.map((role) => (
						<Chip key={role.name} label={role.display_name} size='small' />
					))}
				</Stack>
			</Box>
			<Divider />
			<MenuList sx={{ p: '8px', '& .MuiMenuItem-root': { borderRadius: 1 } }}>

				<MenuItem component={NavLink} to="/me" onClick={popupState.close}>
					<ListItemIcon><PersonOutlineIcon /></ListItemIcon>
					Profile
				</MenuItem>

				<MenuItem onClick={() => {
					triggerHelp(true);
					popupState.close();
				}}>
					<ListItemIcon>
						<HelpOutlineIcon color="primary" />
					</ListItemIcon>
					Help Center
				</MenuItem>

				<MenuItem onClick={() => logout()}>
					<ListItemIcon><LogoutIcon /></ListItemIcon>
					Sign out
				</MenuItem>
			</MenuList>
		</Popover>
	);
});
ProfilePopover.displayName = 'ProfilePopover';

export default ProfilePopover;
