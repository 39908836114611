import { Fab } from "@mui/material";
import FlashAutoIcon from '@mui/icons-material/FlashAuto';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import { useContext } from "react";
import { CameraContext } from "./CameraContext";

const FlashButton = (props) => {
	const { toggleFlash, flashState } = useContext(CameraContext);

	return (
		<Fab size="small" color="white" aria-label="toggle flash" onClick={toggleFlash} {...props}>
			{flashState === 'off' && <FlashOffIcon />}
			{flashState === 'on' && <FlashOnIcon />}
			{flashState === 'auto' && <FlashAutoIcon />}
		</Fab>
	);
}

export default FlashButton;