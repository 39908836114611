import PersonIcon from '@mui/icons-material/Person';
import { Card, Container, Fab, CircularProgress } from "@mui/material";
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import FixedFooter from '~/components/FixedFooter';
import Loader from '~/components/Loader';
import Page from "~/components/Page";
import PageTitle from "~/components/PageTitle";
import { setFormikErrors } from '~/helpers/generalHelper';
import { useCrud } from '~/hooks/useCrud';
import { departmentQuery } from '~/loaders/departmentsLoader';
import DepartmentForm from '~/pages/forms/crud/DepartmentForm';
import { createDepartment, restoreDepartment, updateDepartment } from '~/requests/departments';

const DepartmentEditPage = () => {
	const crud = useCrud({ createRequest: createDepartment, updateRequest: updateDepartment, restoreRequest: restoreDepartment, queryKey: 'departments' });
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const formikRef = useRef();

	const { id } = useLoaderData();
	const { data: department } = useSuspenseQuery(departmentQuery(id));

	const handleSave = () => {
		if (formikRef.current) {
			formikRef.current.submitForm();  // This triggers Formik's submitForm function
		}
	};

	const restore = () => {
		setIsLoading(true);

		crud.doRestore({
			id: id,
			toastLabel: 'Department',
			onSettled: () => setIsLoading(false)
		});
	};

	const handleSubmit = (id, values, { setSubmitting }) => {
		setIsLoading(true);

		crud.doSave({
			id: id, values, toastLabel: 'Department',
			onSettled: () => {
				setIsLoading(false);
				setSubmitting(false);
			},
			onSuccess: () => {
				navigate(-1);
			},
			onError: (error, errors) => setFormikErrors(formikRef, errors)
		});
	};

	const title = `Edit Department`;

	return (
		<Page title={title}>
			{isLoading && <Loader />}

			<PageTitle gutterBottom icon={<PersonIcon />}>{title}</PageTitle>

			<Container>
				<Card sx={{ p: 3 }}>
					<EditForm formikRef={formikRef} handleSubmit={handleSubmit} />
				</Card>
			</Container>

			<FixedFooter>
				<Fab color="secondary" variant="extended" onClick={() => navigate(-1)}>
					Cancel
				</Fab>
				{!!department?.deleted_at && (

					<Fab
						color="info"
						variant="extended"
						onClick={restore}
						disabled={isLoading}
					>
						{isLoading && (
							<CircularProgress color="inherit" sx={{ p: 1, mr: 1 }} />
						)}

						Restore Department
					</Fab>
				)}
				{!!!department?.deleted_at && (
					<Fab color="primary" variant="extended" onClick={handleSave} disabled={isLoading}>
						{isLoading && (
							<CircularProgress color="inherit" sx={{ p: 1, mr: 1 }} />
						)}
						Save Department
					</Fab>
				)}
			</FixedFooter>
		</Page>
	);
}

export default DepartmentEditPage;

const EditForm = ({ formikRef, handleSubmit }) => {
	const { id } = useLoaderData();
	const { data: department } = useSuspenseQuery(departmentQuery(id));

	return (
		<DepartmentForm ref={formikRef} department={department} onSubmit={(values, options) => handleSubmit(id, values, options)} />
	);
}