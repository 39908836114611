import { QueryClient, queryOptions } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";

// Importing workprovider requests
import { getWorkprovider, getWorkproviders } from "~/requests/workproviders";

/**
 * Creates a query for fetching workprovider details based on provided parameters.
 *
 * @param {object} [params={}] - The optional parameters for fetching workprovider details.
 * @returns {object} The query options for fetching workprovider details.
 *
 * @example
 * const allWorkprovidersQueryOptions = workprovidersQuery();
 * const { data } = useQuery(allWorkprovidersQueryOptions);
 *
 * const filteredWorkprovidersQueryOptions = workprovidersQuery({ param1: value1, param2: value2 });
 * const { data } = useQuery(filteredWorkprovidersQueryOptions);
 */
export const workprovidersQuery = (params = {}) => queryOptions({
	queryKey: ['workproviders', params],
	queryFn: queryFunctionHelper(getWorkproviders, params)
});

/**
 * Creates a query for fetching details of a specific workprovider.
 *
 * @param {string} id - The ID of the workprovider to fetch details for.
 * @returns {object} The query options for fetching workprovider details.
 *
 * @example
 * const workproviderQueryOptions = workproviderQuery("workproviderID");
 * const { data } = useQuery(workproviderQueryOptions);
 */
export const workproviderQuery = (id) => queryOptions({
	queryKey: ['workproviders', id],
	queryFn: queryFunctionHelper(getWorkprovider, id),
	enabled: id !== null
});

/**
 * Creates a loader function to ensure workprovider data is available in the query client.
 *
 * @param {QueryClient} queryClient - The query client instance.
 * @returns {function} The loader function that ensures workprovider data is available.
 *
 * @throws {Error} Throws an error if no workprovider ID is provided.
 *
 * @example
 * const { id } = useLoaderData();
 */
export const workproviderLoader = (queryClient) => async ({ params }) => {
	if (!params.id) {
		throw new Error('No workprovider ID provided');
	}
	await queryClient.ensureQueryData(workproviderQuery(params.id));
	return { id: params.id };
};

/**
 * Creates a loader function for ensuring all workprovider data is available in the query client.
 *
 * @param {QueryClient} queryClient - The query client instance.
 * @returns {Object} Empty object
 *
 * @example
 * const { } = useLoaderData();
 */
export const workprovidersLoader = (queryClient) => async () => {
	await queryClient.ensureQueryData(workprovidersQuery());
	return {};
};
