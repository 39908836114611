import React, { useCallback, useState } from "react";
import { usePopupState } from "material-ui-popup-state/hooks";
import PropTypes from 'prop-types';
import { useQuery } from "@tanstack/react-query";
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { getAssessment } from '~/requests/assessments';

// context setup
const AssessmentDialogContext = React.createContext({
	popupState: {},
	open: (assessment_uuid) => { },
	assessment: null
});
export const __AssessmentDialogContext = AssessmentDialogContext;

export const AssessmentDialogProvider = ({ children }) => {

	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'AssessmentDialog',
	});

	const [assessmentUuid, setAssessmentUuid] = useState(null);


	const { data: assessment } = useQuery({
		queryKey: ['assessments', assessmentUuid],
		queryFn: queryFunctionHelper(getAssessment, assessmentUuid),
		refetchOnWindowFocus: false,
		enabled: !!assessmentUuid,
	});

	const open = useCallback((assessment_uuid) => {
		setAssessmentUuid(assessment_uuid);
		popupState.open();
	}, [popupState]);

	return (
		<AssessmentDialogContext.Provider
			value={{ popupState, open, assessment }}
		>
			{children}
		</AssessmentDialogContext.Provider>
	);
};

AssessmentDialogProvider.propTypes = {
}
