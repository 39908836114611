import { useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from 'formik';
import { enqueueSnackbar } from "notistack";
import { useCallback, useContext, useMemo } from "react";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { submissionQuery } from "~/loaders/estimateFormLoader";
import { deleteSubmission, uncompleteSubmission } from "~/requests/submissions";
import { __FormContext } from "../providers/FormProvider";
import { useConfirm } from "./useConfirm";


export function useForm() {

	const context = useContext(__FormContext);

	const queryClient = useQueryClient();
	const { pathname } = useResolvedPath();
	const navigate = useNavigate();
	const formik = useFormikContext();
	const { openConfirm, closeConfirm } = useConfirm();

	const currentKeyUrl = useMemo(() => pathname.split('/').at(-1), [pathname]);
	const menuItemIndex = useMemo(() => {
		const index = context.menuItems?.findIndex((item) => item.url === currentKeyUrl);
		return index === -1 ? -1 : index;
	}, [context, currentKeyUrl]);

	const formKey = useMemo(() => context.submission?.form?.key, [context.submission]);

	const isCompleted = useMemo(() => !!context.submission?.is_completed, [context.submission]);

	const nextPage = useMemo(() => menuItemIndex >= 0 ? context.menuItems?.at(menuItemIndex + 1) : null, [context.menuItems, menuItemIndex]);

	const isLastPage = useMemo(() => !(!!nextPage), [nextPage]);

	const previousPage = useMemo(() => menuItemIndex > 0 ? context.menuItems?.at(menuItemIndex - 1) || null : null, [context.menuItems, menuItemIndex]);

	const showPageNavigation = useMemo(() => menuItemIndex >= 0 ? true : false, [menuItemIndex]);

	const isMenuItemDisabled = useCallback((key) => !!context.disabledMenuItems?.find((item) => item.key === key) ?? false, [context.disabledMenuItems]);

	const isMenuItemError = useCallback((key) => !!context.errorMenuItems?.find((item) => item.key === key) ?? false, [context.errorMenuItems]);

	const hasNextPage = useMemo(() => !!nextPage && !isMenuItemDisabled(nextPage?.key), [isMenuItemDisabled, nextPage]);

	const hasPreviousPage = useMemo(() => !!previousPage && !isMenuItemDisabled(nextPage?.key), [isMenuItemDisabled, nextPage?.key, previousPage]);

	const getMenuItem = useCallback((url) => context.menuItems?.find((item) => item.url === url), [context]);

	const goNextPage = useCallback(() => navigate(`../${nextPage?.url}`), [navigate, nextPage]);
	const key = submissionQuery(context.submission?.uuid).queryKey;

	const invalidateQuery = useCallback((optionalKey = null) => {
		queryClient.invalidateQueries({ queryKey: optionalKey || key, type: 'all' });
		if (context.submission.parent?.uuid) {
			// invalidate parent if it has one
			queryClient.invalidateQueries({ queryKey: submissionQuery(context.submission.parent.uuid).queryKey, type: 'all' });
		}
	}, [context.submission, key, queryClient]);

	const submitForm = useCallback(async (completed = false, onFinish = undefined) => {
		await formik.setStatus(completed ? 'completed' : 'in_progress');
		await formik.submitForm();

		onFinish?.();
	}, [formik]);

	const goPreviousPage = useCallback(() => navigate(`../${previousPage?.url}`), [navigate, previousPage]);

	const get = useCallback((path, defaultValue = "") => {
		return path.split('.').reduce((a, b) => {
			if (a && b.includes('[') && b.includes(']')) {
				// Handle array[key] notation
				const keyName = b.replace('[', '').replace(']', '');

				return a.find(item => item.key === keyName);
			}

			return (a && a[b]) ? a[b] : defaultValue;
		}, formik.values);
	}, [formik.values]);

	const uncomplete = useCallback(() => {
		uncompleteSubmission(context.submission.uuid).then(() => {

			queryClient.setQueryData(key, (previous = {}) => {
				return {
					...previous,
					status: 'in_progress',
					is_completed: false,
				};
			});

			queryClient.refetchQueries({
				queryKey: key,
				type: 'all'
			});

			formik.setStatus('in_progress');
		});
	}, [context.submission.uuid, formik, key, queryClient]);

	const deleteForm = useCallback((onSuccess = null) => {
		const label = formKey === 'estimate'
			? 'Estimate'
			: (formKey === 'estimate_audit' ? 'Audit' : 'Submission');

		openConfirm({
			type: 'delete',
			title: `Delete ${label}`,
			message: `Are you sure you want to delete this ${label}?`,
			onConfirm: () => {
				closeConfirm();
				const data = { ...context.submission };
				deleteSubmission(data.uuid).then(() => {
					context.setBlockNavigation(false);
					enqueueSnackbar(`The ${label} has been deleted`, { variant: 'success' });
					invalidateQuery();
					onSuccess?.(data);
				});
			},
			onCancel: () => closeConfirm()
		});
	}, [closeConfirm, context, formKey, invalidateQuery, openConfirm]);

	return {
		...context,
		showPageNavigation,
		getMenuItem,
		goNextPage,
		goPreviousPage,
		hasNextPage,
		hasPreviousPage,
		isLastPage,
		isMenuItemDisabled,
		isMenuItemError,
		submitForm,
		formik,
		isSubmitting: formik.isSubmitting,
		get,
		isCompleted,
		uncomplete,
		deleteForm,
		formKey,
		invalidateQuery
	};
}
