import { createContext, useState } from 'react';

const globalKeys = {
  lastMainUrl: 'lastMainUrl',
};

const defaultState = {
  lastMainUrl: '/'
};

export const GlobalStateContext = createContext({
  state: defaultState,
  setState: () => { },
  globalKeys,
});

export const GlobalStateProvider = ({ children }) => {
  const [state, setState] = useState(defaultState);

  return (
    <GlobalStateContext.Provider value={{ state, setState, globalKeys }}>
      {children}
    </GlobalStateContext.Provider>
  );
};
