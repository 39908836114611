import PersonIcon from '@mui/icons-material/Person';
import { Card, Container, Fab, CircularProgress } from "@mui/material";
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRef, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import FixedFooter from '~/components/FixedFooter';
import Loader from '~/components/Loader';
import Page from "~/components/Page";
import PageTitle from "~/components/PageTitle";
import { setFormikErrors } from '~/helpers/generalHelper';
import { useCrud } from '~/hooks/useCrud';

const CRUDEntityPage = (props) => {
	const {
		entityLabel,
		entityQuery = null,

		createRequest = null,
		updateRequest = null,
		restoreRequest = null,

		queryKey,
		entityForm
	} = props;

	const isEdit = !!updateRequest;
	const isNew = !isEdit;

	// chedk for minium props required or throw error
	if (!createRequest && !updateRequest) {
		throw new Error('createRequest or updateRequest is required');
	}

	return isEdit ?
		<EditEntity {...props} />
		: <NewEntity {...props} />;
};


const NewEntity = ({
	entityLabel,
	createRequest,
	queryKey,
	entityForm
}) => {
	const crud = useCrud({ createRequest, queryKey });
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const formikRef = useRef();

	const handleSave = () => {
		if (formikRef.current) {
			formikRef.current.submitForm();  // This triggers Formik's submitForm function
		}
	};

	const handleSubmit = (id, values, { setSubmitting }) => {
		setIsLoading(true);

		crud.doSave({
			id: id, values, toastLabel: entityLabel,
			onSettled: () => {
				setIsLoading(false);
				setSubmitting(false);
			},
			onSuccess: () => {
				navigate(-1);
			},
			onError: (error, errors) => setFormikErrors(formikRef, errors)
		});
	};

	const title = `Create ${entityLabel}`;

	const Form = entityForm;

	return (
		<Page title={title}>
			{isLoading && <Loader />}

			<PageTitle gutterBottom icon={<PersonIcon />}>{title}</PageTitle>

			<Container>
				<Card sx={{ p: 3 }}>
					<Form
						ref={formikRef}
						entity={{}}
						isNew={true}
						onSubmit={(values, options) => handleSubmit(null, values, options)}
					/>
				</Card>
			</Container>

			<FixedFooter>
				<Fab color="secondary" variant="extended" onClick={() => navigate(-1)}>
					Cancel
				</Fab>
				<Fab color="primary" variant="extended" onClick={handleSave} disabled={isLoading}>
					{isLoading && (
						<CircularProgress color="inherit" sx={{ p: 1, mr: 1 }} />
					)}
					Save {entityLabel}
				</Fab>
			</FixedFooter>
		</Page>
	);
};

const EditEntity = ({
	entityLabel,
	entityQuery = null,

	createRequest = null,
	updateRequest = null,
	restoreRequest = null,

	queryKey,
	entityForm
}) => {
	const crud = useCrud({ createRequest, updateRequest, restoreRequest, queryKey });
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(false);
	const formikRef = useRef();

	const { id } = useLoaderData();
	const { data: entity } = useSuspenseQuery(entityQuery(id));

	const handleSave = () => {
		if (formikRef.current) {
			formikRef.current.submitForm();  // This triggers Formik's submitForm function
		}
	};

	const restore = () => {
		setIsLoading(true);

		crud.doRestore({
			id: id,
			toastLabel: entityLabel,
			onSettled: () => setIsLoading(false)
		});
	};

	const handleSubmit = (id, values, { setSubmitting }) => {
		setIsLoading(true);

		crud.doSave({
			id: id, values, toastLabel: entityLabel,
			onSettled: () => {
				setIsLoading(false);
				setSubmitting(false);
			},
			onSuccess: () => {
				navigate(-1);
			},
			onError: (error, errors) => setFormikErrors(formikRef, errors)
		});
	};

	const title = `Edit ${entityLabel}`;

	return (
		<Page title={title}>
			{isLoading && <Loader />}

			<PageTitle gutterBottom icon={<PersonIcon />}>{title}</PageTitle>

			<Container>
				<Card sx={{ p: 3 }}>
					<EditForm formikRef={formikRef} handleSubmit={handleSubmit} form={entityForm} entityQuery={entityQuery} />
				</Card>
			</Container>

			<FixedFooter>
				<Fab color="secondary" variant="extended" onClick={() => navigate(-1)}>
					Cancel
				</Fab>
				{!!entity?.deleted_at && (

					<Fab
						color="info"
						variant="extended"
						onClick={restore}
						disabled={isLoading}
					>
						{isLoading && (
							<CircularProgress color="inherit" sx={{ p: 1, mr: 1 }} />
						)}

						Restore {entityLabel}
					</Fab>
				)}
				{!!!entity?.deleted_at && (
					<Fab color="primary" variant="extended" onClick={handleSave} disabled={isLoading}>
						{isLoading && (
							<CircularProgress color="inherit" sx={{ p: 1, mr: 1 }} />
						)}
						Save {entityLabel}
					</Fab>
				)}
			</FixedFooter>
		</Page>
	);
};

const EditForm = ({ formikRef, handleSubmit, form: Form, entityQuery }) => {
	const { id } = useLoaderData();
	const { data: entity } = useSuspenseQuery(entityQuery(id));

	return (
		<Form ref={formikRef} entity={entity} onSubmit={(values, options) => handleSubmit(id, values, options)} />
	);
}

export default CRUDEntityPage;