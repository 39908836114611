import { MenuItem, TextField as MuiTextField, Select, Stack } from "@mui/material";
import { Autocomplete } from "formik-mui";
import { Field, useFormikContext } from "formik";
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useQuery } from "@tanstack/react-query";
import { queryFunctionHelper } from "~/helpers/queryHelper";
import { getSuburbs } from "~/requests/suburbs";
import { states } from "~/form/estimate/data/states";
import { useEffect, useState } from "react";

const SuburbAutocompleteField = ({ name, state: _state = undefined, onSuburbSelected = null, required = false, ...props }) => {
	const formik = useFormikContext();
	const [state, setState] = useState(_state ?? states[0].value);

	const { data: suburbs = [], isLoading } = useQuery({
		queryKey: ['suburbs', state],
		queryFn: queryFunctionHelper(getSuburbs, { state })
	});

	useEffect(() => {
		if (_state !== undefined) {
			setState(_state);
		}
	}, [_state]);

	return (
		<Stack direction="row">
			{!_state && (
				<Select
					defaultValue={states[0].value}
					value={state}
					onChange={(e) => setState(e.target.value)}
					sx={{ minWidth: 90 }}
				>
					{states.map((item, i) => (
						<MenuItem key={i} value={item.value}>{item.label}</MenuItem>
					))}
				</Select>
			)}
			<Field
				name={name}
				component={Autocomplete}
				options={suburbs}
				isLoading={isLoading}
				getOptionLabel={(option) => typeof option === "string" ? option : option.name}
				isOptionEqualToValue={(option, value) => option.name === value}
				onChange={(e, value) => {
					formik.setFieldTouched(name, true, false);
					formik.setFieldValue(name, value?.name);
					onSuburbSelected?.(value);
				}}
				renderOption={(renderProps, option, { selected, inputValue }) => {
					const matches = match(option.name, inputValue);
					const parts = parse(option.name, matches);
					return (
						<li {...renderProps} key={option.id}>
							{parts.map((part, index) => (
								<span key={index} style={{ fontWeight: part.highlight ? 700 : 400, whiteSpace: 'pre' }}>
									{part.text}
								</span>
							))}
							<span style={{ marginLeft: 'auto', fontWeight: 400, color: '#666' }}>{option.postcode}</span>
						</li>
					);
				}}
				renderInput={(params) => (
					<MuiTextField
						{...params}
						name={name}
						error={formik.touched[name] && !!formik.errors[name]}
						helperText={formik.errors[name]}
						label="Suburb"
						variant="outlined"
						required={required}
					/>
				)}
				{...props}
			/>
		</Stack>
	)
};

export default SuburbAutocompleteField;