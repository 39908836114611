import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import MuiSelect from '@mui/material/Select';
import { getIn } from 'formik';

function fieldToSelect({
	disabled,
	field: { onBlur: _onBlur, onChange: fieldOnChange, ...field },
	form: { isSubmitting, touched, errors, setFieldTouched, setFieldValue },
	onClose,
	size = undefined,
	...props
}) {
	const fieldError = getIn(errors, field.name);
	const showError = getIn(touched, field.name) && !!fieldError;

	return {
		disabled: disabled ?? isSubmitting,
		error: showError,
		formError: showError ? fieldError : undefined,
		onBlur: () => {
			// no-op
		},
		onChange:
			fieldOnChange ??
			(() => {
				// no-op
			}),
		// we must use `onClose` instead of `onChange` to be able to trigger validation when users click outside of the select list.
		onClose:
			onClose ??
			(async (e) => {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				const dataset = (e.target).dataset;
				if (dataset && dataset.value) {
					// out-of-sync issue since November 2019: https://github.com/formium/formik/issues/2059#issuecomment-890613538
					// without the await, formik validates with the former value
					await setFieldValue(field.name, dataset.value, false);
				}
				setFieldTouched(field.name, true, true);
			}),
		size,
		...field,
		...props,
	};
}

export function Select({
	formControl,
	inputLabel,
	formHelperText,
	fullWidth = false,
	...selectProps
}) {
	const { error, formError, disabled, ...selectFieldProps } =
		fieldToSelect(selectProps);
	const { children: formHelperTextChildren, ...formHelperTextProps } =
		formHelperText || {};
	const shouldDisplayFormHelperText = error || formHelperTextChildren;

	return (
		<FormControl disabled={disabled} error={error} {...formControl} fullWidth={fullWidth}>
			<InputLabel id={selectFieldProps.labelId} {...inputLabel} size={selectFieldProps.size}>
				{selectFieldProps.label}
			</InputLabel>
			<MuiSelect {...selectFieldProps} />
			{shouldDisplayFormHelperText && (
				<FormHelperText {...formHelperTextProps}>
					{error ? formError : formHelperTextChildren}
				</FormHelperText>
			)}
		</FormControl>
	);
}

Select.displayName = 'FormikMaterialUISelect';
