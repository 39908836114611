import { Stack, Toolbar, Box } from "@mui/material";
import { memo } from "react";

const FixedFooter = memo(({ children }) => {
	return (
		<>
			<Box sx={{ height: 60 }}></Box>
			<Toolbar sx={{
				position: "fixed",
				bottom: '1rem',
				right: 0,
				gap: '0.75rem',
				zIndex: 10,
				pointerEvents: 'none',

				'& button': {
					pointerEvents: 'auto'
				}

			}}>
				<Stack direction="row" spacing={2} alignItems="flex-end">
					{children}
				</Stack>
			</Toolbar >
		</>
	)
});

FixedFooter.displayName = 'FixedFooter';

export default FixedFooter