export const getRandomString = length => Array.from({ length }, () => "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"[Math.floor(Math.random() * 62)]).join('');

export const mergeArraysByKeyName = (arr1 = [], arr2 = [], mergeKey) => {
	const mergedMap = new Map();

	// Merge objects from the first array
	for (const item of arr1) {
		if (item) {
			mergedMap.set(item[mergeKey], { ...mergedMap.get(item[mergeKey]), ...item });
		}
	}

	// Merge objects from the second array
	for (const item of arr2) {
		if (item) {
			mergedMap.set(item[mergeKey], { ...mergedMap.get(item[mergeKey]), ...item });
		}
	}

	return Array.from(mergedMap.values());
}

export const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms))