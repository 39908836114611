import { useQueryClient } from "@tanstack/react-query";
import { getIn, useFormikContext } from "formik";
import _debounce from 'lodash.debounce';
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useBlocker, useParams } from "react-router-dom";
import { mergeValuesWithInital } from "~/helpers/dataHelpers";
import { useConfirm } from "~/hooks/useConfirm";
import { submissionQuery } from "~/loaders/estimateFormLoader";
import { updateSubmission as _updateSubmission } from "~/requests/submissions";
import { submitForm } from "../requests/submissions";
import { __FormPricesContext } from "./FormPricesProvider";

// context setup
const FormContext = React.createContext({
	menuItems: [],
	baseMenuItems: [],
	additionalMenuItems: [],
	menuItemsRules: (key, formik, submission) => { },
	menuItemsErrors: (key, formik) => { },
	submission: null,
	disabledMenuItems: [],
	errorMenuItems: [],
});
export const __FormContext = FormContext;

export const FormProvider = ({
	menuItems = [],
	additionalMenuItems = [],
	menuItemsRules = null,
	menuItemsErrors = null,
	initialValues = {},
	submission = null,
	children
}) => {

	const { formId } = useParams();
	const formik = useFormikContext();
	const queryClient = useQueryClient();
	const [blockNavigation, setBlockNavigation] = useState(true);

	const parentSubmission = useMemo(() => (submission.parent), [submission.parent]);

	const { getMiscPrice, getPanelPrice } = useContext(__FormPricesContext);

	console.log('errors', formik.errors);

	const { openConfirm, closeConfirm } = useConfirm();

	const shouldBlock = React.useCallback(({ currentLocation, nextLocation }) => {
		const currentPathname = currentLocation.pathname.split('/');
		const nextPathname = nextLocation.pathname.split('/');

		// if form is completed then no need to block

		if (submission.is_completed === true) {
			return false;
		}

		// Check if the base URL (up to the ID) is different
		const isDifferentBaseURL =
			currentPathname.slice(0, 4).join('/') !== nextPathname.slice(0, 4).join('/');

		return blockNavigation && formik.dirty && isDifferentBaseURL;
	}, [blockNavigation, formik.dirty, submission]);

	const blocker = useBlocker(shouldBlock);

	useEffect(() => {
		if (blocker.state === "blocked") {

			// open confirm dialog 
			openConfirm({
				message: 'Are you sure you want to leave this page? You may have unsaved changes.',
				confirmButtonText: 'Leave',
				cancelButtonText: 'Return',
				onConfirm: () => {
					closeConfirm();
					blocker.proceed?.()
				},
				onCancel: () => {
					closeConfirm();
					blocker.reset?.()
				}
			});

		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [blocker.state]);

	const [_menuItems, disabledMenuItems, errorMenuItems] = useMemo(() => {
		const items = menuItems.filter(item => menuItemsRules?.(item.key, formik, submission) ?? true);
		const disabled = menuItems.filter(item => menuItemsRules?.(item.key, formik, submission) === null);
		const errored = menuItems.filter(item => menuItemsErrors?.(item.key, formik));

		return [items, disabled, errored];
	}, [menuItems, menuItemsRules, formik, submission, menuItemsErrors]);

	const handleAutoSave = useCallback((fieldName = "") => {
		formik.setSubmitting(true);

		// console.log('auto save', fieldName);

		const value = getIn(formik.values, fieldName);
		if (value) {
			submitForm('estimate', submission?.uuid, {
				data: { [fieldName]: getIn(formik.values, fieldName) },
				status: formik.status
			})
				.then(() => {

				})
				.catch((err) => {
					console.error(err);
				})
				.finally(() => {
					formik.setSubmitting(false);
				});
		}
		formik.setSubmitting(false);
	}, [formik.values, formik.status, submission]);

	const handleDebounceFn = useCallback((event) => {
		return;

		formik.validateField(event.target.name);

		if (!getIn(formik.errors, event.target.name)) {
			handleAutoSave(event.target.name);
		}
	}, [formik.errors, handleAutoSave]);

	const onBlur = useCallback(() => _debounce(handleDebounceFn, 1000), [handleDebounceFn]);

	const updateSubmission = useCallback(async (data) => {
		await _updateSubmission(submission.uuid, data);
		const key = submissionQuery(submission.uuid).queryKey;

		await queryClient.refetchQueries({
			queryKey: key,
			type: 'all'
		});

	}, [queryClient, submission]);

	const refreshSubmission = useCallback(async () => {
		const key = submissionQuery(submission.uuid).queryKey;
		return await queryClient.refetchQueries({
			queryKey: key,
			type: 'all'
		});
	}, [queryClient, submission]);

	useEffect(() => {
		formik.setFieldValue('_status', formik.status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.status]);

	useEffect(() => {
		if (submission) {
			setTimeout(() => {
				const values = {
					vehicle: {
						...submission.assessment?.vehicle
					},
					customer: {
						...submission.assessment?.customer
					},
					insurance: {
						claim_number: submission.assessment?.claim_number
					},
				}


				formik.setValues(mergeValuesWithInital(values, formik.values, ""));
			}, 0);
		}
	}, [submission]);


	useEffect(() => {
		const handleBeforeUnload = (e) => {
			e.preventDefault();
			e.returnValue = '';
			return '';
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, []);

	const estimateMeta = useMemo(() => ({
		bodyStyle: parentSubmission?.summary?.body_style ?? formik.values.vehicle?.body_style,
		paintType: parentSubmission?.summary?.paint_type ?? formik.values.vehicle?.paint_type,
	}), [parentSubmission, formik]);

	return (
		<FormContext.Provider
			value={{
				menuItems: _menuItems,
				baseMenuItems: menuItems,
				additionalMenuItems,
				disabledMenuItems,
				errorMenuItems,
				menuItemsRules,
				formId,
				submission,
				updateSubmission,
				getMiscPrice,
				getPanelPrice,
				estimateMeta,
				parentSubmission,
				setBlockNavigation,
				refreshSubmission
			}}
		>
			{children instanceof Function ? children({ onBlur }) : children}
		</FormContext.Provider>
	);
};
