import { Box, Card, Stack, Toolbar } from "@mui/material";
import { useFilters } from "~/hooks/useFilters";

const Wrap = ({ children, basis = 250, shrink = 1, grow = 0 }) => (
	<Box sx={{
		flexGrow: grow,
		flexShrink: shrink,
		flexBasis: `${basis}px`,
	}}>
		{children}
	</Box>
);

const FilterBar = ({
	children
}) => {
	const hook = useFilters();

	return (
		<Card sx={{
			position: 'relative',
			borderRadius: {
				xs: 0,
				xl: 100,
			},
		}}>
			<Toolbar sx={{ py: 1 }}>
				<Stack direction='row' spacing={2} width='100%' alignItems='center' useFlexGap flexWrap="wrap">
					{children instanceof Function ? children({ ...hook, Wrap }) : children}
				</Stack>

			</Toolbar>
		</Card>
	);
}

export default FilterBar;