import AddLinkIcon from '@mui/icons-material/AddLink';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Button, FormHelperText, Grid, MenuItem, TextField as MuiTextField, Stack, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MUISelect from "@mui/material/Select";
import Tooltip from "@mui/material/Tooltip";
import { useQuery } from '@tanstack/react-query';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { FastField, Field } from "formik";
import { Autocomplete, Select, TextField } from "formik-mui";
import { useCallback, useEffect, useState } from 'react';
import { DatePicker } from '~/components/formik-mui-x-datepickers/DatePicker';
import FormInput from '~/components/Forms/FormInput';
import { fieldForceUpperCase, readOnlyHelper } from '~/helpers/dataHelpers';
import { queryFunctionHelper } from '~/helpers/queryHelper';
import { paintCodesQuery } from '~/loaders/paintCodeLoader';
import { getWorkproviders } from '~/requests/workproviders';
import EstimateFormPage from "../../components/Forms/EstimateFormPage";
import PageTitle from "../../components/PageTitle";
import { useForm } from '../../hooks/useForm';
import { bodyTypes, paintTypes, transmissionTypes, vehicleSizes } from './data/data';
import { DateTimeField } from '@mui/x-date-pickers-pro';
import { DateTime } from 'luxon';
import { useConfirm } from '~/hooks/useConfirm';
import StateDropdown from '~/components/Dropdowns/StateDropdown';
import { unlinkAssessment } from '~/requests/submissions';

const PageJobDetails = () => {

	const { submission, isCompleted, updateSubmission, parentSubmission, formik, getMiscPrice, refreshSubmission } = useForm();

	const { data: workproviders = [], isLoading: isWorkprovidersLoading } = useQuery({
		queryKey: ['workproviders'],
		queryFn: queryFunctionHelper(getWorkproviders)
	});

	const { data: paintCodes, paintCodesLoading } = useQuery(paintCodesQuery());

	const [workprovider, setWorkprovider] = useState(submission.workprovider?.uuid);
	const [workproviderLocked, setWorkproviderLocked] = useState(!!submission.workprovider?.uuid);

	const { openConfirm } = useConfirm();


	const unlockWorkprovider = () => {
		setWorkprovider(null);
		setWorkproviderLocked(false);
	}

	const unlink = useCallback(() => {
		openConfirm({
			type: 'question',
			title: 'Unlink Assessment',
			message: 'Are you sure you want unlink this assement for the estimate?',
			onConfirm: (close) => {
				unlinkAssessment(submission.uuid).then(() => {
					refreshSubmission();
					close();
				});
			},
			onCancel: (close) => close()
		});
	}, [submission.uuid, refreshSubmission, openConfirm]);

	useEffect(() => {
		if (workprovider) {
			setWorkproviderLocked(true);
			if (submission.workprovider?.uuid != workprovider) {
				// handle workprovider change 

				// get new prices for any existing misc values 
				const newMisc = formik.values.misc.map(item => {
					const price = getMiscPrice('misc', item.key);
					return { ...item, price };
				});

				// get new prices for any existing turret values
				const newTurretMisc = formik.values.turret_misc.map(item => {
					const price = getMiscPrice('turret_misc', item.key, item.key == 'consumables_allowance' ? false : true);
					return { ...item, price };
				});

				// write new values to formik
				formik.setValues({
					...formik.values,
					misc: newMisc,
					turret_misc: newTurretMisc
				});

				updateSubmission({
					workprovider_uuid: workprovider
				});
			}
		}
	}, [workprovider, updateSubmission, submission.workprovider?.uuid, getMiscPrice]);

	return (
		<EstimateFormPage>
			<>
				<PageTitle>Job Details</PageTitle>
				<Typography variant="h3" component="h3" gutterBottom style={{ textDecoration: "uppercase" }}>
					Insurance
				</Typography>
				<Grid container paddingTop={1} spacing={2} marginBottom={2}>
					<Grid item xs={12} md={6}>

						<FormControl fullWidth required error={!workprovider}>
							<InputLabel>Workprovider</InputLabel>
							<MUISelect
								value={workprovider}
								label="Workprovider *"
								onChange={(e) => setWorkprovider(e.target.value)}
								defaultValue=""
								disabled={workproviderLocked || isWorkprovidersLoading}
								sx={{
									"& .MuiSelect-icon": {
										right: (workproviderLocked && !isCompleted) ? '57px' : null,
									},
								}}
								endAdornment={
									<>
										{(workproviderLocked && !isCompleted) && (
											<InputAdornment position="end">
												<IconButton onClick={unlockWorkprovider}>
													<Tooltip title="Change Workprovider">
														<LockOpenIcon
															color="primary"
														/>
													</Tooltip>
												</IconButton>
											</InputAdornment>
										)}
									</>
								}
							>
								{workproviders.map((item) => (
									<MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>
								))}
							</MUISelect>
							{workproviderLocked && (
								<FormHelperText>Workprovider is locked</FormHelperText>
							)}

							{!workprovider && (
								<FormHelperText>Workprovider must be selected</FormHelperText>
							)}
						</FormControl>

					</Grid>
					<Grid item xs={12} md={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="insurance.claim_number"
							label="Claim Number"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.claim_number || parentSubmission?.assessment?.claim_number)}
						/>
					</Grid>
				</Grid>

				<Typography variant="h3" component="h3" gutterBottom style={{ textDecoration: "uppercase" }}>
					Customer
				</Typography>

				<Grid container paddingTop={1} spacing={2} marginBottom={2}>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="customer.first_name"
							label="First Name"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.customer?.first_name)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="customer.last_name"
							label="Last Name"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.customer?.last_name)}
						/>
					</Grid>
					<Grid item xs={12}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="customer.company_name"
							label="Company Name"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.customer?.company_name)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="customer.mobile_number"
							label="Mobile Number"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.customer?.mobile_number || submission?.assessment?.customer?.mobile_alt)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="customer.phone_number"
							label="Phone Number"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.customer?.phone_home || submission?.assessment?.customer?.phone_work)}
						/>
					</Grid>
					<Grid item xs={12}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="customer.email"
							label="Email"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.customer?.email || submission?.assessment?.customer?.email_alt)}
						/>
					</Grid>
				</Grid>

				<Typography variant="h3" component="h3" gutterBottom style={{ textDecoration: "uppercase" }}>
					Booking
				</Typography>
				<Grid container paddingTop={1} spacing={2} marginBottom={2}>
					<Grid item xs={12} md={6}>
						<Stack direction="row">
							{!submission?.assessment && (
								<Button variant="contained" color='primary' disabled endIcon={<AddLinkIcon />} size='large'>
									Link Assessment
								</Button>
							)}

							{submission?.assessment && (
								<Stack direction="row" sx={{ width: '100%' }}>
									<DateTimeField
										label="Assessment Date"
										value={DateTime.fromISO(submission?.assessment?.assessment_datetime)}
										slotProps={{ textField: { fullWidth: true } }}
										format="dd/MM/yyyy hh:mm a"
										readOnly
									/>
									<Tooltip title="Unlink Assessment">
										<Button
											color="primary"
											variant='contained'
											size='small'
											sx={{
												minWidth: 'unset',
												width: 56,
												borderTopLeftRadius: 0,
												borderBottomLeftRadius: 0,
											}}
											onClick={unlink}>
											<LinkOffIcon />
										</Button>
									</Tooltip>
								</Stack>
							)}
						</Stack>
					</Grid>
					<Grid item xs={12} md={6}>
						<Stack direction="row">
							<FastField
								component={FormInput}
								inputComponent={DatePicker}
								name="booking.repair_date"
								label="Repair Date"
								slotProps={{
									actionBar: {
										actions: ['cancel', 'clear', 'today', 'accept']
									}
								}}
								textField={{ fullWidth: true }}
								fullWidth={true}
								format="dd/MM/yyyy"

							/>

							{/* <Tooltip title="Link Booking">
								<Button
									color="primary"
									variant='contained'
									size='small'
									disabled
									sx={{
										minWidth: 'unset',
										width: 56,
										borderTopLeftRadius: 0,
										borderBottomLeftRadius: 0,
									}}>
									<AddLinkIcon />
								</Button>
							</Tooltip> */}
						</Stack>
					</Grid>
				</Grid>

				<Typography variant="h3" component="h3" gutterBottom style={{ textDecoration: "uppercase" }}>
					Vehicle
				</Typography>
				<Grid container paddingTop={1} spacing={2} marginBottom={2}>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.rego"
							label="Rego"
							required
							fullWidth
							onChange={e => fieldForceUpperCase(e, formik)}
							{...readOnlyHelper(submission?.assessment?.vehicle?.rego)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={StateDropdown}
							defaultValue=""
							name="vehicle.rego_state"
							label="Rego State"
							required
							fullWidth
							{...readOnlyHelper(submission?.assessment?.vehicle?.rego_state)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={Select}
							name="vehicle.size"
							label="Size"
							formControl={{
								fullWidth: true,
								required: true
							}}
						>
							{vehicleSizes.map((item, i) => (
								<MenuItem key={i} value={item.value}>{item.label}</MenuItem>
							))}
						</FastField>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={Select}
							formControl={{
								fullWidth: true,
								required: true
							}}
							defaultValue=""
							name="vehicle.body_style"
							label="Body Style"
						>
							{bodyTypes.map((item, i) => (
								<MenuItem key={i} value={item.value}>{item.label}</MenuItem>
							))}
						</FastField>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.build_year"
							label="Vehicle Year"
							mask="99/9999"
							helperText="Format: MM/YYYY eg 01/2023"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.vehicle?.build_year)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.make"
							label="Make"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.vehicle?.make)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.model"
							label="Model"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.vehicle?.model)}
						/>
					</Grid>
					<Grid item xs={6}>
						<Field
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.badge"
							label="Badge"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.vehicle?.badge)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={Select}
							name="vehicle.transmission"
							label="Transmission"
							formControl={{
								fullWidth: true,
								required: true
							}}
							{...readOnlyHelper(submission?.assessment?.vehicle?.transmission?.toLowerCase())}
						>
							{transmissionTypes.map((item, i) => (
								<MenuItem key={i} value={item.value}>{item.label}</MenuItem>
							))}
						</FastField>
					</Grid>
					<Grid item xs={6}>
						<Field
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.vin"
							label="VIN"
							onChange={e => fieldForceUpperCase(e, formik)}
							fullWidth
							{...readOnlyHelper(submission?.assessment?.vehicle?.vin)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.colour"
							label="Colour"
							fullWidth
							{...readOnlyHelper(submission?.assessment?.vehicle?.colour)}
						/>
					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={TextField}
							name="vehicle.klms"
							required
							label="Klms"
							fullWidth
							type="number"
							InputProps={{
								inputProps: { min: 0 }
							}}
						/>

					</Grid>
					<Grid item xs={6}>
						<FastField
							component={FormInput}
							inputComponent={Select}
							autoSave
							name="vehicle.paint_type"
							label="Paint Type"
							defaultValue={null}
							formControl={{
								required: true,
								fullWidth: true,
							}}
						>
							{paintTypes.map((item, i) => (
								<MenuItem key={i} value={item.value}>{item.label}</MenuItem>
							))}
						</FastField>
					</Grid>
					<Grid item xs={12} md={6}>
						<Field
							name="vehicle.paint_code"
							component={Autocomplete}
							freeSolo
							options={paintCodes || []}
							loading={paintCodesLoading}
							groupBy={code => code.manufacturer}
							isOptionEqualToValue={(option, value) => option.uuid === value.uuid}
							getOptionKey={(option) => option.id}
							getOptionLabel={(option) => option.code}
							renderOption={(renderProps, option, { selected, inputValue }) => {
								const matches = match(option.code, inputValue);
								const parts = parse(option.code, matches);
								return (
									<li {...renderProps}>
										{parts.map((part, index) => (
											<span key={index} style={{ fontWeight: part.highlight ? 700 : 400, whiteSpace: 'pre' }}>
												{part.text}
											</span>
										))}
										{/* <Stack direction='row' gap={1} sx={{ ml: 2 }}>
											{option.toners.map((toner, i) => (
												<Chip size='small' label={toner} key={i} />
											))}
										</Stack> */}
										<span style={{ marginLeft: 'auto', fontWeight: 400, color: '#666' }}>{option.name}</span>
									</li>
								);
							}}

							renderInput={(params) => (
								<MuiTextField
									{...params}
									// We have to manually set the corresponding fields on the input component
									name="vehicle.paint_code"
									error={formik.touched['vehicle.paint_code'] && !!formik.errors['vehicle.paint_code']}
									helperText={formik.errors['vehicle.paint_code']}
									label="Paint Code"
									variant="outlined"
								/>
							)}
						/>
					</Grid>
				</Grid>
			</>
		</EstimateFormPage>
	);
}

export default PageJobDetails;