import React, { useContext } from "react";
import { __AssessmentEditDialogContext } from "~/providers/dialogs/AssessmentEditDialogProvider";

export function useAssessmentEditDialog() {
	const context = useContext(__AssessmentEditDialogContext);
	const { open } = context;
	return {
		open
	};
}
