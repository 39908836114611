import React, { useCallback, useEffect, useMemo } from "react";
import { Box, Dialog, Fab, Stack, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { bindDialog, usePopupState } from "material-ui-popup-state/hooks";
import { CameraContext } from "./CameraContext";
import Webcam from "react-webcam";
import CameraButtons from "./CameraButtons";
import FlashButton from "./FlashButton";
import CloseIcon from '@mui/icons-material/Close';
import CameraGallery from "./CameraGallery";
import { getRandomString } from "~/helpers";
import { encodedCameraShutterAudio } from "~/assets/cameraShutter";
import useOrientation from "~/hooks/useOrientation";
import { useConfirm } from "~/hooks/useConfirm";

const Camera = ({ onSave = null, children }) => {
	const theme = useTheme();

	const isIpad = useMediaQuery(theme.breakpoints.down('lg'));
	const orientation = useOrientation();

	return (
		<CameraContextProvider onSave={onSave}>
			{({ popupState, videoConstraints, webcamRef, shutterActive, supportsFlash, setCurrentTrack, galleryOpen, closeCamera }) => (
				<>
					{children instanceof Function ? children({ ...popupState }) : children}
					<Dialog
						{...bindDialog(popupState)}
						onClose={() => closeCamera()}
						fullScreen={isIpad}
						maxWidth='lg'
						id="camera-dialog"
					>
						<Box sx={{
							height: '100%',
							position: 'relative',
						}}>
							<Stack
								sx={{
									height: '100%',
								}}
								direction={orientation === "landscape" ? "row" : "column"}
							>
								<Box sx={{
									width: '100%',
									height: '100%',
									flexGrow: 1,
									backgroundColor: '#333',
									position: 'relative',
									overflow: 'hidden',
								}}>
									<Webcam
										screenshotFormat="image/jpeg"
										audio={false}
										disablePictureInPicture
										screenshotQuality={1}
										imageSmoothing
										videoConstraints={videoConstraints}
										ref={webcamRef}
										onUserMedia={(stream) => {
											const track = stream.getVideoTracks()?.[0];
											setCurrentTrack(track);
										}}
										style={{
											width: '100%',
											height: '100%',
											objectFit: 'cover',
											objectPosition: 'center center',
										}}
									/>

									{shutterActive && (
										<Box
											sx={{
												position: 'absolute',
												top: 0,
												right: 0,
												bottom: 0,
												left: 0,
												zIndex: 1,
												backgroundColor: 'black',
												opacity: 0,
												animation: 'shutter 200ms ease-in-out'
											}}
										/>
									)}

									{supportsFlash && (
										<FlashButton sx={{
											position: 'absolute',
											top: 0,
											left: 0,
											margin: 2,
										}} />
									)}

								</Box>
								<CameraButtons />
							</Stack>

							<Tooltip title="Close Camera">
								<Fab
									aria-label="close camera"
									onClick={() => closeCamera()}
									color="white"
									size="small"
									sx={{
										position: 'absolute',
										top: 0,
										right: (orientation === 'landscape' && !galleryOpen) ? 86 : 0,
										margin: 2,
										zIndex: 1070,
									}}
								>
									<CloseIcon />
								</Fab>
							</Tooltip>

							{galleryOpen && (
								<CameraGallery />
							)}
						</Box>
					</Dialog>
				</>
			)
			}
		</CameraContextProvider>
	);
};


const flashStates = {
	auto: 'auto',
	on: 'on',
	off: 'off',
};

const CameraContextProvider = ({ onSave, children }) => {
	const SUPPORTS_MEDIA_DEVICES = "mediaDevices" in navigator;
	const shutterAudio = new Audio(encodedCameraShutterAudio);

	const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

	const webcamRef = React.useRef(null);


	const popupState = usePopupState({
		variant: 'dialog',
		popupId: 'CameraDialog',

	});

	const [videoConstraints, setVideoConstraints] = React.useState({
		audio: false,
		width: { min: 1080, ideal: 3840 },
		height: { min: 1920, ideal: 2160 },
		aspectRatio: 1.333,
		facingMode: { ideal: 'environment' },
	});

	const [facingMode, setFacingMode] = React.useState('environment');
	const [flashState, setFlashState] = React.useState(flashStates.auto);
	const [shutterActive, setShutterActive] = React.useState(false);
	const [cameras, setCameras] = React.useState([]);
	const [currentTrack, setCurrentTrack] = React.useState(null);
	const [galleryOpen, setGalleryOpen] = React.useState(false);

	const [photos, setPhotos] = React.useState([]);

	const { openConfirm, closeConfirm } = useConfirm();

	const handleDevices = React.useCallback(mediaDevices => setCameras(mediaDevices.filter(({ kind }) => kind === "videoinput")), []);

	React.useEffect(() => {
		navigator.mediaDevices.enumerateDevices().then(handleDevices);
	}, [handleDevices]
	);

	const cameraCount = useMemo(() => cameras.length, [cameras]);
	const selectedPhotos = useMemo(() => photos.filter((photo) => photo.selected), [photos]);

	const supportsFlash = useMemo(async () => {
		if (!currentTrack) return false;
		if (!('ImageCapture' in window)) return false;

		if (currentTrack) {
			const imageCapture = new ImageCapture(currentTrack);
			if (typeof imageCapture.getPhotoCapabilities !== 'function') return false;

			const capabilities = await imageCapture.getPhotoCapabilities();
			const torchSupported = !!capabilities.torch
				|| (
					'fillLightMode' in capabilities &&
					capabilities.fillLightMode.length != 0 &&
					capabilities.fillLightMode != 'none'
				);

			return torchSupported
		}
	}, [currentTrack]);

	const setTorch = useCallback((enabled) => {
		if (!currentTrack) return;
		if (!('ImageCapture' in window)) return;

		currentTrack.applyConstraints({
			advanced: [{ torch: enabled }]
		});
		// new ImageCapture(currentTrack).getPhotoCapabilities().then(capabilities => {

		// 	if (capabilities.torch) {
		// 		currentTrack.applyConstraints({
		// 			advanced: [{ torch: enabled }]
		// 		});
		// 	} else if ('fillLightMode' in capabilities) {
		// 		currentTrack.applyConstraints({
		// 			advanced: [{ fillLightMode: enabled ? 'flash' : 'off' }]
		// 		});
		// 	}
		// });

	}, [currentTrack]);

	const setBestResolution = useCallback(async () => {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: { facingMode: facingMode }
			});

			const track = stream.getVideoTracks()[0];


			const { width = { max: 1920 }, height = { max: 1080 } } = track.getCapabilities?.() || {};
			alert(JSON.stringify({ width, height }));

			// Update videoConstraints with the maximum width and height available
			setVideoConstraints({
				width: width.max,
				height: height.max,
				aspectRatio: 1.333,
				facingMode: { ideal: facingMode }
			});

			track.stop();
		} catch (error) {
			console.error("Error setting best resolution:", error);
		}
	}, [facingMode]);


	useEffect(() => {
		setBestResolution();
	}, [setBestResolution]);

	const takePhoto = useCallback(() => {
		setShutterActive(true);
		setTimeout(() => setShutterActive(false), 200);
		if (flashState === flashStates.auto) {
			const delay = 400;
			setTorch(true);
			setTimeout(() => handlePhoto(), delay);
			setTimeout(() => setTorch(false), delay + 200);
		} else {
			handlePhoto();
		}

		function handlePhoto() {
			const imageSrc = webcamRef.current.getScreenshot();
			const key = getRandomString(8);

			shutterAudio.play();

			const toggle = (_key, enabled = null) => setPhotos((prev) => prev.map((photo) => {
				if (photo.key === _key) {
					return { ...photo, selected: enabled !== null ? !!enabled : !photo.selected };
				}
				return photo;
			}));

			setPhotos((prev) => [...prev, {
				key,
				src: imageSrc,
				selected: true,
				toggle: (enabled = null) => toggle(key, enabled)
			}]);
		}
	}, [flashState, setTorch]);

	const toggleDirection = useCallback(() => {
		setFacingMode((prev) => prev === 'environment' ? 'user' : 'environment');

		setTimeout(() => setBestResolution(), 10);
	}, []);


	const toggleFlash = useCallback(() => {
		setFlashState((prev) => {
			if (prev === flashStates.auto) return flashStates.on;
			if (prev === flashStates.on) return flashStates.off;
			return flashStates.auto;
		});
	}, []);

	const openGallery = useCallback(() => {
		setGalleryOpen(true);
	}, []);

	const closeGallery = useCallback(() => {
		setGalleryOpen(false);
	}, []);

	const reset = useCallback(() => {
		setPhotos([]);
		closeGallery();
	}, [closeGallery]);

	const closeCamera = useCallback((force = false) => {
		if ((photos.length > 0) && !force) {

			openConfirm({
				message: 'Are you sure you want to close the camera? You may have unsaved photos.',
				confirmButtonText: 'Close',
				cancelButtonText: 'Return',
				onConfirm: () => {
					popupState.close();
					reset();
					closeConfirm();
				},
				onCancel: () => {
					closeConfirm();
				}
			});
		} else {
			popupState.close();
			reset();
		};

	}, [popupState, reset]);

	const savePhotos = useCallback(() => {
		onSave?.(selectedPhotos, () => {
			// On Finish

		});
		reset();
		closeCamera(true);
	}, [closeCamera, onSave, selectedPhotos]);

	useEffect(() => {
		if (flashState === flashStates.on) setTorch(true);
		if (flashState === flashStates.off) setTorch(false);
	}, [flashState, setTorch]);

	useEffect(() => {
		setVideoConstraints((prev) => ({
			...prev,
			facingMode: { ideal: facingMode }
		}));
	}, [facingMode]);

	if (!SUPPORTS_MEDIA_DEVICES) {
		return null;
	}

	return (
		<CameraContext.Provider
			value={{ popupState, closeCamera, toggleDirection, takePhoto, toggleFlash, flashState, shutterActive, supportedConstraints, cameraCount, supportsFlash, currentTrack, photos, galleryOpen, openGallery, closeGallery, selectedPhotos, savePhotos }}
		>
			{children({ popupState, videoConstraints, webcamRef, shutterActive, setCurrentTrack, supportsFlash, galleryOpen, selectedPhotos, closeCamera })}
		</CameraContext.Provider>
	);
};
export default Camera;