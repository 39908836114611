import PropTypes from 'prop-types';
import { forwardRef, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useMediaQuery } from '@mui/material';

import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';


const MenuItem = ({ item, level, onNavigation = null }) => {
	const theme = useTheme();
	const { pathname } = useLocation();

	const isActive = useMemo(() => pathname === item.url, [pathname, item.url]);

	const Icon = item.icon;
	const itemIcon = item?.icon ? (
		<Icon stroke={1.5} size="1.3rem" />
	) : (
		<FiberManualRecordIcon
			sx={{
				width: isActive ? 8 : 6,
				height: isActive ? 8 : 6
			}}
			fontSize={level > 0 ? 'inherit' : 'medium'}
		/>
	);

	let itemTarget = '_self';
	if (item.target) {
		itemTarget = '_blank';
	}

	let listItemProps = {
		component: forwardRef((props, ref) => <NavLink ref={ref} {...props} to={item.url} target={itemTarget} />)
	};
	if (item?.external) {
		listItemProps = { component: 'a', href: item.url, target: itemTarget };
	}

	return (
		<ListItem disablePadding>
			<ListItemButton
				{...listItemProps}
				disabled={item.disabled}
				onClick={() => onNavigation(item.url)}
				sx={{
					borderRadius: `8px`,
					mb: 0.5,
					alignItems: 'flex-start',
					backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
					py: level > 1 ? 1 : 1.25,
					pl: `${level * 24}px`
				}}
				selected={isActive}
			>
				<ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36 }}>{itemIcon}</ListItemIcon>
				<ListItemText
					primary={
						<Typography variant={'body1'} color="inherit" sx={{
							fontWeight: isActive ? '600' : '400',
						}}>
							{item.title}
						</Typography>
					}
					secondary={
						item.caption && (
							<Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
								{item.caption}
							</Typography>
						)
					}
				/>
				{item.chip && (
					<Chip
						color={item.chip.color}
						variant={item.chip.variant}
						size={item.chip.size}
						label={item.chip.label}
						avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
					/>
				)}
			</ListItemButton>
		</ListItem>
	);
};

MenuItem.propTypes = {
	item: PropTypes.object,
	level: PropTypes.number
};

export default MenuItem;
