import { FormControlLabel, Grid, InputAdornment } from "@mui/material";
import { Field, Formik } from "formik";
import { Switch, TextField } from "formik-mui";
import { forwardRef } from 'react';
import { Form } from 'react-router-dom';
import * as Yup from 'yup';
import { mergeObjectLeft } from "~/helpers/dataHelpers";

// whitelist of fields and default values
const formTemplate = {
	name: '',
	rate: '',
	is_administrative: false,
};

const validationSchema = () => Yup.object().shape({
	name: Yup.string().required('Required').min(2, 'Name is too Short').max(100, 'Name is too long'),
	rate: Yup.number().required('Required').min(0, 'Rate must be greater than 0'),
	is_administrative: Yup.boolean(),
});

const DepartmentForm = forwardRef(({ department = null, isNew = false, onSubmit }, ref) => {

	const handleSubmit = (values, formikBag) => {
		// send values and inject other values if applicable
		const formValues = {
			...values,
		};

		onSubmit(formValues, formikBag);
	};


	return (
		<Formik
			initialValues={mergeObjectLeft(formTemplate, department)}
			onSubmit={handleSubmit}
			validationSchema={() => validationSchema()}
			innerRef={ref}
		>
			{formik => (
				<Form>
					<Grid container>
						<Grid item padding={2} xs={12} sm={6} display="flex" flexDirection="column" gap={2}>
							<Field
								component={TextField}
								name="name"
								label="Name"
								fullWidth
								required
							/>

							<Field
								component={TextField}
								name="rate"
								label="Rate"
								fullWidth
								type="number"
								InputProps={{
									startAdornment: <InputAdornment position="start">$</InputAdornment>,
									inputMode: 'numeric',
									min: 0,
								}}
							/>

							<FormControlLabel
								control={
									<Field
										label="External User"
										name="is_administrative"
										type="checkbox"
										component={Switch}
									/>
								}
								label="Administrative"
								labelPlacement='end'
							/>
						</Grid>
					</Grid>

					<FormikContextHelper />
				</Form>
			)}
		</Formik>
	);
});

const FormikContextHelper = () => {
	// console.log('errors', formik.errors);
	return null;
}

DepartmentForm.displayName = 'DepartmentForm';

export default DepartmentForm;